import { Logo } from "../../../ui/logo";

import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { linkTranslate } from "../../../utils/utilitiesservices";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OrderStatus from "../../../utils/OrderStatus";

const OrderDetailItem = ({ item, handleExtradition }) => {
  return (
    <Row key={item.id} className="carditem">
      <Col xs={12} sm={4} md={4}>
        <div className="cart-board  w-100 h-100">
          <div
            className={" carditemimage-bg "}
            style={
              item.image
                ? {
                    backgroundImage: `url(/images/products/mini/${item.image.fileName})`,
                  }
                : {}
            }
          >
            <Link
              to={"/productdetail" + linkTranslate(item.name) + "/" + item.id}
            >
              {item.image ? (
                <img
                  className="cardImg"
                  src={"/images/products/mini/" + item.image.fileName}
                  alt={item.name}
                />
              ) : (
                <Logo></Logo>
              )}
            </Link>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={5} md={5} className="py-3 card-prop">
        <div>
          <h4>{item.name}</h4>
        </div>
        <div>
          {" "}
          <strong>Ürün Kodu</strong>: {item.code}
        </div>
        <div>
          {" "}
          <strong>Bağlı Olduğu Depo</strong>: {item.storageName}
        </div>
        <div>
          {" "}
          <strong>Marka</strong>: {item.brandName}
        </div>
      </Col>

      <Col className="d-flex justify-content-center align-items-center mb-2">
        {" "}
        {item.status === OrderStatus.Wait ? (
          <h6 className="text-warning">Beklemede</h6>
        ) : item.status === OrderStatus.Approval ? (
          <>
            <div className="w-100">
              <h6 className="text-success">Onaylandı</h6>
            </div>
            <div className="w-100">
              {item.extradition === true ? (
                <>
                  {/* <div class="alert alert-success" role="alert">
                    <div class="alert-heading">İade Kabul Edildi</div>{" "}
                    {item.extraditionDescription} <hr></hr>{" "}
                    {item.extraditionNote}
                  </div> */}
                  <>
                    {item.demandState === true ? (
                      <div class="alert alert-success" role="alert">
                        <div class="alert-heading">İade Kabul Edildi</div>{" "}
                        <hr></hr> {item.returnReason}{" "}
                        {item.extraditionDescription} <hr></hr>{" "}
                        {item.extraditionNote}
                      </div>
                    ) : (
                      <div class="alert alert-danger" role="alert">
                        <div class="alert-heading">İade Red Edildi</div>{" "}
                        <hr></hr> {item.returnReason}{" "}
                        {item.extraditionDescription} <hr></hr>{" "}
                        {item.extraditionNote}
                      </div>
                    )}
                  </>
                </>
              ) : item.extradition === false ? (
                <>
                  <div class="alert alert-warning" role="alert">
                    <div class="alert-heading">İade Talebi Bekleniyor </div>
                    {item.returnReason} {item.extraditionDescription} <hr></hr>{" "}
                    {item.extraditionNote}
                  </div>
                </>
              ) : (
                <Button
                  className="btn btn-dark"
                  onClick={(e) =>
                    handleExtradition(
                      e,
                      item.code + " siparişini  ",
                      "detail",
                      item.orderDetailId
                    )
                  }
                >
                  İade Et
                </Button>
              )}
            </div>
          </>
        ) : (
          <h6 className="text-danger">Red Edildi</h6>
        )}
      </Col>
    </Row>
  );
};

export default OrderDetailItem;
