import React from "react";
import { getList, post } from "../../utils/axiosServices";
import SwalServices from "../../utils/swalServices";

export const getCode = (code, handleData) => {
  debugger;
  getList("api/Auth/GetCode", { code })
    .then((a) => {
      handleData(true);
    })
    .catch((error) => {
      handleData(false);
    });
};

export const onSubmit = (setData, setbtnLoading, value, code) => {
  setbtnLoading(true);
  debugger;
  post("/api/Auth/ForgotPassportConfirm", {
    code,
    password: value.password,
    passwordR: value.passwordR,
  })
    .then((a) => {
      setbtnLoading(false);
      setData(true);
    })
    .catch(function (error) {
      setbtnLoading(false);
      SwalServices.error(
        error.response ? error.response.data : "Hata oluştu." + error.message
      );

      return;
    });
};
