import React from "react";
import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import { LogoWithLink } from "../../ui/logo";
import "./auth.css";
const AuthLayout = () => {
  return (
    <div className="account-bg">
      <div className="account-pages my-5 pt-2">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-10 col-xl-9">
              <div className="card login-card">
                <div className="card-body row">
                  <div className="col-5 d-none d-md-block br-right login-right-img rounded p-0">
                    <div className="login-right-text d-flex align-items-center">
                      <h4 className="ms-4 pb-5">
                        Sipariş Ver
                        <br />
                        Anında Sette !
                      </h4>
                    </div>
                  </div>

                  <div className="col-md-7 col-sm-12 col-xs-12  px-5 pt-1 pb-1">
                    <div className=" text-center ">
                      <LogoWithLink />
                    </div>
                    <Outlet />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
