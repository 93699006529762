import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import logger from "redux-logger";
import thunk from "redux-thunk";

// Initial State
const initialState = {
  user: readUserFromLocalStorage(),
  cart: readCartFromLocalStorage(),
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_LOGIN":
      return {
        ...state,
        user: action.payload,
      };
    case "USER_LOGOUT":
      return {
        ...state,
        user: null,
      };
    case "FILL_CART":
      return {
        ...state,
        cart: action.cart,
      };

    default:
      return state;
  }
};

// Middleware
const middleware = (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(logger);

// Store
const store = configureStore({
  reducer,
  middleware,
});

// Action Creators
export const userLogin = (userData) => (dispatch) => {
  localStorage.setItem("users", JSON.stringify(userData));

  return timeoutPromise(1000).then(() =>
    dispatch({
      type: "USER_LOGIN",
      payload: userData,
    })
  );
};

export const userLogout = () => (dispatch) => {
  localStorage.removeItem("users");

  return dispatch({
    type: "USER_LOGOUT",
  });
};

export const addCart = (product) => (dispatch, getState) => {
  
  const { cart } = getState();
  const image =
    product.images && product.images.length > 0
      ? product.images[0]
      : product.image
      ? product.image
      : null;
  const cartItem = {
    id: product.id,
    brandName: product.brandName,
    code: product.code,
    categoryName: product.categoryName,
    image: image,
    name: product.name,
    quality: product.quality,
    storageName: product.storageName,
  };

  const existingCartItem = cart.find((item) => item.id === product.id);
  let updatedCart;

  if (existingCartItem) {
    updatedCart = cart.map((item) =>
      item.id === product.id
        ? { ...item, quantity: (item.quantity || 1) + 1 }
        : item
    );
  } else {
    updatedCart = [...cart, { ...cartItem, quantity: 1 }];
  }

  localStorage.setItem("cart", JSON.stringify(updatedCart));
  return timeoutPromise().then(() =>
    dispatch({
      type: "FILL_CART",
      cart: updatedCart,
    })
  );
};

export const addCartArray = (productArray) => (dispatch, getState) => {
  const { cart } = getState();

  // Map through the product array and create cart items
  const newCartItems = productArray.map((product) => {
    const image =
      product.images && product.images.length > 0
        ? product.images[0]
        : product.image
        ? product.image
        : null;
    return {
      id: product.id,
      brandName: product.brandName,
      code: product.code,
      categoryName: product.categoryName,
      image: image,
      name: product.name,
      quality: product.quality,
      storageName: product.storageName,
    };
  });

  // Merge the new cart items with the existing cart
  let updatedCart = [...cart];
  newCartItems.forEach((newItem) => {
    const existingCartItem = updatedCart.find((item) => item.id === newItem.id);
    if (existingCartItem) {
      updatedCart = updatedCart.map((item) =>
        item.id === newItem.id
          ? { ...item, quantity: (item.quantity || 1) + 1 }
          : item
      );
    } else {
      updatedCart.push({ ...newItem, quantity: 1 });
    }
  });

  // Save the updated cart to local storage
  localStorage.setItem("cart", JSON.stringify(updatedCart));

  // Dispatch the action to update the cart in the redux store
  return timeoutPromise().then(() =>
    dispatch({
      type: "FILL_CART",
      cart: updatedCart,
    })
  );
};

export const removeFromCart = (productId) => (dispatch, getState) => {
  
  const { cart } = getState();

  const updatedCart = cart.filter((item) => item.id !== productId);
  localStorage.setItem("cart", JSON.stringify(updatedCart));
  return timeoutPromise().then(() =>
    dispatch({
      type: "FILL_CART",
      cart: updatedCart,
    })
  );
};

export const removeAllCart = () => (dispatch, getState) => {
  

  localStorage.removeItem("cart");
  return timeoutPromise().then(() =>
    dispatch({
      type: "FILL_CART",
      cart: readCartFromLocalStorage(),
    })
  );
};

// Helper function: Returns a Promise that resolves after a specified timeout
const timeoutPromise = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

// Helper function: Reads user information from localStorage
function readUserFromLocalStorage() {
  try {
    const serialized = localStorage.getItem("users");
    return serialized ? JSON.parse(serialized) : undefined;
  } catch (error) {
    console.error("Error reading user from localStorage:", error);
    return undefined;
  }
}

function readCartFromLocalStorage() {
  try {
    const serialized = localStorage.getItem("cart");
    return serialized ? JSON.parse(serialized) : [];
  } catch (error) {
    console.error("Error reading user from localStorage:", error);
    return undefined;
  }
}

export default store;
