import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Button, Col, Pagination, Row, Table } from "react-bootstrap";
import { BreadcrumbQualityes } from "../../../utils/breadcrumbAdmin";
import AdminContainer from "../../../layout/adminLayout/Component/adminContainer";
import { getAll, removeById } from "./qualityesServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import SwalServices from "../../../utils/swalServices";

import New from "./component/new";
import Update from "./component/update";
import SearchButton from "../../../ui/searchButton";
const Qualityes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setdata] = useState();

  const [rowCount, setrowCount] = useState(null);

  const [filter, setfilter] = useState();

  const [newItem, setNewItem] = useState();
  const [updateItem, setUpdateItem] = useState();
  const [modelExtradition, setModelExtradition] = useState(false);

  useEffect(() => {
    fillData();
  }, [location]);

  const fillData = () => {
    const newFilter = {
      page: searchParams.get("page") || 1,
      size: searchParams.get("size") || 20,
      filter: searchParams.get("search") || "",
    };
    setfilter(newFilter);
    getAll(newFilter, setfilter, setrowCount, setdata);
  };

  const handleRedirect = () => {
    searchParams.set("page", 1);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleRemove = (id, title) => {
    const removeAct = () => {
      removeById(id, setrowCount, setdata, handleRedirect);
    };

    SwalServices.confirm(
      "İçerik Sil",
      title + "  özelliğini silmek istediğinize emin misiniz?",
      removeAct
    );
  };

  const HandleClose = () => {
    setNewItem();
    setUpdateItem();
  };

  const handleSearch = (text) => {
    searchParams.set("page", 1);
    searchParams.set("search", text);

    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  return (
    <>
      <Helmet title="Ogm Pictures - Nitelikler"></Helmet>
      {newItem && (
        <New show={newItem} handleClose={HandleClose} filldata={fillData} />
      )}
      {updateItem && (
        <Update
          show={updateItem}
          handleClose={HandleClose}
          filldata={fillData}
        />
      )}
      <AdminContainer>
        {" "}
        <BreadcrumbQualityes />{" "}
        <Row>
          {" "}
          <Col sm={6} className="col-6">
            <div style={{ maxWidth: "350px" }}>
              <SearchButton
                handleSearch={handleSearch}
                placeholder={"Nitelik Adı"}
                value={filter && filter.filter}
              />
            </div>
          </Col>
          <Col className="text-end mb-2 col-6" sm={6}>
            {" "}
            <Button variant="dark" onClick={() => setNewItem(true)}>
              {" "}
              <FontAwesomeIcon icon={faCirclePlus} /> Yeni Nitelik
            </Button>{" "}
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <div>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Başlık</th>
                  <th style={{ width: "150px" }} className="text-center">
                    İşlemler
                  </th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  (data.length === 0 ? (
                    <tr>
                      <td colSpan={2}>
                        {" "}
                        <div className="text-center"> İçerik Bulunamadı.</div>
                      </td>
                    </tr>
                  ) : (
                    data.map((item, i) => (
                      <tr>
                        <td>{item.qualityContent}</td>
                        <td className="text-center">
                          <Button
                            variant="success"
                            onClick={() =>
                              setUpdateItem({
                                id: item.id,
                                content: item.qualityContent,
                              })
                            }
                          >
                            <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                          </Button>{" "}
                          <Button
                            variant="danger"
                            onClick={() =>
                              handleRemove(item.id, item.qualityContent)
                            }
                          >
                            <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                          </Button>
                        </td>
                      </tr>
                    ))
                  ))}
              </tbody>
            </Table>
          </div>{" "}
          <div className="d-flex justify-content-center">
            {rowCount > 0 && (
              <Pagination
                currentPage={parseInt(searchParams.get("page")) || 1}
                size={filter.size}
                rowCount={rowCount}
              ></Pagination>
            )}
          </div>{" "}
        </Row>
      </AdminContainer>
    </>
  );
};

export default Qualityes;
