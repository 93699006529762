import React, { useEffect, useState } from "react";
import LoadSpin from "../../../ui/loadSpin";
import { getFormatDatetime } from "../../../utils/utilitiesservices";
import { Col, Row, Table } from "react-bootstrap";
import { getByIdPrint } from "./ordersServices";
import { useParams } from "react-router-dom";
import { Logo } from "../../../ui/logo";
import OrderStatus from "../../../utils/OrderStatus";

const OrderDetailPrint = () => {
  const { id } = useParams();
  const [item, setItem] = useState();

  useEffect(() => {
    getByIdPrint(id, setItem);
  }, []);

  useEffect(() => {
    if (item) {
      setTimeout(print(), 2000);
    }
  }, [item]);

  const print = () => {
    window.print();
    setTimeout(window.close, 0);
  };
  return (
    <div className="mt-5">
      {item ? (
        <div className="col-md-12 mb-3 text-start">
          <Row className="d-flex justify-content-center align-items-center">
            {" "}
            <Col xs={12} sm={8} md={8} className="py-3 order-prop">
              <div>
                <span>Sipariş Numarası </span>:{item.siparisNo}
              </div>
              <div>
                {" "}
                <span>Sipariş Durumu</span>:
                {item.status === OrderStatus.Wait ? (
                  <label className="text-warning">Beklemede.</label>
                ) : item.status === OrderStatus.Approval ? (
                  <label className="text-success">Onaylandı.</label>
                ) : (
                  <label className="text-danger">Red Edildi.</label>
                )}
              </div>
              <div>
                {" "}
                <span>Notlar </span>: {item.note}
              </div>{" "}
              <div>
                {" "}
                <span>Sipariş Veren Kullanıcı </span>: {item.username}
              </div>
              <div>
                {" "}
                <span>Teslim Edilecek Proje</span>: {item.project}
              </div>
              <div>
                {" "}
                <span>Sipariş Tarihi </span>:{" "}
                {getFormatDatetime(item.createDate, item.createDate)}
              </div>
              {item.status !== OrderStatus.Wait && (
                <>
                  {" "}
                  <div>
                    {" "}
                    <span>Onaylayan Kullanıcı </span>: {item.approwUsername}
                  </div>{" "}
                  <div>
                    {" "}
                    <span>Onaylayan Kullanıcı Notu </span>: {item.Description}
                  </div>
                </>
              )}
            </Col>
            {item &&
              item.orderList &&
              item.orderList.map((detailItem) => (
                <Row
                  key={detailItem.id}
                  className="carditem m-1 "
                  style={{
                    width: " 90%",
                    margin: "auto",
                    boxShadow: "none",
                  }}
                >
                  <Col
                    xs={12}
                    sm={4}
                    md={4}
                    className=" d-flex justify-content-center align-items-center "
                  >
                    <div className="cart-board">
                      <div className="text-center m-3">
                        {detailItem.image ? (
                          <img
                            style={{ maxHeight: "150px", maxWidth: "150px" }}
                            src={
                              "/images/products/mini/" +
                              detailItem.image.fileName
                            }
                            alt={detailItem.name}
                          />
                        ) : (
                          <Logo></Logo>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={8} md={8} className="py-3 card-prop">
                    <div>
                      <h6>{detailItem.name}</h6>
                    </div>
                    <div>
                      {" "}
                      <strong>Ürün Kodu</strong>: {detailItem.code}
                    </div>
                    <div>
                      {" "}
                      <strong>Bağlı Olduğu Depo</strong>:{" "}
                      {detailItem.storageName}
                    </div>
                    <div>
                      {" "}
                      <strong>Marka</strong>: {detailItem.brandName}
                    </div>{" "}
                    <>
                      {detailItem.status === OrderStatus.Approval ? (
                        <div>
                          {detailItem.description && (
                            <div className="alert alert-success">
                              {" "}
                              {detailItem.description}
                            </div>
                          )}
                          {detailItem.extradition === true ? (
                            <>
                              <div class="alert alert-success" role="alert">
                                <h4 class="alert-heading">İade Kabul Edildi</h4>{" "}
                                {detailItem.ExtraditionDescription} <hr></hr>{" "}
                                {detailItem.ExtraditionNote}
                              </div>
                            </>
                          ) : detailItem.extradition === false ? (
                            <>
                              <div class="alert alert-warning" role="alert">
                                <h4 class="alert-heading">
                                  İade Talebi Bekleniyor{" "}
                                </h4>
                                {detailItem.ExtraditionDescription} <hr></hr>{" "}
                                {detailItem.ExtraditionNote}
                              </div>
                            </>
                          ) : (
                            <>
                              <span className="text-success">
                                Sipariş Onaylandı
                              </span>
                            </>
                          )}
                        </div>
                      ) : detailItem.status === OrderStatus.Reject ? (
                        <div>
                          {" "}
                          {detailItem.description && (
                            <div className="alert alert-danger">
                              {" "}
                              {detailItem.description}
                            </div>
                          )}
                          <span className="text-danger">
                            Sipariş Red Edildi
                          </span>
                        </div>
                      ) : (
                        <span></span>
                      )}
                    </>
                  </Col>
                </Row>
              ))}
          </Row>
        </div>
      ) : (
        <LoadSpin></LoadSpin>
      )}
    </div>
  );
};

export default OrderDetailPrint;
