import SwalServices from "../../utils/swalServices";
import { post } from "../../utils/axiosServices";

export const getApprow = (code, setData, navigate) => {
  const redirect = () => {
    navigate("/login");
  };

  post("api/Auth/GetUserApprow", { code: code })
    .then((a) => {
      setData(a.data);
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.confirmSignle("Geçersiz İstek", "", redirect);
    });
};

export const postApprow = async (code, type, success, errorF) => {
  post("api/Auth/PostUserApprow", { code: code, type: type })
    .then((a) => {
      success();
    })
    .catch((error) => {
      console.log(error);
      errorF(error);
    });
};

export const PostPhoneApprow = (code, values, success, errorF) => {
  post("/api/Auth/PostPhoneApprow", { code: code, phoneCode: values.code })
    .then((a) => {
      success(a);
    })
    .catch(function (error) {
      errorF(error);
    });
};
