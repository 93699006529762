import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/redux";
import ToRoute from "./route/toRoute";
import "@fortawesome/fontawesome-svg-core/styles.css";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Provider store={store}>
          <ToRoute></ToRoute>
        </Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
