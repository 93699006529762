import React, { useState, useRef } from "react";
import { Col } from "react-bootstrap";
import "./ProductGallery.css"; // CSS dosyasını unutmayın

const ProductGallery = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(images[0]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [lensPosition, setLensPosition] = useState({ x: 0, y: 0 });
  const [isZoomed, setIsZoomed] = useState(false);

  const imageRef = useRef(null);

  const handlePrev = () => {
    const newIndex = Math.max(currentIndex - 1, 0);
    setCurrentIndex(newIndex);
    setCurrentImage(images[newIndex]);
  };

  const handleNext = () => {
    const newIndex = Math.min(currentIndex + 1, images.length - 1);
    setCurrentIndex(newIndex);
    setCurrentImage(images[newIndex]);
  };

  const handleMouseMove = (e) => {
    if (imageRef.current) {
  
      const { left, top, width, height } =
        imageRef.current.getBoundingClientRect();
      const x = e.clientX - left;
      const y = e.clientY - top;

      const lensSize = 600; // Lens boyutu
      const zoomScale = 2; // Zoom ölçeği

      const maxX = width - lensSize / zoomScale;
      const maxY = height - lensSize / zoomScale;

      // Lens konumunu sınırlandır
      const limitedX = Math.max(0, Math.min(x - lensSize / 2, maxX));
      const limitedY = Math.max(0, Math.min(y - lensSize / 2, maxY));

      setLensPosition({ x: limitedX, y: limitedY });
      setIsZoomed(true);
    }
  };

  const handleMouseLeave = () => {
    setIsZoomed(false);
  };

  const lensSize = 600; // Lens boyutu
  const zoomScale = 2; // Zoom ölçeği

  return (
    <>
      <Col md={12}>
        <div className="main-image-container">
          {isZoomed && (
            <div
              className="lens"
              style={{
                position: "absolute",
                top: 0,
                right: "-600px", // Sağda çıkmasını sağlar
                width: "600px",
                height: "600px",
                backgroundImage: `url(/images/products/max/${currentImage.fileName})`,
                backgroundSize: `${
                  imageRef.current.clientWidth * zoomScale
                }px ${imageRef.current.clientHeight * zoomScale}px`,
                backgroundPosition: `-${lensPosition.x * zoomScale}px -${
                  lensPosition.y * zoomScale
                }px`,
                zIndex: 999999, // Lensin diğer elementlerin üzerinde olması için
              }}
            />
          )}
          <div className="zoom-wrapper">
            <img
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              ref={imageRef}
              src={`/images/products/max/${currentImage.fileName}`}
              alt={currentImage.title}
              className="main-image"
            />
          </div>
          {images.length > 1 && (
            <>
              <button onClick={handlePrev} className="nav-button prev">
                &lt;
              </button>
              <button onClick={handleNext} className="nav-button next">
                &gt;
              </button>
            </>
          )}
        </div>
      </Col>

      <Col md={12}>
        <div className="thumbnail-container">
          {images.map((image, index) => (
            <div
              key={index}
              onClick={() => {
                setCurrentIndex(index);
                setCurrentImage(image);
              }}
              className={`thumbnail ${image === currentImage ? "active" : ""}`}
            >
              <img
                src={`/images/products/mini/${image.fileName}`}
                alt={image.title}
                width={100}
              />
            </div>
          ))}
        </div>
      </Col>
    </>
  );
};

export default ProductGallery;
