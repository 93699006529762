import { Logo } from "../../../ui/logo";

import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState } from "react";

import SwalServices from "../../../utils/swalServices";
// , setModelExtradition
const OrderDetailItem = ({ item }) => {
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState("");

  const handleApprow = (e, state) => {
    e.preventDefault();

    const success = () => {
      setLoading(true);
      //   postOrderDetailUpdate(
      //     item.oderDetailId,
      //     note,
      //     state,
      //     setModelExtradition,
      //     setLoading
      //   );
    };
    SwalServices.confirm(
      "Sipariş detayı onayı",
      item.name +
        " siparişini " +
        (state ? "onaylamak" : "red etmek") +
        " istediğinize emin misiniz?",
      success
    );
  };

  return (
    <Row key={item.id} className="carditem m-4">
      <Col xs={12} sm={4} md={4}>
        <div className="cart-board  w-100 h-100">
          <div
            className={" carditemimage-bg "}
            style={
              item.image
                ? {
                    backgroundImage: `url(/images/products/mini/${item.image.fileName})`,
                  }
                : {}
            }
          >
            <Link to={"#"}>
              {item.image ? (
                <img
                  className="cardImg"
                  src={"/images/products/mini/" + item.image.fileName}
                  alt={item.name}
                />
              ) : (
                <Logo></Logo>
              )}
            </Link>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={8} md={8} className="py-3 card-prop">
        <Row>
          <Col xs={12} sm={6} md={6} className="border-right">
            {" "}
            <div>
              <h6>{item.name}</h6>
            </div>
            <div>
              {" "}
              <strong>Ürün Kodu</strong>: {item.code}
            </div>
            <div>
              {" "}
              <strong>Bağlı Olduğu Depo</strong>: {item.storageName}
            </div>{" "}
            {item.project && (
              <div>
                <strong>Projesi</strong>: {item.project}
              </div>
            )}
          </Col>{" "}
          <Col xs={12} sm={6} md={6}>
            {" "}
            <div>
              {" "}
              <strong>Kullanıcı</strong>: {item.username}
            </div>{" "}
            <div>
              {" "}
              <strong>İade Nedeni</strong>: {item.returnReason}
            </div>
            <div>
              {" "}
              <strong>İade Notu</strong>: {item.extraditionNote}
            </div>
            {item.approvedUser && (
              <>
                {" "}
                <div>
                  {" "}
                  <strong>Onaylayan Kullanici</strong>: {item.approvedUser}
                </div>
                <div>
                  {" "}
                  <strong>Onaylayan Notu</strong>: {item.extraditionDescription}
                </div>
              </>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default OrderDetailItem;
