import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Badge, Button, Col, Pagination, Row, Table } from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AdminContainer from "../../../layout/adminLayout/Component/adminContainer";
import { BreadcrumbWarehouse } from "../../../utils/breadcrumbAdmin";
import { getAll, removeById } from "./wareHouseServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import SwalServices from "../../../utils/swalServices";
import New from "./component/new";
import Update from "./component/update";
import SearchButton from "../../../ui/searchButton";

const WareHouse = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setdata] = useState();

  const [newItem, setNewItem] = useState();
  const [updateItem, setUpdateItem] = useState();
  const [rowCount, setrowCount] = useState(null);

  const [filter, setfilter] = useState();

  const [modelExtradition, setModelExtradition] = useState(false);

  useEffect(() => {
    fillData();
  }, [location]);

  const fillData = () => {
    const newFilter = {
      page: searchParams.get("page") || 1,
      size: searchParams.get("size") || 20,
      filter: searchParams.get("search") || "",
    };
    setfilter(newFilter);
    getAll(newFilter, setfilter, setrowCount, setdata);
  };

  const handleRedirect = () => {
    searchParams.set("page", 1);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleRemove = (id, title) => {
    const removeAct = () => {
      removeById(id, setrowCount, setdata, handleRedirect);
    };

    SwalServices.confirm(
      "Depo Sil",
      title + "Depo silmek istediğinize emin misiniz?",
      removeAct
    );
  };

  const HandleClose = () => {
    setNewItem();
    setUpdateItem();
  };

  const handleSearch = (text) => {
    searchParams.set("page", 1);
    searchParams.set("search", text);

    navigate(`${location.pathname}?${searchParams.toString()}`);
  };
  return (
    <>
      {newItem && (
        <New show={newItem} handleClose={HandleClose} filldata={fillData} />
      )}
      {updateItem && (
        <Update
          show={updateItem}
          handleClose={HandleClose}
          filldata={fillData}
        />
      )}
      <Helmet title="Ogm Pictures -Depolar"></Helmet>

      <AdminContainer>
        <BreadcrumbWarehouse />
        <Row>
          {" "}
          <Col sm={6} className="col-6">
            <div style={{ maxWidth: "350px" }}>
              <SearchButton
                handleSearch={handleSearch}
                placeholder={"Depo Adı"}
                value={filter && filter.filter}
              />
            </div>
          </Col>
          <Col className="text-end mb-2 col-6">
            <Button variant="dark" onClick={() => setNewItem(true)}>
              <FontAwesomeIcon icon={faCirclePlus} /> Yeni Depo
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <div>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Adı</th>
                  <th style={{ width: "150px", minWidth: "150px" }}>Yer</th>
                  <th
                    style={{ width: "150px", minWidth: "150px" }}
                    className="text-center"
                  >
                    Durumu
                  </th>
                  <th
                    style={{ width: "150px", minWidth: "150px" }}
                    className="text-center"
                  >
                    İşlemler
                  </th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  (data.length === 0 ? (
                    <tr>
                      <td colSpan={4}>
                        {" "}
                        <div className="text-center"> İçerik Bulunamadı.</div>
                      </td>
                    </tr>
                  ) : (
                    data.map((item, i) => (
                      <tr>
                        <td>{item.storageName}</td>
                        <td>{item.location}</td>
                        <td className="text-center">
                          {item.status ? (
                            <Badge bg="success">Aktif</Badge>
                          ) : (
                            <Badge bg="danger">Pasif</Badge>
                          )}
                        </td>
                        <td className="text-center" style={{ width: "150px" }}>
                          <Button
                            variant="success"
                            onClick={() => setUpdateItem(item.id)}
                          >
                            <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                          </Button>{" "}
                          {/* <Button
                          variant="danger"
                          onClick={() =>
                            handleRemove(item.id, item.storageName)
                          }
                        >
                          <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                        </Button> */}
                        </td>
                      </tr>
                    ))
                  ))}
              </tbody>
            </Table>
          </div>
          <div className="d-flex justify-content-center">
            {rowCount > 0 && (
              <Pagination
                currentPage={parseInt(searchParams.get("page")) || 1}
                size={filter.size}
                rowCount={rowCount}
              ></Pagination>
            )}
          </div>{" "}
        </Row>
      </AdminContainer>
    </>
  );
};

export default WareHouse;
