import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RegisterProjectList } from "./registerServices";
import LoadSpin from "../../ui/loadSpin";

import InputMask from "react-input-mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const RegisterForm = ({ onSubmit, btnLoading }) => {
  const [project, setProject] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordR, setShowPasswordR] = useState(false);

  const toggleShowPassword = (setShow) => {
    setShow((prevState) => !prevState);
  };
  useEffect(() => {
    RegisterProjectList(setProject);
  }, []);

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Adınızı giriniz.")
      .min(3, "Ad en az 3 karakter olmalıdır.")
      .matches(
        /^[a-zA-ZığüşöçİĞÜŞÖÇ ]+$/,
        "Ad alanı sadece harflerden oluşmalıdır."
      ),
    surname: Yup.string()
      .required("Soyadınızı giriniz.")
      .min(2, "Soyad en az 2 karakter olmalıdır.")
      .matches(
        /^[a-zA-ZığüşöçİĞÜŞÖÇ ]+$/,
        "Soyad alanı sadece harflerden oluşmalıdır."
      ),
    email: Yup.string()
      .required("E-posta giriniz.")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        "Geçerli bir e-posta adresi giriniz."
      ),
    phone: Yup.string()
      .required("Telefon numaranızı giriniz.")
      .matches(
        /^\(\d{3}\) \d{3} \d{2} \d{2}$/,
        "Geçerli bir telefon numarası giriniz."
      ),
    project: Yup.object().required("Proje seçiniz."),
    password: Yup.string()
      .min(6, "En az 6 karakter olmalı")
      .required("Şifrenizi giriniz.")
      .matches(/^\S*$/, "Şifre boşluk karakteri içeremez."),
    passwordR: Yup.string()
      .oneOf([Yup.ref("password"), null], "Şifreler eşleşmiyor")
      .required("Şifrenizi tekrar giriniz."),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      surname: "",
      email: "",
      phone: "",
      project: null,
      password: "",
      passwordR: "",
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <>
      {project ? (
        <form
          className="form-horizontal row"
          method="post"
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <div className="col-md-12 mb-3 text-start">
            <label className="form-label" htmlFor="name">
              Ad <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control ${
                formik.touched.name && formik.errors.name ? "is-invalid" : ""
              }`}
              name="name"
              placeholder="Adınızı giriniz."
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="invalid-feedback">{formik.errors.name}</div>
            ) : null}
          </div>

          <div className="col-md-12 mb-3 text-start">
            <label className="form-label" htmlFor="surname">
              Soyad <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control ${
                formik.touched.surname && formik.errors.surname
                  ? "is-invalid"
                  : ""
              }`}
              name="surname"
              placeholder="Soyadınızı giriniz."
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.surname}
            />
            {formik.touched.surname && formik.errors.surname ? (
              <div className="invalid-feedback">{formik.errors.surname}</div>
            ) : null}
          </div>

          <div className="col-md-12 mb-3 text-start">
            <label className="form-label mb-3" htmlFor="email">
              E-posta <span className="text-danger">*</span>
            </label>
            <input
              type="email"
              className={`form-control ${
                formik.touched.email && formik.errors.email ? "is-invalid" : ""
              }`}
              name="email"
              placeholder="E-posta adresinizi giriniz."
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="invalid-feedback">{formik.errors.email}</div>
            ) : null}
          </div>

          <div className="col-md-12 mb-3 text-start">
            <label className="form-label" htmlFor="phone">
              Telefon <span className="text-danger">*</span>
            </label>
            <InputMask
              mask="(999) 999 99 99"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              {(inputProps) => (
                <input
                  type="text"
                  {...inputProps}
                  className={`form-control ${
                    formik.touched.phone && formik.errors.phone
                      ? "is-invalid"
                      : ""
                  }`}
                  name="phone"
                  placeholder="Telefon numaranızı giriniz."
                />
              )}
            </InputMask>
            {formik.touched.phone && formik.errors.phone ? (
              <div className="invalid-feedback">{formik.errors.phone}</div>
            ) : null}
          </div>

          <div className="col-md-12 mb-3 text-start" style={{ zIndex: 5 }}>
            <label className="form-label" htmlFor="project">
              Proje <span className="text-danger">*</span>
            </label>
            <Select
              options={
                project &&
                project.map((item) => ({
                  value: item.id,
                  label: item.storageName,
                }))
              }
              placeholder="Proje seçiniz..."
              isClearable={true}
              onChange={(selectedOption) => {
                formik.setFieldValue("project", selectedOption);
              }}
              className={`react-select-container ${
                formik.touched.project && formik.errors.project
                  ? "is-invalid"
                  : ""
              }`}
            />
            {formik.touched.project && formik.errors.project ? (
              <div className="invalid-feedback d-block">
                {formik.errors.project}
              </div>
            ) : null}
          </div>

          <div className="col-md-12 mb-3 text-start">
            <label className="form-label" htmlFor="password">
              Şifre <span className="text-danger">*</span>
            </label>
            <div className="input-group">
              <input
                type={showPassword ? "text" : "password"} // Şifreyi göster/gizle
                className={`form-control ${
                  formik.touched.password && formik.errors.password
                    ? "is-invalid"
                    : ""
                }`}
                name="password"
                placeholder="Şifrenizi giriniz."
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />{" "}
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={() => toggleShowPassword(setShowPassword)}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </button>
            </div>
            {formik.touched.password && formik.errors.password ? (
              <div className="invalid-feedback  d-block">
                {formik.errors.password}
              </div>
            ) : null}
          </div>

          <div className="col-md-12 mb-3 text-start">
            <label className="form-label" htmlFor="passwordR">
              Şifre Tekrar <span className="text-danger">*</span>
            </label>
            <div className="input-group">
              {" "}
              <input
                type={showPasswordR ? "text" : "password"} // Şifreyi göster/gizle
                className={`form-control ${
                  formik.touched.passwordR && formik.errors.passwordR
                    ? "is-invalid"
                    : ""
                }`}
                name="passwordR"
                placeholder="Şifrenizi tekrar giriniz."
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.passwordR}
              />{" "}
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={() => toggleShowPassword(setShowPasswordR)}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </button>
            </div>
            {formik.touched.passwordR && formik.errors.passwordR ? (
              <div className="invalid-feedback  d-block">
                {formik.errors.passwordR}
              </div>
            ) : null}
          </div>

          {!btnLoading ? (
            <div className="mb-3 row">
              <div className="col-sm-12">
                <button
                  className="btn btn-dark w-100 mt-2 waves-effect waves-light lgn-btn"
                  type="submit"
                >
                  Üye Ol
                </button>
              </div>
            </div>
          ) : (
            <>
              <div className="mb-3 row">
                <div className="col-sm-12">
                  <button
                    disabled={true}
                    className="btn btn-dark w-100 mt-2 waves-effect waves-light lgn-btn"
                    type="submit"
                  >
                    Üye Ol...
                  </button>
                </div>
              </div>
            </>
          )}

          <div className="mb-3 text-center footer-register-link">
            <div className="form-check">
              <span> Üye misiniz?</span> <Link to={"/login"}>Giriş Yapın</Link>
            </div>
          </div>
        </form>
      ) : (
        <div className="text-center">
          <LoadSpin></LoadSpin>
        </div>
      )}
    </>
  );
};

export default RegisterForm;
