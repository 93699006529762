import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Col, Row } from "react-bootstrap";
import { create } from "./profilServices";
import SwalServices from "../../../utils/swalServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const UpdatePassword = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [showPasswordActive, setShowPasswordActive] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordR, setShowPasswordR] = useState(false);

  const toggleShowPassword = (setShow) => {
    setShow((prevState) => !prevState);
  };

  const onSubmit = (values) => {
    setBtnLoading(true);

    const success = () => {
      formik.setValues({
        availablePassword: "",
        password: "",
        passwordR: "",
      });
      setBtnLoading(false);
      setIsUpdate(true);

      SwalServices.success("İşlem Başarılı", "Şifreniz başarıyla güncellendi.");
    };

    create(values, setBtnLoading, success);
  };

  const validationSchema = Yup.object({
    availablePassword: Yup.string().required("Mevcut şifrenizi giriniz."),
    password: Yup.string()
      .min(6, "En az 6 karakter olmalı")
      .required("Yeni şifrenizi giriniz.")
      .matches(/^\S*$/, "Şifre boşluk karakteri içeremez."),
    passwordR: Yup.string()
      .oneOf([Yup.ref("password"), null], "Yeni Şifre ile eşleşmiyor")
      .required("Yeni şifrenizi tekrar giriniz."),
  });

  const formik = useFormik({
    initialValues: {
      availablePassword: "",
      password: "",
      passwordR: "",
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <Col className="border-end border-bottom border-start p-3">
      <Row>
        <Col>
          {isUpdate ? (
            <>
              <h3 className="text-success">İşlem Tamamlandı.</h3>
            </>
          ) : (
            <form
              className="form-horizontal row"
              method="post"
              autoComplete="off"
              onSubmit={formik.handleSubmit}
            >
              <div className="col-md-12 mb-3 text-start">
                <label className="form-label" htmlFor="availablePassword">
                  Mevcut Şifreniz <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    type={showPasswordActive ? "text" : "password"} // Şifreyi göster/gizle
                    className={`form-control ${
                      formik.touched.availablePassword &&
                      formik.errors.availablePassword
                        ? "is-invalid"
                        : ""
                    }`}
                    name="availablePassword"
                    placeholder="Mevcut şifrenizi giriniz."
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.availablePassword}
                  />{" "}
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => toggleShowPassword(setShowPasswordActive)}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </button>
                </div>
                {formik.touched.availablePassword &&
                formik.errors.availablePassword ? (
                  <div className="invalid-feedback show">
                    {formik.errors.availablePassword}
                  </div>
                ) : null}
              </div>

              <div className="col-md-12 mb-3 text-start">
                <label className="form-label" htmlFor="password">
                  Yeni Şifre <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className={`form-control ${
                      formik.touched.password && formik.errors.password
                        ? "is-invalid"
                        : ""
                    }`}
                    name="password"
                    placeholder="Yeni şifrenizi giriniz."
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => toggleShowPassword(setShowPassword)}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </button>
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <div className="invalid-feedback show">
                    {formik.errors.password}
                  </div>
                ) : null}
              </div>

              <div className="col-md-12 mb-3 text-start">
                <label className="form-label" htmlFor="passwordR">
                  Yeni Şifre Tekrar <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <input
                    type={showPasswordR ? "text" : "password"} // Şifreyi göster/gizle
                    className={`form-control ${
                      formik.touched.passwordR && formik.errors.passwordR
                        ? "is-invalid"
                        : ""
                    }`}
                    name="passwordR"
                    placeholder="Şifrenizi tekrar giriniz."
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.passwordR}
                  />{" "}
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => toggleShowPassword(setShowPasswordR)}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </button>
                </div>
                {formik.touched.passwordR && formik.errors.passwordR ? (
                  <div className="invalid-feedback show">
                    {formik.errors.passwordR}
                  </div>
                ) : null}
              </div>

              {!btnLoading ? (
                <div className="mb-3 row">
                  <div className="col-sm-12">
                    <button
                      className="btn btn-dark w-100 mt-2 waves-effect waves-light lgn-btn"
                      type="submit"
                    >
                      Şifremi Güncelle
                    </button>
                  </div>
                </div>
              ) : (
                <div className="mb-3 row">
                  <div className="col-sm-12">
                    <button
                      disabled
                      className="btn btn-dark w-100 mt-2 waves-effect waves-light lgn-btn"
                      type="submit"
                    >
                      Güncelleniyor...
                    </button>
                  </div>
                </div>
              )}
            </form>
          )}
        </Col>
      </Row>
    </Col>
  );
};

export default UpdatePassword;
