import React, { useEffect, useState } from "react";
import {
  faCaretRight,
  faCircleCheck,
  faCircleXmark,
  faList,
  faMagnifyingGlass,
  faPenSquare,
  faPenToSquare,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { getOrderAll, postOrderUpdate } from "./ordersServices";
import Pagination from "../../../utils/pagination";
import LoadSpin from "../../../ui/loadSpin";
import OrderDetailItem from "./orderItem";
import SwalServices from "../../../utils/swalServices";
import OrderStatus from "../../../utils/OrderStatus";

import { Helmet } from "react-helmet";
import { BreadcrumbOrder } from "../../../utils/breadcrumbAdmin";
import AdminContainer from "../../../layout/adminLayout/Component/adminContainer";
import { getFormatDatetime } from "../../../utils/utilitiesservices";
import SearchButton from "../../../ui/searchButton";
import OrderPrint from "./orderPrint";

const Orders = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [orderNo, setOrderNo] = useState("");
  const [data, setdata] = useState();

  const [rowCount, setrowCount] = useState(null);
  const [modelExtradition, setModelExtradition] = useState(false);
  const [modelPrint, setModelPrint] = useState();
  const [modelLoading, setModelLoading] = useState(false);

  const [filter, setfilter] = useState();

  useEffect(() => {
    FillData();
  }, [location]);

  const FillData = () => {
    const newFilter = {
      page: searchParams.get("page") || 1,
      size: searchParams.get("size") || 20,
      filter: searchParams.get("filter") || "",
    };
    setfilter(newFilter);
    getOrderAll(newFilter, setfilter, setrowCount, setdata);
  };
  const handleInputChange = (e) => {
    setOrderNo(e.target.value);
  };

  const handleSearch = (text) => {
    setOrderNo(text);
    searchParams.set("page", 1);
    searchParams.set("filter", text);

    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handlePrintClose = () => {
    setModelPrint();
  };

  const OrderItem = ({ item }) => {
    const handleExtradition = (e, item) => {
      e.preventDefault();

      setModelExtradition(item);
    };
    const handlePrint = (e, item) => {
      e.preventDefault();
      setModelPrint(item);
    };
    return (
      <Row key={item.id} className="carditem">
        <Col xs={12} sm={2} md={2}>
          <div className="cart-board  w-100 h-100">
            <Link
              onClick={(e) => handleExtradition(e, item)}
              className="text-center"
            >
              DETAY GÖSTER
              <br />
              {item.orderList ? item.orderList.length : 0} ADET ÜRÜN
            </Link>
          </div>
        </Col>
        <Col xs={12} sm={8} md={8} className="py-3 order-prop">
          <div>
            {" "}
            <span>Sipariş Numarası </span>: {item.siparisNo}
          </div>
          <div>
            <span>Sipariş Durumu</span>:{" "}
            {item.status === OrderStatus.Wait ? (
              <label className="text-warning">Beklemede.</label>
            ) : item.status === OrderStatus.Approval ? (
              <label className="text-success">Onaylandı.</label>
            ) : (
              <label className="text-danger">Red Edildi.</label>
            )}
          </div>
          <div>
            {" "}
            <span>Notlar </span>: {item.note}
          </div>{" "}
          <div>
            {" "}
            <span>Sipariş Veren Kullanıcı </span>: {item.username}
          </div>
          <div>
            {" "}
            <span>Teslim Edilecek Proje</span>: {item.project}
          </div>
          <div>
            {" "}
            <span>Sipariş Tarihi </span>:{" "}
            {getFormatDatetime(item.createDate, item.createDate)}
          </div>
          {item.status !== OrderStatus.Wait && (
            <>
              {" "}
              <div>
                {" "}
                <span>Onaylayan Kullanıcı </span>: {item.approwUsername}
              </div>{" "}
              <div>
                {" "}
                <span>Onaylayan Kullanıcı Notu </span>: {item.Description}
              </div>
            </>
          )}
        </Col>
        <Col
          xs={12}
          sm={2}
          md={2}
          className="d-flex justify-content-center align-items-center py-3"
        >
          {item.status === OrderStatus.Wait ? (
            <>
              {item.extradition === true ? (
                <>
                  <div class="alert alert-success" role="alert">
                    <h4 class="alert-heading">İade Kabul Edildi</h4>{" "}
                    {item.ExtraditionDescription} <hr></hr>{" "}
                    {item.ExtraditionNote}
                  </div>
                </>
              ) : item.extradition === false ? (
                <>
                  <div class="alert alert-warning" role="alert">
                    <h4 class="alert-heading">İade Talebi Bekleniyor </h4>
                    {item.ExtraditionDescription} <hr></hr>{" "}
                    {item.ExtraditionNote}
                  </div>
                </>
              ) : (
                <Button
                  className="btn btn-dark"
                  onClick={(e) => handleExtradition(e, item)}
                >
                  İşlem
                </Button>
              )}
            </>
          ) : item.status === OrderStatus.Approval ? (
            <span>
              <Button
                className="btn btn-outline btn-success"
                onClick={(e) => handleExtradition(e, item)}
              >
                <FontAwesomeIcon icon={faCircleCheck}></FontAwesomeIcon>{" "}
              </Button>
            </span>
          ) : (
            <span>
              <Button
                className="btn btn-outline btn-danger"
                onClick={(e) => handleExtradition(e, item)}
              >
                <FontAwesomeIcon icon={faCircleXmark}></FontAwesomeIcon>
              </Button>
            </span>
          )}
        </Col>
        <Col className="text-end">
          <Link to={""} onClick={(e) => handlePrint(e, item)}>
            <FontAwesomeIcon
              className="text-black"
              style={{ fontSize: "20px" }}
              icon={faPrint}
            ></FontAwesomeIcon>
          </Link>{" "}
          <Link
            to={"#" + item.id}
            className="text-black"
            onClick={(e) => handleExtradition(e, item)}
          >
            <FontAwesomeIcon
              className="text-black"
              style={{ fontSize: "20px" }}
              icon={faPenToSquare}
            ></FontAwesomeIcon>
          </Link>
        </Col>{" "}
      </Row>
    );
  };

  const handleNoteChange = (e) => {
    e.preventDefault();
    if (modelExtradition) {
      setModelExtradition({ ...modelExtradition, description: e.target.value });
    }
  };

  const handleApprow = (e, state) => {
    if (!state && modelExtradition.description.length < 3) {
      SwalServices.error(
        "Not girinizi",
        "Siparişi red edebilmek için not giriniz."
      );
      return;
    }

    const success = () => {
      postOrderUpdate(
        modelExtradition.id,
        modelExtradition.description,
        state,
        setModelExtradition,
        setModelLoading,
        FillData
      );
    };
    e.preventDefault();
    if (modelExtradition) {
      SwalServices.confirm(
        "Sipariş Onayı",
        "Sipariş " +
          (state ? "onaylamak" : "red etmek") +
          " istediğinize emin misiniz?",
        success
      );
    }
  };
  return (
    <AdminContainer>
      <Helmet title="Ogm Pictures - Siparişler"></Helmet>
      {rowCount === null ? (
        <div className="text-center">
          <LoadSpin />{" "}
        </div>
      ) : (
        <>
          {" "}
          <Container className="m-250">
            <Row className="d-flex justify-content-between">
              <Col xs={12} sm={12} md={12}>
                <BreadcrumbOrder></BreadcrumbOrder>
              </Col>
            </Row>
            <Row>
              <div style={{ maxWidth: "350px" }}>
                <SearchButton
                  handleSearch={handleSearch}
                  placeholder={"Sipariş No"}
                  value={orderNo}
                />
              </div>
            </Row>
            {data && data.length > 0 ? (
              <div className="card-container ">
                {data.map((item) => (
                  <OrderItem item={item}></OrderItem>
                ))}
              </div>
            ) : (
              <div className="card-container-emty text-center d-flex justify-content-center  align-items-center flex-column">
                <div className="card-icon">
                  <FontAwesomeIcon icon={faList} />
                </div>
                <h3>Sipariş bulunamadı.</h3>
                <div>Sistem üzerinden herhangi bir sipariş oluşturulmadı.</div>
              </div>
            )}
            <div className="d-flex justify-content-center">
              {rowCount > 0 && (
                <Pagination
                  currentPage={parseInt(searchParams.get("page")) || 1}
                  size={filter.size}
                  rowCount={rowCount}
                ></Pagination>
              )}
            </div>
          </Container>
        </>
      )}
      {modelPrint && (
        <OrderPrint item={modelPrint} close={handlePrintClose}></OrderPrint>
      )}

      {modelExtradition && (
        <Modal
          size="xl"
          show={modelExtradition}
          onHide={() => {
            setModelExtradition();
          }}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Sipariş Detayı
            </Modal.Title>
          </Modal.Header>
          {modelLoading ? (
            <Modal.Body>
              <Row className="text-center">
                <LoadSpin></LoadSpin>
              </Row>
            </Modal.Body>
          ) : (
            <>
              {" "}
              <Modal.Body>
                <Row>
                  <Col>
                    {modelExtradition &&
                      modelExtradition.orderList &&
                      modelExtradition.orderList.map((detailItem) => (
                        <OrderDetailItem
                          item={detailItem}
                          setModelExtradition={setModelExtradition}
                          FillData={FillData}
                          orderCount={modelExtradition.orderList.length}
                        ></OrderDetailItem>
                      ))}
                  </Col>
                </Row>
              </Modal.Body>
              {modelExtradition.status === OrderStatus.Wait ? (
                <Modal.Footer>
                  <Row className="w-100">
                    <Col xs={12} sm={6} md={6}>
                      {" "}
                      <div className="form-group m-1">
                        <input
                          className="form-control"
                          placeholder="Notunuz"
                          value={modelExtradition.description}
                          onChange={(e) => handleNoteChange(e)}
                        ></input>
                      </div>
                    </Col>
                    <Col xs={12} sm={6} md={6}>
                      {" "}
                      <Button
                        className="m-1"
                        variant="secondary"
                        onClick={() => setModelExtradition()}
                      >
                        Kapat
                      </Button>
                      <Button
                        className="m-1"
                        variant="danger"
                        onClick={(e) => handleApprow(e, false)}
                      >
                        Red Et
                      </Button>
                      <Button
                        className="m-1"
                        variant="success"
                        onClick={(e) => handleApprow(e, true)}
                      >
                        Onayla
                      </Button>
                    </Col>
                  </Row>
                </Modal.Footer>
              ) : modelExtradition.status === OrderStatus.Approval ? (
                <Modal.Footer>
                  <Row className="w-100">
                    <Col xs={12} sm={12} md={12}>
                      {" "}
                      {modelExtradition.description}
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                      <h6 className="text-success">Sipariş Onaylandı.</h6>
                    </Col>
                  </Row>
                </Modal.Footer>
              ) : (
                <Modal.Footer>
                  <Row className="w-100">
                    <Col xs={12} sm={12} md={12}>
                      {" "}
                      {modelExtradition.description}
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                      <h6 className="text-error">Sipariş Red Edildi.</h6>
                    </Col>
                  </Row>
                </Modal.Footer>
              )}
            </>
          )}
        </Modal>
      )}
    </AdminContainer>
  );
};

export default Orders;
