import { Logo } from "../../../ui/logo";

import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { linkTranslate } from "../../../utils/utilitiesservices";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CardItem = ({ item, cartRemove }) => {
  return (
    <Row key={item.id} className="carditem position-relative m-1">
      <Link
        to={"#"}
        className="trash-button w-auto"
        onClick={() => cartRemove(item.id)}
      >
        <FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon>
      </Link>

      <Col xs={12} sm={12} md={4}>
        <div className="cart-board  w-100 h-100">
          <div
            className={" carditemimage-bg "}
            style={
              item.image
                ? {
                    backgroundImage: `url(/images/products/mini/${item.image.fileName})`,
                  }
                : {}
            }
          >
            <Link
              to={"/productdetail" + linkTranslate(item.name) + "/" + item.id}
            >
              {item.image ? (
                <img
                  className="cardImg"
                  src={"/images/products/mini/" + item.image.fileName}
                  alt={item.name}
                />
              ) : (
                <Logo></Logo>
              )}
            </Link>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={12} md={8} className="py-3 card-prop">
        {" "}
        <div>
          <h4>{item.name}</h4>
        </div>
        <div>
          {" "}
          <strong>Ürün Kodu</strong>: {item.code}
        </div>
        <div>
          {" "}
          <strong>Bağlı Olduğu Depo</strong>: {item.storageName}
        </div>
        <div>
          {" "}
          <strong>Marka</strong>: {item.brandName}
        </div>
      </Col>
    </Row>
  );
};

export default CardItem;
