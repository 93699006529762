import React from "react";
import { errorCodeControl, userGetList } from "../../utils/axiosServices";

export const getHeader = (setData) => {
  userGetList("api/Home/GetMainInfo")
    .then((a) => {
      setData(a.data);
    })
    .catch((error) => {
      errorCodeControl(error);
      return [];
    });
};
