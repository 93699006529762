import React from "react";
import { Link } from "react-router-dom";

export const Logo = (props) => {
  return (
    <img
      props
      src="/images/logo.png"
      className="logo-dark mx-auto"
      alt="Ogm Pictures"
    />
  );
};
export const LogoWhite = (props) => {
  return (
    <img
      props
      src="/images/white-logo.png"
      className="  mx-auto"
      alt="Ogm Pictures"
    />
  );
};
export const LogoWithLink = () => {
  return (
    <Link to="#" className="auth-logo">
      <Logo height="100"></Logo>
      {/* <img
        src="/images/logo.png"
        height="100"
        className="logo-dark mx-auto"
        alt="Ogm Pictures"
      /> */}
    </Link>
  );
};

export const LogoWhiteWithLink = () => {
  return (
    <Link to="/" className="auth-logo">
      <LogoWhite height="100"></LogoWhite>
      {/* <img
        src="/images/logo.png"
        height="100"
        className="logo-dark mx-auto"
        alt="Ogm Pictures"
      /> */}
    </Link>
  );
};
