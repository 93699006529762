import React, { useState, useEffect } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import AdminContainer from "../../../layout/adminLayout/Component/adminContainer";
import { BreadcrumbProfil } from "../../../utils/breadcrumbAdmin";
import UpdatePassword from "./updatePassword";
import LoadSpin from "../../../ui/loadSpin";
import { getById } from "./profilServices";

import UpdateInformation from "./updateInformation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { Helmet } from "react-helmet";
import Role from "../../../utils/Role";
const Profil = () => {
  const [data, setData] = useState();

  useEffect(() => {
    getById(setData);
  }, []);

  return (
    <AdminContainer>
      <Helmet title="Ogm Pictures - Profilim"></Helmet>

      <BreadcrumbProfil />

      {data ? (
        <Container>
          <Row className="border rounded mxd-1 mb-3  p-3 ">
            <Col className="d-flex justify-content-start">
              {" "}
              <FontAwesomeIcon
                icon={faUserCircle}
                className="me-4 mt-2"
                style={{ fontSize: "45px" }}
              ></FontAwesomeIcon>{" "}
              <Col>
                <h3>{data.name + " " + data.surname}</h3>{" "}
                <div>
                  {data.role === Role.Standard
                    ? "Üye"
                    : data.role === Role.WareHouse
                    ? "Depo Yöneticisi"
                    : data.role === Role.Admin
                    ? "Admin"
                    : ""}
                </div>
              </Col>
            </Col>{" "}
          </Row>
          <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
            <Tab eventKey={1} title="Profilim">
              <UpdateInformation data={data} />
            </Tab>
            <Tab eventKey={2} title="Şifremi Güncelle">
              <UpdatePassword></UpdatePassword>
            </Tab>
          </Tabs>{" "}
        </Container>
      ) : (
        <Container>
          <Row>
            {" "}
            <Col className="text-center m-5 ">
              <LoadSpin></LoadSpin>
            </Col>{" "}
          </Row>{" "}
        </Container>
      )}
    </AdminContainer>
  );
};

export default Profil;
