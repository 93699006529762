import { addCart, addCartArray } from "../../../redux/redux";
import { errorCodeControl, userGetList } from "../../../utils/axiosServices";
import SwalServices from "../../../utils/swalServices";

export const getProductDetail = (id, setData) => {
  userGetList("api/Product/GetByIdProduct/" + id)
    .then((a) => {
      setData(a.data);
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("Hata Oluştu", err);
      setData([]);
      errorCodeControl(error);
    });
};

export const addCartProduct = (product, dispatch) => {
  try {
    dispatch(addCart(product)).then(() => {
      SwalServices.success("Sepete eklendi");
    });
  } catch (error) {
    
    SwalServices.warning("Hata Oluştu", "Sepete eklenmedi.");
  }
};

export const addCartProductArray = (products, dispatch) => {
  try {
    dispatch(addCartArray(products)).then(() => {
      SwalServices.success("Liste sepete eklendi");
    });
  } catch (error) {
    
    SwalServices.warning("Hata Oluştu", "Sepete eklenmedi.");
    errorCodeControl(error);
  }
};
