import { errorCodeControl, userGetList } from "../../utils/axiosServices";

export const getHeader = (handleNotification) => {
  userGetList("api/profil/GetNotification")
    .then((a) => {
      handleNotification(a.data);
    })
    .catch((error) => {});
};

export const postNotification = (id, handleData) => {
  debugger;
  userGetList("api/profil/PostReadNotification", { Id: id })
    .then((a) => {
      debugger;
      if (handleData && a.data) {
        handleData(a.data);
      }
    })
    .catch((error) => {
      debugger;
      errorCodeControl(error);
    });
};
