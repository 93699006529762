import {
    errorCodeControl,
    userGetList,
    userPost,
  } from "../../../utils/axiosServices";
  import SwalServices from "../../../utils/swalServices";
  
  export const getAll = (filter, setfilter, setrowCount, setData) => {
    userGetList("api/admin/Demand/GetAllExtradition", filter)
      .then((a) => {
        setData(a.data.list);
        setrowCount(a.data.rowsCount);
      })
      .catch((error) => {
        let err = error.response
          ? error.response.data
          : "Hata oluştu." + error.message;
        SwalServices.warning("İşlem başarısız.", err);
        setData([]);
        errorCodeControl(error);
      });
  };
  
  export const create = (data, setIsloading, fillDataAndClose) => {
    setIsloading(true);
    userPost("api/admin/Demand/ExtraditionCreate", data)
      .then((a) => {
        setIsloading(false);
        fillDataAndClose();
        SwalServices.success("İşlem Başarılı");
      })
      .catch((error) => {
        let err = error.response
          ? error.response.data
          : "Hata oluştu." + error.message;
        SwalServices.warning("İşlem başarısız.", err);
        errorCodeControl(error);
        setIsloading(false);
      });
  };
  
  export const update = (data, setIsloading, fillDataAndClose) => {
    setIsloading(true);
    userPost("api/admin/Demand/ExtraditionUpdate", data)
      .then((a) => {
        setIsloading(false);
        fillDataAndClose();
        SwalServices.success("İşlem Başarılı");
      })
      .catch((error) => {
        errorCodeControl(error);
        let err = error.response
          ? error.response.data
          : "Hata oluştu." + error.message;
        SwalServices.warning("İşlem başarısız.", err);
        setIsloading(false);
      });
  };
  
  export const removeById = (id, setrowCount, setData, redirect) => {
    
    userPost("api/admin/Demand/ExtraditionDelete", { Id: id })
      .then((a) => {
        setData(a.data.list);
        setrowCount(a.data.rowsCount);
        SwalServices.success("İşlem Başarılı");
        redirect();
      })
      .catch((error) => {
        errorCodeControl(error);
        let err = error.response
          ? error.response.data
          : "Hata oluştu." + error.message;
        SwalServices.warning("İşlem başarısız.", err);
      });
  };
  