import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useQuery from "../../utils/useQuery";
import {
  getApprow,
  PostPhoneApprow,
  postApprow,
} from "./RegisterConfirmServices";
import LoadSpin from "../../ui/loadSpin";
import { Button, Container } from "react-bootstrap";
import RegisterConfirmPhoneForm from "./RegisterConfirmPhoneForm";
import { post } from "../../utils/axiosServices";
import SwalServices from "../../utils/swalServices";
const RegisterConfirm = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const code = query.get("code");

  const [btnLoading, setbtnLoading] = useState();
  const [data, setData] = useState();
  const [dataSend, setDataSend] = useState({ phone: false, email: false });

  useEffect(() => {
    getApprow(code, setData, navigate, null);
  }, []);

  const HandleSendInformation = async (type) => {
    const successPostUserApprow = () => {
      if (type === "Email") {
        setDataSend({ ...dataSend, email: true });
      }
      if (type === "Phone") {
        setDataSend({ ...dataSend, phone: true });
      }
      SwalServices.success("İşlem başarılı", "E-posta adresinize doğrulama postası gönderildi.");
      setbtnLoading(false);
    };
    const cancelPostUserApprow = (error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      setbtnLoading(false);
    };

    await setbtnLoading(false);
    await postApprow(code, type, successPostUserApprow, cancelPostUserApprow);
  };

  const onSubmit = async (values) => {
    const successGetPhoneApprow = (a) => {
      setData(a.data);
      setbtnLoading(false);
    };
    const errorGetPhoneApprow = (error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      setbtnLoading(false);
    };
    await setbtnLoading(true);
    PostPhoneApprow(code, values, successGetPhoneApprow, errorGetPhoneApprow);
  };

  return (
    <Container>
      <h4 className="font-size-18 mt-2 text-center">Üyelik Oluştur</h4>
      <p>Üyelik kaydınızın devamı için e-posta adresinizi doğrulamalısınız.</p>
      {data ? (
        data.email && data.phone ? (
          <>
            <div class="alert alert-success" role="alert">
            E-posta adresiniz ve telefon numaranız onaylanmıştır.
              <br />
              Sistem yöneticisi tarafından hasabınız onaylandıktan sornra giriş
              yapabilirsiniz.
            </div>
            <div className="text-center">
              <div className="mb-3  text-center footer-register-link">
                <div class="form-check">
                  Üye Girişi <Link to={"/login"}>Giriş Yapın</Link>
                </div>
              </div>
            </div>{" "}
          </>
        ) : (
          <>
            {!btnLoading ? (
              <>
                <div>
                  {" "}
                  <h6>
                  E-posta onayınız beklenmektedir. <span className="text-danger">*</span>
                  </h6>
                  {data.email ? (
                    <>
                      <div class="alert alert-success" role="alert">
                      E-posta adresiniz onaylanmıştır.
                      </div>
                    </>
                  ) : (
                    <>
                      {dataSend.email ? (
                        <div class="alert alert-success" role="alert">
                          E-posta adresinize kod gönderilmiştir.
                        </div>
                      ) : (
                        <div className="text-center">
                          <Button
                            className="btn btn-success"
                            onClick={() => HandleSendInformation("Email")}
                          >
                            Maili tekrar gönder
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                  {/* <hr />
                  <h6>
                    Telefon Onayı <span className="text-danger">*</span>
                  </h6>
                  {data.phone ? (
                    <>
                      <div class="alert alert-success" role="alert">
                        Telefon numarınız onaylanmıştır.
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <div>
                        <RegisterConfirmPhoneForm
                          onSubmit={onSubmit}
                        ></RegisterConfirmPhoneForm>
                      </div>
                      <div className="text-center">
                        {dataSend.phone ? (
                          <div class="alert alert-success" role="alert">
                            Telefon numarınız için kod gönderilmiştir
                          </div>
                        ) : (
                          <div>
                            <Button
                              className="btn btn-success"
                              onClick={() => HandleSendInformation("Phone")}
                            >
                              Kodu tekrar gönder
                            </Button>
                          </div>
                        )}
                      </div>
                    </>
                  )} */}
                </div>
              </>
            ) : (
              <div className="text-center">
                <LoadSpin></LoadSpin>
              </div>
            )}

            <div className="mb-3 text-center footer-register-link">
              <div className="form-check">
                <span> Üye misiniz?</span> <Link to={"/login"}>Giriş Yapın</Link>
              </div>
            </div>
          </>
        )
      ) : (
        <div className="text-center">
          <LoadSpin></LoadSpin>{" "}
        </div>
      )}
    </Container>
  );
};

export default RegisterConfirm;
