import React from "react";
import {
  Link,
  useLocation,
  useSearchParams,
  useNavigate,
} from "react-router-dom";

const Pagination = ({ currentPage, size, rowCount }) => {
  const location = useLocation();
  const [search, setSearch] = useSearchParams();
  const navigate = useNavigate();

  const totalPages = Math.ceil(rowCount / size);

  const generatePageLink = (page) => {
    let searchParams = new URLSearchParams(location.search);
    searchParams.set("page", page);
    // searchParams.set("size", size);
    return `${location.pathname}?${searchParams.toString()}`;
  };

  const handlePageChange = (page) => {
    search.set("page", page);
    navigate(`${location.pathname}?${search.toString()}`);
  };

  return (
    <nav className="theme-pagination">
      {totalPages > 1 && (
        <ul className="pagination">
          {currentPage > 1 && (
            <li className="page-item">
              <Link
                onClick={() => handlePageChange(currentPage - 1)}
                className="page-link"
                to={generatePageLink(currentPage - 1)}
                aria-label="Previous"
              >
                <span aria-hidden="true">«</span>
                <span className="sr-only">Geri</span>
              </Link>
            </li>
          )}
          {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
            let startPage = Math.max(1, currentPage - 2); // Başlangıç sayfasını hesapla
            let endPage = Math.min(totalPages, startPage + 4); // Bitiş sayfasını hesapla

            // Eğer en son sayfaları gösteriyorsak, sayfa numaralarını kaydır.
            if (currentPage > totalPages - 2) {
              startPage = Math.max(1, totalPages - 4);
            }

            // Gösterilecek sayfaları belirle.
            return startPage + i;
          }).map((page) => (
            <li
              className={`page-item ${page === currentPage ? "active" : ""}`}
              key={page}
            >
              <Link
                className="page-link"
                to={generatePageLink(page)}
                onClick={() => handlePageChange(page)}
              >
                {page}
              </Link>
            </li>
          ))}

          {currentPage < totalPages && (
            <li className="page-item">
              <Link
                className="page-link"
                to={generatePageLink(currentPage + 1)}
                onClick={() => handlePageChange(currentPage + 1)}
                aria-label="Next"
              >
                <span aria-hidden="true">»</span>
                <span className="sr-only">İleri</span>
              </Link>
            </li>
          )}
        </ul>
      )}
    </nav>
  );
};

export default Pagination;
