import React from "react";
import {
  errorCodeControl,
  userGetList,
  userPost,
} from "../../../utils/axiosServices";
import SwalServices from "../../../utils/swalServices";

export const getOrderAll = (filter, setfilter, setrowCount, setData) => {
  userGetList("api/admin/Order/GetAll", filter)
    .then((a) => {
      setData(a.data.list);
      setrowCount(a.data.rowsCount);
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      setData([]);
      errorCodeControl(error);
    });
};

export const postOrderDetailUpdate = (
  id,
  note,
  status,
  setModelExtradition,
  setLoading,
  FillData
) => {
  note = note ?? "";
  userPost("api/admin/Order/OrderDetailApproval", { id, note, status })
    .then((a) => {
      setLoading(false);
      FillData();
      setModelExtradition(a.data);
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      setLoading(false);
      errorCodeControl(error);
    });
};

export const postOrderUpdate = (
  id,
  note,
  status,
  setModelExtradition,
  setLoading,
  FillData
) => {
  setLoading(true);
  note = note ?? "";
  userPost("api/admin/Order/OrderApproval", { id, note, status })
    .then((a) => {
      setLoading(false);
      setModelExtradition(a.data);
      FillData();
    })
    .catch((error) => {
      setLoading(false);
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      errorCodeControl(error);
    });
};

export const getByIdPrint = (id, setdata) => {
  userGetList("api/admin/Order/getByIdPrint", { id })
    .then((a) => {
      setdata(a.data);
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      errorCodeControl(error);
    });
};
