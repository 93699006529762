import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadSpin from "../../../../ui/loadSpin";
import { create } from "../wareHouseServices";

const New = ({ show, handleClose, filldata }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values, { setSubmitting }) => {
    const submitFillData = (a) => {
      setSubmitting(false);
      handleClose();
      filldata();
    };

    await create(
      { StorageName: values.name, Location: values.location },
      setIsLoading,
      submitFillData
    );
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Depo adı giriniz."),
    location: Yup.string().required("Konum giriniz."),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      location: "",
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <Modal
      size="xl"
      show={show}
      onHide={handleClose}
      aria-labelledby="example-modal-sizes-title-sm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-sm">
          Depo Oluştur
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          className="form-horizontal row"
          method="post"
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <div className="col-md-12 mb-3 text-start">
            <label>
              Depo Adı <span className="text-danger">*</span>{" "}
            </label>
            <input
              id="name"
              name="name"
              type="text"
              className={`form-control ${
                formik.touched.name && formik.errors.name ? "is-invalid" : ""
              }`}
              placeholder="Depo adı giriniz."
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="invalid-feedback">{formik.errors.name}</div>
            ) : null}
          </div>{" "}
          <div className="col-md-12 mb-3 text-start">
            <label>
              Konum <span className="text-danger">*</span>{" "}
            </label>
            <input
              id="location"
              name="location"
              type="text"
              className={`form-control ${
                formik.touched.location && formik.errors.location
                  ? "is-invalid"
                  : ""
              }`}
              placeholder="Konum giriniz."
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.location}
            />
            {formik.touched.location && formik.errors.location ? (
              <div className="invalid-feedback">{formik.errors.location}</div>
            ) : null}
          </div>
          <Modal.Footer>
            {isLoading ? (
              <div>
                <LoadSpin></LoadSpin>
              </div>
            ) : (
              <>
                <Button variant="secondary" type="button" onClick={handleClose}>
                  Kapat
                </Button>
                <Button variant="success" type="submit">
                  Oluştur
                </Button>
              </>
            )}
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default New;
