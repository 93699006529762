import Slider from "react-slick";
import { Logo } from "./logo";

const CardGalery = ({ item }) => {
  const imagesList = item.images;

  const settings = {
    customPaging: function (i) {
      return (
        <button type="button" id={`slick-slide-control0${i}`}>
          {" "}
        </button>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: imagesList.length > 1,
    speed: 500,
    slidesToShow: Math.min(1, imagesList.length),
    slidesToScroll: 1,
  };
  return (
    <>
      {" "}
      <div className="gallery-proc">
        <Slider {...settings}>
          {imagesList.length > 0 ? (
            imagesList.map((image) => (
              <>
                <div
                  className="products-img"
                  style={{
                    backgroundImage: `url(https://depo.ogmpictures.com/images/products/mini/${image.fileName})`,
                  }}
                >
                  {" "}
                  <img
                    src={"https://depo.ogmpictures.com/images/products/mini/" + image.fileName}
                    className="bg-img"
                    alt={image.title}
                  />
                </div>
              </>
            ))
          ) : (
            <div className="products-img">
              <Logo></Logo>
            </div>
          )}
        </Slider>
      </div>
    </>
  );
};
export default CardGalery;
