import { post } from "../../utils/axiosServices"; 

export const getEmailConfirm = (code, setData, navigate) => {
  const redirect = () => {
    navigate("/login");
  };

  post("api/Auth/PostEmailApprow", { code: code })
    .then((a) => {
      setData(true);
    })
    .catch((error) => {
      setData(false);
    });
};
