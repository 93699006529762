import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getCode, onSubmit } from "./projectSelectConfirmService";
import LoadSpin from "../../ui/loadSpin";
import useQuery from "../../utils/useQuery";
import { Helmet } from "react-helmet";
import SwalServices from "../../utils/swalServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const ProjectSelectConfirm = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const code = query.get("code");

  const [state, setState] = useState(true);
  const [data, setData] = useState();
  const [btnLoading, setbtnLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  useEffect(() => {
    getCode(code, setData, setState);
  }, []);

  const validationSchema = Yup.object({
    project: Yup.object().required("Proje seçiniz."),
    password: Yup.string()
      .min(6, "En az 6 karakter olmalı")
      .required("Şifrenizi giriniz."),
  });

  const HandleSubmit = (values) => {
    const success = () => {
      const redirect = () => {
        navigate("/login");
      };

      SwalServices.confirmSignle(
        "İşlem başerılı",
        "Talebiniz başarıyla oluşturuldu.",
        redirect
      );
    };

    const data = {
      code: code,
      password: values.password,
      project: values.project.value,
    };
    debugger;
    onSubmit(setbtnLoading, data, success);
  };

  const formik = useFormik({
    initialValues: {
      project: null,
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: HandleSubmit,
  });

  return (
    <>
      <Helmet>
        <title>OGM Pictures - Üye Proje Seçimi </title>
      </Helmet>
      <div className="text-center mt-2">
        <div className="p-3">
          <h4 className="font-size-18 mt-2 text-center">Üye Proje Seçimi</h4>
          <small className="login-sml-info">
            Devam etmek için proje seçiniz.
          </small>
          {state ? (
            <>
              {data ? (
                <>
                  {data.isThereDemand ? (
                    <div className="alert alert-success">
                      Proje kayıt başvurusu talebiniz onay beklemektedir.
                    </div>
                  ) : (
                    <form
                      className="form-horizontal row"
                      method="post"
                      autoComplete="off"
                      onSubmit={formik.handleSubmit}
                    >
                      <div className="alert alert-danger">
                        Kayıtlı olduğunuz proje için çalışma süreniz dolmuş veya
                        projeniz artık aktif değil.Devam edebilmek için yeniden
                        proje seçerek başvuru yapabilirsiniz.
                      </div>

                      <div className="col-md-12 mb-3 text-start" style={{ zIndex: 5 }}>
                        <label className="form-label" htmlFor="project">
                          Proje <span className="text-danger">*</span>
                        </label>
                        <Select
                          options={
                            data.project &&
                            data.project.map((item) => ({
                              value: item.id,
                              label: item.storageName,
                            }))
                          }
                          placeholder="Proje seçiniz..."
                          isClearable={true}
                          onChange={(selectedOption) => {
                            formik.setFieldValue("project", selectedOption);
                          }}
                          className={`react-select-container ${
                            formik.touched.project && formik.errors.project
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {formik.touched.project && formik.errors.project ? (
                          <div className="invalid-feedback d-block">
                            {formik.errors.project}
                          </div>
                        ) : null}
                      </div>

                      <div className="col-md-12 mb-3 text-start">
                        <label className="form-label" htmlFor="password">
                          Şifre <span className="text-danger">*</span>
                        </label>

                        <div className="input-group">
                          <input
                            type={showPassword ? "text" : "password"} // Şifreyi göster/gizle
                            className={`form-control ${
                              formik.touched.password && formik.errors.password
                                ? "is-invalid"
                                : ""
                            }`}
                            name="password"
                            placeholder="Şifrenizi giriniz."
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                          />
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={toggleShowPassword}
                          >
                            <FontAwesomeIcon
                              icon={showPassword ? faEyeSlash : faEye}
                            />
                          </button>
                        </div>

                        {formik.touched.password && formik.errors.password ? (
                          <div className="invalid-feedback">
                            {formik.errors.password}
                          </div>
                        ) : null}
                      </div>

                      {!btnLoading ? (
                        <div className="mb-3 row">
                          <div className="col-sm-12">
                            <button
                              className="btn btn-dark w-100 mt-2 waves-effect waves-light lgn-btn"
                              type="submit"
                            >
                              Talep Oluştur
                            </button>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="mb-3 row">
                            <div className="col-sm-12">
                              <button
                                disabled={true}
                                className="btn btn-dark w-100 mt-2 waves-effect waves-light lgn-btn"
                                type="submit"
                              >
                                Talep Oluştur...
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </form>
                  )}
                </>
              ) : (
                <div className="text-center">
                  <LoadSpin></LoadSpin>
                </div>
              )}
            </>
          ) : (
            <div className="alert alert-danger">Geçersiz İstek</div>
          )}
          <div className="mb-3 text-center footer-register-link">
            <div className="form-check">
              <span> Üye misiniz?</span> <Link to={"/login"}>Giriş Yapın</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectSelectConfirm;
