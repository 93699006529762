import React from "react";
import { Col, Container, Row } from "react-bootstrap";
// import LeftMenu from "./leftMenu";

const AdminContainer = ({ children }) => {
  return (
    <Container>
      <Row>
        {/* <Col md={3}>
          <LeftMenu></LeftMenu>
        </Col> */}
        <Col md={12}>{children}</Col>
      </Row>
    </Container>
  );
};

export default AdminContainer;
