import React, { useEffect, useState } from "react";
import { faClose, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, InputGroup } from "react-bootstrap";

const SearchButton = ({ handleSearch, placeholder, value }) => {
  const [searchText, setSearchText] = useState(value);

  useEffect(() => {
    setSearchText(value);
  }, [value]);

  const handleInputChange = (e) => {
    if (e && e.target && e.target.value) {
      setSearchText(e.target.value);
    } else {
      setSearchText("");
    }
  };
  const handleclear = () => {
    setSearchText("");
    handleSearch("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch(searchText);
  };
  return (
    <Form onSubmit={handleSubmit}>
      <InputGroup className="mb-3">
        <Form.Control
          placeholder={placeholder}
          aria-label={placeholder}
          aria-describedby="basic-addon2"
          value={searchText}
          onChange={handleInputChange}
        />
        <Button
          id="button-addon2"
          variant="light"
          type="button"
          className="border-bottom border-top"
          onClick={handleclear}
        >
          <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
        </Button>
        <Button variant="dark" id="button-addon2" type="submit">
          <FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon>
        </Button>
      </InputGroup>
    </Form>
  );
};

export default SearchButton;
