import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminLayout from "../layout/adminLayout/adminLayout";
import Dashboard from "../pages/admin/dashboard/dashboard";
import Products from "../pages/admin/products/products";
import Orders from "../pages/admin/orders/orders";
import ProductDetail from "../pages/admin/productdetail/productdetail";

import Brands from "../pages/admin/brands/brand";
import Categoryes from "../pages/admin/categoryes/categoryes";
import Qualityes from "../pages/admin/qualityes/qualityes";
import Storage from "../pages/admin/storages/storages";
import User from "../pages/admin/users/user";
import { BreadcrumbDashBoard } from "../utils/breadcrumbAdmin";
import Demand from "../pages/admin/demand/demand";
import Project from "../pages/admin/project/project";
import WareHouse from "../pages/admin/wareHouse/wareHouse";
import ProductNew from "../pages/admin/productdetail/productnew";
import Profil from "../pages/web/profil/profil";
import Extradition from "../pages/admin/extradition/extradition";
import ProfilUsers from "../pages/web/profilUsers/profilUsers";
import ProductsProccessPrint from "../pages/admin/products/ProductsProccessPrint";
import OrderDetailPrint from "../pages/admin/orders/orderDetailPrint";

const AdminRoute = () => {
  return (
    <Routes>
      <Route
        exact
        path="/ProductsProccessPrint/:id"
        element={<ProductsProccessPrint />}
      />{" "}
      <Route exact path="/OrderPrint/:id" element={<OrderDetailPrint />} />
      <Route element={<AdminLayout />}>
        <Route exact path="/" element={<Dashboard />} />
        <Route exact path="/pending" element={<Demand />} />
        <Route exact path="/Orders" element={<Orders />} />
        <Route exact path="/Products" element={<Products />} />
        <Route exact path="/ProductNew" element={<ProductDetail />} />
        <Route exact path="/ProductDetail/:id" element={<ProductDetail />} />

        <Route exact path="/Brands" element={<Brands />} />
        <Route exact path="/Extradition" element={<Extradition />} />
        <Route exact path="/Categoryes" element={<Categoryes />} />
        <Route exact path="/Qualityes" element={<Qualityes />} />
        <Route exact path="/Projects" element={<Project />} />
        <Route exact path="/Warehouses" element={<WareHouse />} />
        <Route exact path="/Users" element={<User />} />
        <Route exact path="/Profil" element={<Profil />} />
        <Route exact path="/Profil-users" element={<ProfilUsers />} />
      </Route>
    </Routes>
  );
};

export default AdminRoute;
