import React from "react";
import { post } from "../../utils/axiosServices";
import { userLogin } from "../../redux/redux";
import SwalServices from "../../utils/swalServices";

export const loginValue = {
  username: "",
  password: "",
  // rememberme: false,
};
export const onSubmit = async (
  e,
  formValue,
  setbtnLoading,
  handleLogin,
  navigate
) => {
  e.preventDefault();
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  if (formValue.username.length === 0 || formValue.password.length === 0) {
    SwalServices.warning("Uyarı", "E-posta veya şifre boş olamaz.");
    return false;
  } else if (!emailPattern.test(formValue.username)) {
    SwalServices.warning("Uyarı", "Geçerli bir e-posta adresi giriniz.");
    return false;
  } else if (formValue.password.length < 1) {
    SwalServices.warning("Uyarı", "Geçersiz şifre.");
    return false;
  }

  setbtnLoading(true);

  await post("/api/Auth/Login", formValue)
    .then((a) => {
      var token = a.data;
      handleLogin(token);
    })
    .catch(function (error) {
      debugger;
      if ( error.response && error.response.status === 401) {
        if (error.response.data.type === 0) {
          navigate("/registerconfirm?code=" + error.response.data.guids);
        } else if (error.response.data.type === 1) {
          navigate("/projectSelectConfirm?code=" + error.response.data.guids);
        } else {
          let err = error.response
            ? error.response.data
            : "Hata oluştu." + error.message;
          SwalServices.warning("Geçersiz Giriş", err);
          setbtnLoading(false);
        }
      } else {
        let err = error.response
          ? error.response.data
          : "Hata oluştu." + error.message;
        SwalServices.warning("Geçersiz Giriş", err);
        setbtnLoading(false);
      }
    });
};
