import { Logo } from "../../../ui/logo";

import {
  faCircleCheck,
  faCircleXmark,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { linkTranslate } from "../../../utils/utilitiesservices";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OrderStatus from "../../../utils/OrderStatus";
import { useState } from "react";
import LoadSpin from "../../../ui/loadSpin";
import { postOrderDetailUpdate } from "./ordersServices";
import SwalServices from "../../../utils/swalServices";

const OrderDetailItem = ({
  item,
  setModelExtradition,
  FillData,
  orderCount,
}) => {
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState("");

  const handleApprow = (e, state) => {
    e.preventDefault();

    if (!state && note.length < 3) {
      SwalServices.error(
        "Not girinizi",
        "Siparişi red edebilmek için not giriniz."
      );
      return;
    }

    const success = () => {
      setLoading(true);
      postOrderDetailUpdate(
        item.oderDetailId,
        note,
        state,
        setModelExtradition,
        setLoading,
        FillData
      );
    };

    SwalServices.confirm(
      "Sipariş detayı onayı",
      item.name +
        " siparişini " +
        (state ? "onaylamak" : "red etmek") +
        " istediğinize emin misiniz?",
      success
    );
  };

  return (
    <Row key={item.id} className="carditem m-3">
      <Col xs={12} sm={4} md={4}>
        <div className="cart-board  w-100 h-100">
          <div
            className={" carditemimage-bg "}
            style={
              item.image
                ? {
                    backgroundImage: `url(/images/products/mini/${item.image.fileName})`,
                  }
                : {}
            }
          >
            <Link
              to={"/productdetail" + linkTranslate(item.name) + "/" + item.id}
            >
              {item.image ? (
                <img
                  className="cardImg"
                  src={"/images/products/mini/" + item.image.fileName}
                  alt={item.name}
                />
              ) : (
                <Logo></Logo>
              )}
            </Link>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={8} md={8} className="py-3 card-prop">
        <div>
          <h4>{item.name}</h4>
        </div>
        <div>
          {" "}
          <strong>Ürün Kodu</strong>: {item.code}
        </div>
        <div>
          {" "}
          <strong>Bağlı Olduğu Depo</strong>: {item.storageName}
        </div>
        <div>
          {" "}
          <strong>Marka</strong>: {item.brandName}
        </div>
      </Col>
      <Col
        xs={12}
        sm={12}
        md={12}
        className="d-flex justify-content-center align-items-center mb-2"
      >
        {loading ? (
          <div className="text-center">
            <LoadSpin></LoadSpin>
          </div>
        ) : (
          <>
            {item.status === OrderStatus.Wait ? (
              <>
                {orderCount > 1 && (
                  <>
                    <div className="form-group m-1">
                      <input
                        className="form-control"
                        placeholder="Notunuz"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      ></input>
                    </div>
                    <Button
                      className="btn btn-danger m-1"
                      onClick={(e) => handleApprow(e, false)}
                    >
                      Red Et
                    </Button>
                    <Button
                      className="btn btn-success m-1"
                      onClick={(e) => handleApprow(e, true)}
                    >
                      Onayla
                    </Button>
                  </>
                )}
              </>
            ) : item.status === OrderStatus.Approval ? (
              <div>
                {item.description && (
                  <div className="alert alert-success"> {item.description}</div>
                )}
                {item.extradition === true ? (
                  <>
                    <div class="alert alert-success" role="alert">
                      <h4 class="alert-heading">İade Kabul Edildi</h4>{" "}
                      {item.ExtraditionDescription} <hr></hr>{" "}
                      {item.ExtraditionNote}
                    </div>
                  </>
                ) : item.extradition === false ? (
                  <>
                    <div class="alert alert-warning" role="alert">
                      <h4 class="alert-heading">İade Talebi Bekleniyor </h4>
                      {item.ExtraditionDescription} <hr></hr>{" "}
                      {item.ExtraditionNote}
                    </div>
                  </>
                ) : (
                  <>
                    <span className="text-success">
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        style={{ fontSize: "35px" }}
                      ></FontAwesomeIcon>
                    </span>
                  </>
                )}
              </div>
            ) : (
              <div>
                {" "}
                {item.description && (
                  <div className="alert alert-danger"> {item.description}</div>
                )}
                <span className="text-danger">
                  <FontAwesomeIcon icon={faCircleXmark} 
                        style={{ fontSize: "35px" }}></FontAwesomeIcon>
                </span>
              </div>
            )}
          </>
        )}
      </Col>
    </Row>
  );
};

export default OrderDetailItem;
