import React from "react";
import { errorCodeControl, userGetList, userPost } from "../../../utils/axiosServices";
import SwalServices from "../../../utils/swalServices";

export const getFavories = (setData) => {
  userGetList("api/Order/GetAllFavories")
    .then((a) => {
      setData(a.data);
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("Hata Oluştu", err);
      setData([]);
      errorCodeControl(error);
    });
};

export const favoriesRemoveService = (id, setData) => {
  userPost("api/Order/RemoveFavories", { rid: id })
    .then((a) => {
      setData(a.data);

      SwalServices.success(
        "İşlem başarılı",
        "Ürün favorilerinizden kaldırıldı"
      );
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("Hata Oluştu", err);
      errorCodeControl(error);
    });
};

export const favoriesAllRemove = (setData) => {
  userPost("api/Order/RemoveAllFavories", {})
    .then((a) => {
      setData();
      SwalServices.success("İşlem başarılı", "Tüm favoriler silindi.");
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("Hata Oluştu", err);
      errorCodeControl(error);
    });
};
