import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useQuery from "../../utils/useQuery";
import LoadSpin from "../../ui/loadSpin";
import { Button, Container } from "react-bootstrap";
import { getEmailConfirm } from "./registerEmailConfirmServices";

const RegisterEmailConfirm = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const code = query.get("code");

  const [data, setData] = useState(null);

  useEffect(() => {
    getEmailConfirm(code, setData, navigate, null);
  }, []);

  return (
    <Container>
      {data === null ? (
        <div className="text-center">
          <LoadSpin></LoadSpin>
        </div>
      ) : (
        <>
          {data === true ? (
            <div class="alert alert-success text-center" role="alert">
              <p>
                E-posta adresiniz doğrulandı.Üyelik kaydınız admin tarafından
                onaylandıktan sonra giriş yapabilecekseniz.{" "}
              </p>
              <p>Onay durumu e-posta ile iletilecektir.</p>
            </div>
          ) : (
            <div class="alert alert-danger">
              Geçersiz link <br />
              <p>Link geçersiz veya süresi dolmuş olabilir.</p>
            </div>
          )}
        </>
      )}

      <div className="text-center">
        <div className="mb-3  text-center footer-register-link">
          <div class="form-check">
            Üye Girişi <Link to={"/login"}>Giriş Yapın</Link>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default RegisterEmailConfirm;
