import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux"; 
import { onSubmit } from "./forgotPasswordServices";
import ForgotPasswordForm from "./forgotPasswordForm"; 


const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [btnLoading, setbtnLoading] = useState(false);
  const [data, setData] = useState(false);

  const handleSubmit = async (value) => {
    
    onSubmit(setData, setbtnLoading, value);
  };

  return (
    <>
      <Helmet>
        <title>OGM Pictures - Şifremi Unuttum </title>
      </Helmet>
      <div className="text-center mt-2">
        <div className="p-3">
          <h4 className="font-size-18   mt-2 text-center">Şifremi Unuttum</h4>

          {data && (
            <div class="alert alert-success">
             Kayıtlı böyle bir e-posta mevcut ise şifre yenileme linki e-posta adresinize iletilecektir.
            </div>
          )}

          {!data && (
            <div>
          
              <ForgotPasswordForm
                onSubmit={handleSubmit}
                btnLoading={btnLoading}
              ></ForgotPasswordForm>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
