import React from "react";
import { Link } from "react-router-dom";

export const BreadcrumbDashBoard = () => {
  return (
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <Link to="/" className="text-black">
            OGM Market
          </Link>
        </li>
        <li class="breadcrumb-item active">
          {" "}
          <span>Hoş Geldiniz</span>
        </li>
      </ol>
    </nav>
  );
};

export const BreadcrumbCategory = () => {
  return (
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <Link to="/">OGM Market</Link>
        </li>{" "}
        <li class="breadcrumb-item " aria-current="page">
          Tüm Ürünler
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Filtre
        </li>
      </ol>
    </nav>
  );
};

export const BreadcrumbProduct = ({ children }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <Link to="/">OGM Market</Link>
        </li>{" "}
        <li class="breadcrumb-item " aria-current="page">
          Tüm Ürünler
        </li>
        {children}
      </ol>
    </nav>
  );
};

export const BreadcrumbOrder = () => {
  return (
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <Link to="/">OGM Market</Link>
        </li>{" "}
        <li class="breadcrumb-item active" aria-current="page">
          Siparişlerim
        </li>
      </ol>
    </nav>
  );
};

export const BreadcrumbCard = () => {
  return (
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <Link to="/">OGM Market</Link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Sepetim
        </li>
      </ol>
    </nav>
  );
};

export const BreadcrumbFavories = () => {
  return (
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <Link to="/">OGM Market</Link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Favorilerim
        </li>
      </ol>
    </nav>
  );
};
