import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const LoginForm = ({
  handleChange,
  formValue,
  setbutton,
  onSubmit,
  handleLogin,
  navigate,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <form
      className="form-horizontal"
      method="post"
      autocomplete="off"
      onSubmit={(e) => onSubmit(e, formValue, setbutton, handleLogin, navigate)}
    >
      <div className="mb-3 text-start">
        <label className="form-label" for="username">
          E-posta Adresiniz <span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className="form-control "
          name="username"
          placeholder="E-posta adesinizi giriniz."
          value={formValue.username}
          onChange={handleChange}
        />
      </div>

      <div className="mb-3 text-start">
        <div className="d-flex justify-content-between">
          <label className="form-label" htmlFor="password">
            Şifreniz <span className="text-danger">*</span>
          </label>
        </div>

        <InputGroup>
          {" "}
          <Form.Control
            type={showPassword ? "text" : "password"}
            className="form-control"
            name="password"
            placeholder="Şifrenizi giriniz."
            value={formValue.password}
            onChange={handleChange}
          />
          <InputGroup.Text>
            <FontAwesomeIcon
              icon={showPassword ? faEyeSlash : faEye}
              onClick={togglePasswordVisibility}
              style={{ cursor: "pointer" }}
            />
          </InputGroup.Text>
        </InputGroup>
      </div>

      {/* <div className="mb-3  text-start">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="rememberme"
            name="rememberme"
            defaultChecked={formValue.rememberme}
            onChange={handleChange}
          />
          <label class="form-check-label" for="flexCheckChecked">
            Beni Hatırla
          </label>
        </div>
      </div> */}

      <div className="mb-3 row   ">
        <div className="col-sm-12 ">
          <button
            className="btn btn-dark w-100  mt-2 waves-effect waves-light lgn-btn"
            type="submit"
          >
            Giriş Yap
          </button>
        </div>{" "}
        <div className="text-end mt-3">
          {" "}
          <Link to={"/forgot-password"}>Şifrenizi mi unuttunuz?</Link>
        </div>
      </div>

      <div className="mb-3  text-center footer-register-link">
        <div class="form-check">
          <span>Hesabınız yok mu?</span> <Link to={"/register"}>Üye Olun</Link>
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
