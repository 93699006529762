const FormHandleChange = (event, formValue, setFormValue) => {
  setFormValue({
    ...formValue,
    [event.target.name]:
      event.target.value === "true"
        ? true
        : event.target.value === "false"
        ? false
        : event.target.value,
  });
};

function isValidTaxNumber(taxNumber) {
  
  // Vergi numarası 10 haneli bir sayı olmalı
  if (/^\d{10}$/.test(taxNumber) === false) {
    return false;
  }

  // const digits = taxNumber.split("").map(Number); // Sayıları diziye dönüştür

  // // İlk hane 0 olamaz
  // if (digits[0] === 0) {
  //   return false;
  // }

  return true;
  // Kontrol hanesi doğrulaması
  // const controlSum =
  //   (digits[0] + digits[2] + digits[4] + digits[6] + digits[8]) * 7 -
  //   (digits[1] + digits[3] + digits[5] + digits[7]);

  // const controlDigit = controlSum % 10;

  // return controlDigit === digits[9];
}

function isValidTCKNNumber(tcNumber) {
  
  // TC Kimlik Numarası 11 haneli bir sayı olmalı
  if (/^\d{11}$/.test(tcNumber) === false) {
    return false;
  }

  const digits = tcNumber.split("").map(Number); // Sayıları diziye dönüştür

  // İlk hane 0 olamaz
  if (digits[0] === 0) {
    return false;
  }

  // 1-9 haneleri topla
  const sum1 = digits[0] + digits[2] + digits[4] + digits[6] + digits[8];
  // 2, 4, 6, 8 haneleri topla
  const sum2 = digits[1] + digits[3] + digits[5] + digits[7];

  const controlDigit1 = (sum1 * 7 - sum2) % 10;
  const controlDigit2 = (sum1 + sum2 + digits[9]) % 10;

  return controlDigit1 === digits[9] && controlDigit2 === digits[10];
}

export default FormHandleChange;
export { isValidTCKNNumber, isValidTaxNumber };
