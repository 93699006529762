import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getDashboardCategory } from "./dashboardServices";
import { Logo } from "../../../ui/logo";
import { BreadcrumbDashBoard } from "../../../utils/breadcrumb";
import { linkTranslate } from "../../../utils/utilitiesservices";
import { Helmet } from "react-helmet";

const Dashboard = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    getDashboardCategory(setData);
  }, []);

  const CategoryItem = ({ item }) => {
    return (
      <Col xs={12} sm={4} md={6} lg={4}>
        <div
          style={
            item.image
              ? { backgroundImage: `url(/images/category/${item.image})` }
              : {}
          }
          className={
            "mw-100 w-100 categoryimage " +
            (item.image ? "catimage" : "grayimage")
          }
        >
          {item.image ? (
            <img src={"/images/category/" + item.image} />
          ) : (
            <Logo></Logo>
          )}

          <Link to={"/products" + linkTranslate(item.name) + "/" + item.id}>
            {" "}
            {item.name}
          </Link>
        </div>
      </Col>
    );
  };

  return (
    <Container>
      <Helmet title="Ogm Pictures"></Helmet>
      <BreadcrumbDashBoard />

      <Row className="justify-content-md-center">
        <Col xs={12} sm={12} md={12}>
          <div className={"mw-100 w-100 categoryimage  allProduct grayimage"}>
            <img src={"/images/allProduct.jpg"} />

            <Link to={"/products"} style={{ maxWidth: "350px" }}>
              Tüm Ürünler
            </Link>
          </div>
        </Col>

        {data &&
          data.map((item) => (
            <CategoryItem key={item.id} item={item}></CategoryItem>
          ))}
      </Row>
    </Container>
  );
};

export default Dashboard;
