import React from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const Breadcrumb = ({ children }) => {
  return (
    <Container fluid>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/" className="text-black">
              OGM Pictures
            </Link>
          </li>
          {children}
        </ol>
      </nav>
    </Container>
  );
};

export const BreadcrumbDashBoard = () => {
  return (
    <Breadcrumb>
      <li className="breadcrumb-item active">
        <span>Hoş Geldiniz</span>
      </li>
    </Breadcrumb>
  );
};

export const BreadcrumbBrand = () => {
  return (
    <Breadcrumb>
      <li class="breadcrumb-item active" aria-current="page">
        Markalar
      </li>
    </Breadcrumb>
  );
};
export const BreadcrumbOrder = () => {
  return (
    <Breadcrumb>
      <li class="breadcrumb-item active" aria-current="page">
        Siparişlerim
      </li>
    </Breadcrumb>
  );
};

export const BreadcrumbCategory = () => {
  return (
    <Breadcrumb>
      {" "}
      <li class="breadcrumb-item active" aria-current="page">
        Kategoriler
      </li>
    </Breadcrumb>
  );
};
export const BreadcrumbDemand = () => {
  return (
    <Breadcrumb>
      {" "}
      <li class="breadcrumb-item active" aria-current="page">
        Onay Bekleyen İstekler
      </li>
    </Breadcrumb>
  );
};
export const BreadcrumbQualityes = () => {
  return (
    <Breadcrumb>
      <li class="breadcrumb-item active" aria-current="page">
        Nitelikler
      </li>
    </Breadcrumb>
  );
};
export const BreadcrumbProduct = () => {
  return (
    <Breadcrumb>
      <li class="breadcrumb-item active" aria-current="page">
        Ürünler
      </li>
    </Breadcrumb>
  );
};

export const BreadcrumbUser = () => {
  return (
    <Breadcrumb>
      <li class="breadcrumb-item active" aria-current="page">
        Üyeler
      </li>
    </Breadcrumb>
  );
};

export const BreadcrumbProductDetail = ({ children }) => {
  return (
    <Breadcrumb>
      <li class="breadcrumb-item  " aria-current="page">
        <Link to="/products" className="text-black">
          Ürünler
        </Link>
      </li>
      {children}
    </Breadcrumb>
  );
};
export const BreadcrumbProject = () => {
  return (
    <Breadcrumb>
      <li class="breadcrumb-item active" aria-current="page">
        Projeler
      </li>
    </Breadcrumb>
  );
};
export const BreadcrumbWarehouse = () => {
  return (
    <Breadcrumb>
      <li class="breadcrumb-item active" aria-current="page">
        Depolar
      </li>
    </Breadcrumb>
  );
};

export const BreadcrumbProfil = () => {
  return (
    <Breadcrumb>
      <li class="breadcrumb-item active" aria-current="page">
        Profil
      </li>
    </Breadcrumb>
  );
};
