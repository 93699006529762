import React from "react";
import { Col, Row } from "react-bootstrap";
import { getFormatDatetime } from "../../../utils/utilitiesservices";

const UpdateInformation = ({ data }) => {
  const InformationItem = ({ title, value }) => {
    return (
      <>
        <Row className="m-3 d-flex justify-content-start">
          <Col style={{ maxWidth: "150px" }}>{title} </Col>{" "}
          <Col style={{ maxWidth: "10px" }}>:</Col>
          <Col>{value}</Col>{" "}
        </Row>
      </>
    );
  };

  return (
    <Col className=" border-end border-bottom border-start p-3">
      <h1>Kullanıcı Bilgileri </h1>
      <Row className="border rounded">
        <Col>
          <InformationItem title={"E-Posta"} value={data.email} />
          <InformationItem title={"Telefon"} value={data.phone} />
          {data.storageName && (
            <InformationItem
              title={"Çalıştığı Proje"}
              value={data.storageName}
            />
          )}
          <InformationItem
            title={"Oluşturma Tarihi"}
            value={getFormatDatetime(data.createDate)}
          />
        </Col>
      </Row>
    </Col>
  );
};

export default UpdateInformation;
