import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { onSubmit, getCode } from "./forgotPasswordConfirmServices";
import ForgotPasswordConfirmForm from "./forgotPasswordConfirmForm";
import useQuery from "../../utils/useQuery";
import LoadSpin from "../../ui/loadSpin";
import { Link } from "react-router-dom";

const ForgotPasswordConfirm = () => {
  const query = useQuery();
  const code = query.get("code");
  const [btnLoading, setbtnLoading] = useState(false);
  const [authState, setAuthState] = useState(null);
  const [data, setData] = useState(false);

  useEffect(() => {
    debugger;
    getCode(code, setAuthState);
  }, []);

  const handleSubmit = async (value) => {
    onSubmit(setData, setbtnLoading, value, code);
  };

  return (
    <>
      <Helmet>
        <title>OGM Pictures - Şifremi Güncelle </title>
      </Helmet>
      <div className="text-center mt-2">
        <div className="p-3">
          <h4 className="font-size-18   mt-2 text-center">Şifremi Güncelle</h4>
          {authState === true ? (
            <>
              {data && (
                <div class="alert alert-success">
                  Şifreniz güncellendi.Sisteme yeni şifreniz ile giriş
                  yapabilirsiniz.
                </div>
              )}

              {!data && (
                <div>
                  
                  <ForgotPasswordConfirmForm
                    onSubmit={handleSubmit}
                    btnLoading={btnLoading}
                  ></ForgotPasswordConfirmForm>
                </div>
              )}
            </>
          ) : authState === false ? (
            <div class="alert alert-danger">
              Geçersiz link <br />
              <p>Link geçersiz veya süresi dolmuş olabilir.</p>
            </div>
          ) : (
            <div className="text-center">
              <LoadSpin></LoadSpin>
            </div>
          )}

          <div className="mb-3 text-center footer-register-link">
            <div className="form-check">
              <span> Üye misiniz?</span> <Link to={"/login"}>Giriş Yapın</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordConfirm;
