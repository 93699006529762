import {
  errorCodeControl,
  userGetList,
  userPost,
} from "../../../utils/axiosServices";
import SwalServices from "../../../utils/swalServices";

export const getProducts = (setData, filter, setrowCount, setIsLoading) => {
  userPost("api/Product/GetAll", filter)
    .then((a) => {
      setData(a.data.list);
      setrowCount(a.data.rowsCount);
      setIsLoading(false);
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("Hata Oluştu", err);
      setData([]);
      setIsLoading(false);
      errorCodeControl(error);
    });
};

export const getFilter = (
  setData,
  filter,
  setFilter,
  setCategory,
  SubCategory
) => {
  userGetList("api/Product/GetMain")
    .then((a) => {
      setData(a.data);
      setCategory(a.data.categories);

      if (!filter.categories) {
        const processedCategories = {};

        a.data.categories.forEach((category, index) => {
          if (index > 0) {
            if (index === 1 && SubCategory) {
              try {
                const subId = parseInt(SubCategory);
                processedCategories["category-" + index] = [subId];
              } catch (error) {
                processedCategories["category-" + index] = [];
              }
            } else {
              processedCategories["category-" + index] = [];
            }
          }
        });

        setFilter({ ...filter, categories: processedCategories });
      } else {
        setFilter((prevFilter) => ({ ...prevFilter, ...filter }));
      }
    })
    .catch((error) => {
      debugger;
      setData([]);
      errorCodeControl(error);
    });
};
