import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import LoadSpin from "../../../ui/loadSpin";
import { getProccess } from "./productsServices";
import { getFormatDatetime } from "../../../utils/utilitiesservices";
import { Link } from "react-router-dom";

const ProductsProccess = ({ show, close, id, title }) => {
  const [data, setData] = useState();

  useEffect(() => {
    getProccess(id, setData);
  }, []);

  const handleClose = () => {
    setData();
    close();
  };

  return (
    <Modal
      size="xl"
      show={show}
      onHide={handleClose}
      aria-labelledby="example-modal-sizes-title-sm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-sm">
          {title} Ürün Hareketleri{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data ? (
          <div className="col-md-12 mb-3 text-start">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>İşlem Tarihi</th>
                  <th>İşlem</th>
                  <th>Detay</th>
                </tr>
              </thead>{" "}
              <tbody>
                {data &&
                  data.map((item, i) => (
                    <tr>
                      <td>{getFormatDatetime(item.date, item.date)}</td>
                      <td>{item.proccessName}</td>
                      <td>{item.detail}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        ) : (
          <LoadSpin></LoadSpin>
        )}

        <Modal.Footer>
          {" "}
          <Link
            to={"/ProductsProccessPrint/" + id}
            className="btn btn-dark"
            target="_blank"
          >
            Yazdır
          </Link>
          <Button variant="secondary" type="button" onClick={handleClose}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

export default ProductsProccess;
