import React from "react";
import { Route, Routes } from "react-router-dom";
import AuthLayout from "../layout/authLayout/authLayout";
import Login from "../pages/login/login";
import ForgotPassword from "../pages/forgotPassword/forgotPassword";
import Register from "../pages/register/register";
import DashboardRoute from "./dashboardRoute";
import RegisterConfirm from "../pages/RegisterConfirm/RegisterConfirm";
import RegisterEmailConfirm from "../pages/registerEmailConfirm/registerEmailConfirm";
import ForgotPasswordConfirm from "../pages/forgotPasswordConfirm/forgotPasswordConfirm";
import ProjectSelectConfirm from "../pages/projectSelectConfirm/projectSelectConfirm";

const ToRoute = () => {
  return (
    <>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route
            exact
            path="/forgotPasswordConfirm"
            element={<ForgotPasswordConfirm />}
          />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/registerConfirm" element={<RegisterConfirm />} />
          <Route
            exact
            path="/emailConfirm"
            element={<RegisterEmailConfirm />}
          />{" "}
          <Route
            exact
            path="/projectSelectConfirm"
            element={<ProjectSelectConfirm />}
          />
        </Route>

        <Route exact path="/" element={<DashboardRoute />} />
        <Route path="/*" element={<DashboardRoute />}></Route>
      </Routes>
    </>
  );
};

export default ToRoute;
