import React, { useEffect, useState } from "react";
import LoadSpin from "../../../ui/loadSpin";
import { getFormatDatetime } from "../../../utils/utilitiesservices";
import { Table } from "react-bootstrap";
import { getProccessPrint } from "./productsServices";
import { useParams } from "react-router-dom";

const ProductsProccessPrint = () => {
  const { id } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    

    getProccessPrint(id, setData);
  }, []);

  
  useEffect(() => {
    if (data) {
      print();
    }
  }, [data]);

  const print = () => {
    window.print();
    setTimeout(window.close, 0);
  };
  return (
    <div className="mt-5">
      {data ? (
        <div className="col-md-12 mb-3 text-start">
          <h3 className="mb-2"> {data.title} Ürün Hareketleri </h3>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>İşlem Tarihi</th>
                <th>İşlem</th>
                <th>Detay</th>
              </tr>
            </thead>{" "}
            <tbody>
              {data.list &&
                data.list.map((item, i) => (
                  <tr>
                    <td>{getFormatDatetime(item.date, item.date)}</td>
                    <td>{item.proccessName}</td>
                    <td>{item.detail}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <LoadSpin></LoadSpin>
      )}
    </div>
  );
};

export default ProductsProccessPrint;
