import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Badge, Button, Col, Row, Table } from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BreadcrumbUser } from "../../../utils/breadcrumbAdmin";
import AdminContainer from "../../../layout/adminLayout/Component/adminContainer";
import { getAll, removeById } from "./userServices";
import { getFormatDatetime } from "../../../utils/utilitiesservices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import SwalServices from "../../../utils/swalServices";
import Role from "../../../utils/Role";
import Pagination from "../../../utils/pagination";

import New from "./component/new";
import Update from "./component/update";
import SearchButton from "../../../ui/searchButton";
const User = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setdata] = useState();

  const [newItem, setNewItem] = useState();
  const [updateItem, setUpdateItem] = useState();
  const [rowCount, setrowCount] = useState(null);

  const [filter, setfilter] = useState();

  useEffect(() => {
    fillData();
  }, [location]);

  const fillData = () => {
    const newFilter = {
      page: searchParams.get("page") || 1,
      size: searchParams.get("size") || 20,
      filter: searchParams.get("filter") || "",
    };
    setfilter(newFilter);
    getAll(newFilter, setfilter, setrowCount, setdata);
  };

  const handleSearch = (text) => {
    searchParams.set("page", 1);
    searchParams.set("filter", text);

    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleRedirect = () => {
    searchParams.set("page", 1);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleRemove = (id, title) => {
    const removeAct = () => {
      removeById(id, setrowCount, setdata, handleRedirect);
    };

    SwalServices.confirm(
      "İçerik Sil",
      title + "  kullanıcısını silmek istediğinize emin misiniz?",
      removeAct
    );
  };

  const HandleClose = () => {
    setNewItem();
    setUpdateItem();
  };
  return (
    <>
      <Helmet title="Ogm Pictures - Kullanıcılar"></Helmet>{" "}
      {newItem && (
        <New show={newItem} handleClose={HandleClose} filldata={fillData} />
      )}
      {updateItem && (
        <Update
          show={updateItem}
          handleClose={HandleClose}
          filldata={fillData}
        />
      )}
      <AdminContainer>
        <BreadcrumbUser />
        <Row>
          <Col sm={6} className="col-6">
            <div style={{ maxWidth: "350px" }}>
              <SearchButton
                handleSearch={handleSearch}
                placeholder={"Kullanıcılarda Ara"}
                value={filter && filter.filter}
              />
            </div>
          </Col>
          <Col className="text-end mb-2 col-6" sm={6}>
            <Button variant="dark" onClick={() => setNewItem(true)}>
              <FontAwesomeIcon icon={faCirclePlus} /> Yeni Kullanıcı
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <div>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Adı Soyadı</th>
                  <th>Telefon</th>
                  <th>E-posta</th>
                  <th>Rol</th>
                  <th>Projesi</th>
                  <th className="text-center">Durum</th>
                  <th className="text-center">Kayıt Tarihi</th>
                  <th
                    style={{ width: "150px", minWidth: "150px" }}
                    className="text-center"
                  >
                    İşlemler
                  </th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  (data.length === 0 ? (
                    <tr>
                      <td colSpan={8}>
                        {" "}
                        <div className="text-center"> İçerik Bulunamadı.</div>
                      </td>
                    </tr>
                  ) : (
                    data.map((item, i) => (
                      <tr>
                        <td>{item.name}</td>
                        <td>{item.phone}</td>
                        <td>{item.email}</td>
                        <td>
                          {item.role === Role.Admin ? (
                            <span>Admin</span>
                          ) : item.role === Role.WareHouse ? (
                            <span>Depo Yöneticisi</span>
                          ) : (
                            <span>Üye</span>
                          )}
                        </td>
                        <td>{item.role === Role.Standard && item.project}</td>
                        <td className="text-center">
                          {item.status ? (
                            <Badge bg="success">Aktif</Badge>
                          ) : (
                            <Badge bg="danger">Pasif</Badge>
                          )}
                        </td>
                        <td className="text-center">
                          {getFormatDatetime(item.createDate)}
                        </td>
                        <td className="text-center">
                          <Button
                            className="m-1"
                            variant="success"
                            onClick={() => setUpdateItem(item.id)}
                          >
                            <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                          </Button>
                          <Button
                            className="m-1"
                            variant="danger"
                            onClick={() => handleRemove(item.id, item.name)}
                          >
                            <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                          </Button>
                        </td>
                      </tr>
                    ))
                  ))}
              </tbody>
            </Table>
          </div>
          <div className="d-flex justify-content-center">
            {rowCount > 0 && (
              <Pagination
                currentPage={parseInt(searchParams.get("page")) || 1}
                size={filter.size}
                rowCount={rowCount}
              ></Pagination>
            )}
          </div>
        </Row>
      </AdminContainer>
    </>
  );
};

export default User;
