import React from "react";
import { getList } from "../../utils/axiosServices";
import SwalServices from "../../utils/swalServices";

export const RegisterProjectList = (setProject) => {
  try {
    
    getList("api/Auth/GetProject").then((a) => {
      
      setProject(a.data);
    });
  } catch (error) {
    let err = error.response
      ? error.response.data
      : "Hata oluştu." + error.message;
    SwalServices.warning("Hata oluştu", err);
    setProject([]);
  }
};

export const onSubmit = (formValue, navigate) => {
  

  // if (formValue.length === 0 || formValue.sifre.length === 0) {
  //   // seterrorS(GetError("Kullanıcı adı veya şifre boş olamaz."));
  //   return false;
  // } else if (formValue.kullaniciAdi.length < 2) {
  //   // seterrorS(GetError("Kullanıcı adı veya şifre hatalı."));
  //   return false;
  // } else if (formValue.sifre.length < 1) {
  //   // seterrorS(GetError("Kullanıcı adı veya şifre hatalı."));
  //   return false;
  // }

  //   setbtnLoading(true);
  //   post("Kullanici/login", formValue)
  //     .then((a) => {
  //       var token = a.data;
  //       dispatch(userLogin(token)).then(() => {
  //         navigate("/index");
  //       });
  //     })
  //     .catch(function (error) {
  //       let err = GetError(
  //         error.response
  //           ? error.response.data.detail
  //           : "Hata oluştu." + error.message
  //       );
  //       seterrorS(err);
  //       setbtnLoading(false);
  //       return;
  //     });
};
