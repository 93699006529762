import React from "react";
import Swal from "sweetalert2";

const SwalServices = {
  success: (title, message) => {
    Swal.fire({
      title: title,
      text: message,
      icon: "success",

      confirmButtonColor: "#157347",
      confirmButtonText: "Tamam", // Buton metnini özelleştirme
    });
  },
  warning: (title, message) => {
    Swal.fire({
      title: title,
      text: message,
      icon: "warning",
      confirmButtonColor: "#157347",
      confirmButtonText: "Tamam", // Buton metnini özelleştirme
    });
  },
  error: (title, message) => {
    Swal.fire({
      title: title,
      text: message,
      icon: "error",
      confirmButtonColor: "#157347",
      confirmButtonText: "Tamam", // Buton metnini özelleştirme
    });
  },
  confirm: (title, message, confirmCallback, cancelCallback) => {
    Swal.fire({
      title: title,
      text: message,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#157347",
      confirmButtonText: "Onayla",
      cancelButtonText: "Kapat",
      allowOutsideClick: !cancelCallback,
      allowEscapeKey: !cancelCallback,
      allowEnterKey: !cancelCallback,
    }).then((result) => {
      if (result.isConfirmed) {
        if (confirmCallback) confirmCallback();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        if (cancelCallback) cancelCallback();
      }
    });
  },
  confirmSignle: (title, message, confirmCallback) => {
    Swal.fire({
      title: title,
      text: message,
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#157347",
      confirmButtonText: "Tamam",
    }).then((result) => {
      if (confirmCallback) confirmCallback();
    });
  },
};

// Swal.fire({
//     title: 'Are you sure?',
//     text: 'You will not be able to recover this imaginary file!',
//     icon: 'warning',
//     showCancelButton: true,
//     confirmButtonText: 'Yes, delete it!',
//     cancelButtonText: 'No, keep it'
//   }).then((result) => {
//     if (result.isConfirmed) {
//       Swal.fire('Deleted!', 'Your imaginary file has been deleted.', 'success');
//     } else if (result.dismiss === Swal.DismissReason.cancel) {
//       Swal.fire('Cancelled', 'Your imaginary file is safe :)', 'error');
//     }
//   });
// };

export default SwalServices;
