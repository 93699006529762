import React from "react";
import { getList, post } from "../../utils/axiosServices";
import SwalServices from "../../utils/swalServices";

export const getCode = (code, handleData, setState) => {
  debugger;
  getList("api/Auth/GetProjectCode", { code })
    .then((a) => {
      handleData(a.data);
    })
    .catch((error) => {
      setState(false);
    });
};

export const onSubmit = (setbtnLoading, value,success) => {
  setbtnLoading(true);
  debugger;
  post("/api/Auth/ProjectSelectConfirm", value)
    .then((a) => { 
        success()
    })
    .catch(function (error) {
      setbtnLoading(false);
      SwalServices.error(
        error.response ? error.response.data : "Hata oluştu." + error.message
      ); 
      return;
    });
};
