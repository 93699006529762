import React from "react";
import CardGalery from "../../../ui/CardGalery";
import { Card } from "react-bootstrap";
import { linkTranslate } from "../../../utils/utilitiesservices";
import { Link } from "react-router-dom";
import { Logo } from "../../../ui/logo";
import FavoriesButton from "../../../utils/favoriesButton";

const ProductCard = ({ item }) => {
  return (
    <Card className="property-box ">
      <Card.Body>
        <div
          className="property-box ogmpictures"
          itemtype="https://schema.org/Product"
        >
          <div className="property-image">
            <div class="labels-left">
              <div>
                <span
                  class={
                    "label label-shadow text-capitalize " +
                    (item.affiliatedProject ? " Close" : "")
                  }
                > 
                  {item.affiliatedProject
                    ? item.affiliatedProject
                    : item.storageName}{" "}
                </span>
              </div>
            </div>
            <Link
              to={"/productdetail" + linkTranslate(item.name) + "/" + item.id}
            >
              <div className="property-slider">
                {item.images && item.images.length > 0 ? (
                  <CardGalery item={item}></CardGalery>
                ) : (
                  <div className="gallery-proc slick-logo">
                    <div className="products-img ">
                      <Logo></Logo>
                    </div>
                  </div>
                )}
              </div>
            </Link>
          </div>{" "}  
          <div className="product-info mt-3">
            <div className="labels-left">
              <div className="d-flex justify-content-between">
                <span className="label label-category text-capitalize">{item.brandName} </span>
                <FavoriesButton
                  productId={item.id}
                  status={item.isFavorie}
                ></FavoriesButton>
              </div>
            </div>
            <div className="property-details">
              <Link
                to={"/productdetail" + linkTranslate(item.name) + "/" + item.id}
              >
                <div className="h5 products-title  text-capitalize">{item.name}</div>
              </Link>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ProductCard;
