import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import AdminRoute from "./adminRoute";
import WebRoute from "./webRoute";
import Role from "../utils/Role";
import { Caching } from "../utils/axiosServices";

const DashboardRoute = () => { 
  Caching() 
  const loggedIn = useSelector((state) => state.user);

  // return <WebRoute></WebRoute>;

  if (loggedIn) {
    if (loggedIn.role === Role.WareHouse || loggedIn.role === Role.Admin) {
      return <AdminRoute></AdminRoute>;
    } else {
      return <WebRoute></WebRoute>;
    }
  } else {
    return <Navigate to={"/login"} />;
  }
};

export default DashboardRoute;
