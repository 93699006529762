import React from "react";

export const getFormattedPrice = (price) => {
  var D = price.toFixed(2).substr(price.toFixed(2).indexOf("."), 3);
  try {
    return (
      <>
        {`${price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&.")}`
          .toString()
          .substr(
            0,
            `${price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&.")}`.toString()
              .length - 3
          )}{" "}
        <span className="miniPrice">{D}</span>
      </>
    );
  } catch (error) {
    return price;
  }
};

export function localLoginControl() {
  return localStorage.getItem("users") !== null ? true : false;
}

export function isNullOrEmty(ob) {
  if (!ob || ob == undefined || ob == null || ob == "") {
    return true;
  } else {
    return false;
  }
}
export const getFormatDatetime = (date, hour) => {
  try {
    if (!isNullOrEmty(date)) {
      const datee = new Date(date);
      return !hour
        ? datee.toLocaleDateString()
        : datee.toLocaleDateString() + " " + datee.toLocaleTimeString();
    } else {
      return "";
    }
  } catch (error) {
    return "Hatalı Tarih.";
  }
};

export function CheckEmailMask(email) {
  if (isNullOrEmty(email)) {
    return false;
  } else if (!/.+@.+\.[A-Za-z]+$/.test(email)) {
    return false;
  } else {
    return true;
  }
}

export function linkTranslate(url) {
  try {
    // URL'yi düzenleme işlemleri
    var replacedUrl = url
      .toLowerCase()
      .replace(/\s+/g, "-") // Boşlukları tireye çevirme // Belirli karakterleri kaldırma veya değiştirme
      .replace(/[şŞ]/g, "s")
      .replace(/[ıİ]/g, "i")
      .replace(/[ğĞ]/g, "g")
      .replace(/[çÇ]/g, "c")
      .replace(/[öÖ]/g, "o")
      .replace(/[üÜ]/g, "u")
      .replace(/[\/\\*+?<>,:;|.,()&'"ÇçÖöÜüĞğİıŞş]/g, "")
      .replace(/[-]{2,}/g, "-"); // Ardışık tireleri birleştirme

    return `/${replacedUrl}`;
  } catch (error) {
    // Hata durumunda varsayılan URL'i döndürme
    return "/ilan-detayi";
  }
}

//
