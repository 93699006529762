import { errorCodeControl, userGetList, userPost } from "../../../utils/axiosServices";
import SwalServices from "../../../utils/swalServices";

export const getById = (setData) => {
  try {
    userGetList("api/profil/GetById").then((a) => {
      
      console.log(a.data);
      setData(a.data);
    });
  } catch (error) {
    let err = error.response
      ? error.response.data
      : "Hata oluştu." + error.message;
    SwalServices.warning("Hata oluştu", err);
    errorCodeControl(error);
  }
};

export const create = (data, setbuttonOk, success) => {
  
  setbuttonOk(true);

  userPost("api/profil/updateProfil", data)
    .then((a) => {
      setbuttonOk(false);
      success();
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("Hata Oluştu", err);

      errorCodeControl(error);
      setbuttonOk(false);
    });
};
