import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import OrderStatus from "../../../utils/OrderStatus";
import { getFormatDatetime } from "../../../utils/utilitiesservices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Logo } from "../../../ui/logo";

const OrderPrint = ({ item, close }) => {
  return (
    <Modal
      size="xl"
      show={item}
      onHide={() => {
        close();
      }}
      aria-labelledby="example-modal-sizes-title-sm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-sm">
          Sipariş Yazdır
        </Modal.Title>
      </Modal.Header>
      <>
        {" "}
        <Modal.Body>
          <Row>
            {" "}
            <Col xs={12} sm={8} md={8} className="py-3 order-prop">
              <div>
                {" "}
                <span>Sipariş Numarası </span>:{item.siparisNo}
              </div>
              <div>
                {" "}
                <span>Sipariş Durumu</span>:
                {item.status === OrderStatus.Wait ? (
                  <label className="text-warning">Beklemede.</label>
                ) : item.status === OrderStatus.Approval ? (
                  <label className="text-success">Onaylandı.</label>
                ) : (
                  <label className="text-danger">Red Edildi.</label>
                )}
              </div>
              <div>
                {" "}
                <span>Notlar </span>: {item.note}
              </div>{" "}
              <div>
                {" "}
                <span>Sipariş Veren Kullanıcı </span>: {item.username}
              </div>
              <div>
                {" "}
                <span>Teslim Edilecek Proje</span>: {item.project}
              </div>
              <div>
                {" "}
                <span>Sipariş Tarihi </span>:{" "}
                {getFormatDatetime(item.createDate, item.createDate)}
              </div>
              {item.status !== OrderStatus.Wait && (
                <>
                  {" "}
                  <div>
                    {" "}
                    <span>Onaylayan Kullanıcı </span>: {item.approwUsername}
                  </div>{" "}
                  <div>
                    {" "}
                    <span>Onaylayan Kullanıcı Notu </span>: {item.Description}
                  </div>
                </>
              )}
            </Col>
            {item &&
              item.orderList &&
              item.orderList.map((detailItem) => (
                <Row
                  key={detailItem.id}
                  className="carditem m-3 --"
                  style={{
                    width: " 90%",
                    margin: "auto",
                  }}
                >
                  <Col xs={12} sm={4} md={4}>
                    <div className="cart-board  ">
                      <div
                        className={" carditemimage-bg "}
                        style={
                          detailItem.image
                            ? {
                                backgroundImage: `url(/images/products/mini/${detailItem.image.fileName})`,
                              }
                            : {}
                        }
                      >
                        {detailItem.image ? (
                          <img
                            className="cardImg"
                            src={
                              "/images/products/mini/" +
                              detailItem.image.fileName
                            }
                            alt={detailItem.name}
                          />
                        ) : (
                          <Logo></Logo>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={8} md={8} className="py-3 card-prop">
                    <div>
                      <h6>{detailItem.name}</h6>
                    </div>
                    <div>
                      {" "}
                      <strong>Ürün Kodu</strong>: {detailItem.code}
                    </div>
                    <div>
                      {" "}
                      <strong>Bağlı Olduğu Depo</strong>:{" "}
                      {detailItem.storageName}
                    </div>
                    <div>
                      {" "}
                      <strong>Marka</strong>: {detailItem.brandName}
                    </div>{" "}
                    <>
                      {detailItem.status === OrderStatus.Approval ? (
                        <div>
                          {detailItem.description && (
                            <div className="alert alert-success">
                              {" "}
                              {detailItem.description}
                            </div>
                          )}
                          {detailItem.extradition === true ? (
                            <>
                              <div class="alert alert-success" role="alert">
                                <h4 class="alert-heading">İade Kabul Edildi</h4>{" "}
                                {detailItem.ExtraditionDescription} <hr></hr>{" "}
                                {detailItem.ExtraditionNote}
                              </div>
                            </>
                          ) : detailItem.extradition === false ? (
                            <>
                              <div class="alert alert-warning" role="alert">
                                <h4 class="alert-heading">
                                  İade Talebi Bekleniyor{" "}
                                </h4>
                                {detailItem.ExtraditionDescription} <hr></hr>{" "}
                                {detailItem.ExtraditionNote}
                              </div>
                            </>
                          ) : (
                            <>
                              <span className="text-success">
                                Sipariş Onaylandı
                              </span>
                            </>
                          )}
                        </div>
                      ) : detailItem.status === OrderStatus.Reject ? (
                        <div>
                          {" "}
                          {detailItem.description && (
                            <div className="alert alert-danger">
                              {" "}
                              {detailItem.description}
                            </div>
                          )}
                          <span className="text-danger">
                            Sipariş Red Edildi
                          </span>
                        </div>
                      ) : (
                        <span></span>
                      )}
                    </>
                  </Col>
                </Row>
              ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {" "}
          <Link
            to={"/OrderPrint/" + item.id}
            className="btn btn-dark"
            target="_blank"
          >
             Yazdır
          </Link>
          <Button variant="secondary" type="button" onClick={close}>
            Kapat
          </Button>
        </Modal.Footer>
      </>
    </Modal>
  );
};

export default OrderPrint;
