import React from "react";
import { post } from "../../utils/axiosServices";
import SwalServices from "../../utils/swalServices";

export const onSubmit = (setData, setbtnLoading, value) => {
  setbtnLoading(true);
  post("/api/Auth/ForgotPassport", value)
    .then((a) => {
      setbtnLoading(false);
      setData(true);
    })
    .catch(function (error) {
      
      setbtnLoading(false);
      SwalServices.error(
        error.response
          ? error.response.data
          : "Hata oluştu." + error.message
      );

      return;
    });
};
