import { Col, Row } from "react-bootstrap";
import { useState } from "react";

import SwalServices from "../../../utils/swalServices";

const DemandUser = ({ item }) => {
  return (
    <Row key={item.id} className="carditem m-4">
      <Col xs={12} sm={12} md={12} className="py-3 card-prop">
        <Row>
          <div>
            <h3>Kullanıcı Adı : {item.name}</h3>
          </div>
          <div>
            <strong>Telefon</strong>: {item.phone}
          </div>
          <div>
            <strong>E-posta</strong>: {item.email}
          </div>
          <div>
            <strong>Proje</strong>: {item.storageName}
          </div>
 
        </Row>
      </Col>
    </Row>
  );
};

export default DemandUser;
