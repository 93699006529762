import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { BreadcrumbDemand } from "../../../utils/breadcrumbAdmin";
import AdminContainer from "../../../layout/adminLayout/Component/adminContainer";
import { Badge, Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  getAll,
  getOrderDetail,
  getProductDetail,
  getProjectDetail,
  getUserDetail,
  PostOrderDetail,
} from "./demandServices";
import SwalServices from "../../../utils/swalServices";
import { getFormatDatetime } from "../../../utils/utilitiesservices";
import Pagination from "../../../utils/pagination";
import LoadSpin from "../../../ui/loadSpin";
import OrderDetailItem from "./demandOrderItem";
import DemandUser from "./demandUser";
import DemandProductScrap from "./DemandProductScrap";
import { demandTypeName } from "../dashboard/dashboardServices";
import DemandProject from "./demandProject";

const demandType = { OrderDetailItem: 1 };

const Demand = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setdata] = useState();

  const [rowCount, setrowCount] = useState(null);
  const [filter, setfilter] = useState({
    page: searchParams.get("page") || 1,
    size: searchParams.get("size") || 20,
    view: searchParams.get("view") || "all",
  });

  const [modelDemand, setModelDemand] = useState();
  const [demandNote, setDemandNote] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    FillData();
  }, [location]);

  const FillData = () => {
    const newFilter = {
      page: searchParams.get("page") || 1,
      size: searchParams.get("size") || 20,
      view: searchParams.get("view") || "all",
    };
    setfilter(newFilter);
    getAll(newFilter, setfilter, setrowCount, setdata);
  };

  const getById = (item) => {
    if (item.orderDetailId) {
      const success = (value) => {
        setModelDemand({ ...item, detail: value });
      };
      getOrderDetail(item.orderDetailId, success);
    } else if (item.userId) {
      const success = (value) => {
        setModelDemand({ ...item, detail: value });
      };
      getUserDetail(item.userId, success);
    } else if (item.projectId) {
      const success = (value) => {
        setModelDemand({ ...item, detail: value });
      };
      debugger;
      getProjectDetail(item.id, success);
    } else if (item.scarpProductId) {
      const success = (value) => {
        setModelDemand({ ...item, detail: value });
      };
      getProductDetail(item.scarpProductId, success);
    } else {
      SwalServices.warning("Geçersiz İşlem");
    }
  };

  const HandleModalClose = () => {
    setModelDemand();
    setDemandNote("");
    setIsLoading(false);
  };

  const handleDemand = (state) => {
    if (!state && demandNote.length < 3) {
      SwalServices.error(
        "Not girinizi",
        "Talebi red edebilmek için not giriniz."
      );
      return;
    }
    PostOrderDetail(
      modelDemand.id,
      demandNote,
      state,
      setIsLoading,
      HandleModalClose,
      FillData
    );
    // PostOrderDetail
  };

  const handleSelect = (e) => {
    debugger;
    searchParams.set("view", e.target.value);

    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  return (
    <>
      <Helmet title="Ogm Pictures - Markalar"></Helmet>
      <AdminContainer>
        <BreadcrumbDemand />

        <Row className="form-group mb-3">
          <div style={{ maxWidth: "350px" }}>
            <Form.Select
              aria-label="all"
              value={filter.view}
              onChange={handleSelect}
            >
              <option value="all">Tümü</option>
              <option value="wait">Beklemede</option>
              <option value="approval">Onaylanmış</option>
              <option value="rejection">Red Edilmiş</option>
            </Form.Select>
          </div>
        </Row>

        <Row className="justify-content-md-center">
          <div>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Talep Türü</th>
                  <th>Talep Eden</th>
                  <th className="text-center">Detay</th>
                  <th className="text-center">Onay Durumu</th>
                  <th>Onaylayan </th>
                  <th style={{ width: "150px" }} className="text-center">
                    İşlem Tarihi
                  </th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  (data.length === 0 ? (
                    <tr>
                      <td colSpan={6}>
                        {" "}
                        <div className="text-center"> İçerik Bulunamadı.</div>
                      </td>
                    </tr>
                  ) : (
                    data.map((item, i) => (
                      <tr>
                        <td>{demandTypeName(item)}</td>
                        <td>{item.demandUser} </td>
                        <td className="text-center">
                          <Button
                            size="sm"
                            className="p-1"
                            onClick={() => getById(item)}
                          >
                            Görüntüle
                          </Button>
                        </td>{" "}
                        <td className="text-center">
                          {item.isState === true ? (
                            <Badge bg="success">Onaylandı</Badge>
                          ) : item.isState === false ? (
                            <Badge bg="danger">Red Edildi</Badge>
                          ) : (
                            <Badge bg="warning">Beklemede</Badge>
                          )}
                        </td>
                        <td>{item.approvedUser} </td>
                        <td className="text-center">
                          {getFormatDatetime(item.createDate)}
                        </td>
                      </tr>
                    ))
                  ))}
              </tbody>
            </Table>
          </div>
          <div className="d-flex justify-content-center">
            {rowCount > 0 && (
              <Pagination
                currentPage={parseInt(searchParams.get("page")) || 1}
                size={filter.size}
                rowCount={rowCount}
              ></Pagination>
            )}
          </div>{" "}
        </Row>

        {modelDemand && (
          <Modal
            size="xl"
            show={modelDemand}
            onHide={() => {
              HandleModalClose();
            }}
            aria-labelledby="example-modal-sizes-title-sm"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                {" "}
                {modelDemand.name} Talep Onayı{" "}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {modelDemand.orderDetailId && (
                <div>
                  <OrderDetailItem
                    item={modelDemand.detail}
                    description={modelDemand.description}
                  ></OrderDetailItem>
                </div>
              )}

              {modelDemand.userId && (
                <div>
                  <DemandUser
                    item={modelDemand.detail}
                    description={modelDemand.description}
                  ></DemandUser>
                </div>
              )}
              {modelDemand.projectId && (
                <div>
                  <DemandProject
                    item={modelDemand.detail}
                    description={modelDemand.description}
                  ></DemandProject>
                </div>
              )}
              {modelDemand.scarpProductId && (
                <div>
                  <DemandProductScrap
                    item={modelDemand.detail}
                    description={modelDemand.description}
                  ></DemandProductScrap>
                </div>
              )}

              {modelDemand.description && (
                <div>
                  <strong>Onay Açıklaması </strong>: {modelDemand.description}
                </div>
              )}

              {modelDemand.isState !== true &&
                modelDemand.isState !== false && (
                  <div>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        name="order-note"
                        value={demandNote}
                        onChange={(e) => setDemandNote(e.target.value)}
                        placeholder="Notunuz"
                      />
                    </div>
                  </div>
                )}
            </Modal.Body>
            <Modal.Footer>
              {isLoading ? (
                <div>
                  <LoadSpin></LoadSpin>
                </div>
              ) : (
                <>
                  {modelDemand.isState === true ? (
                    <div className="text-success">Onaylandı</div>
                  ) : modelDemand.isState === false ? (
                    <div className="text-danger">Red Edildi</div>
                  ) : (
                    <>
                      <Button
                        variant="secondary"
                        onClick={() => HandleModalClose()}
                      >
                        Kapat
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => handleDemand(false)}
                      >
                        Red Et
                      </Button>
                      <Button
                        variant="success"
                        onClick={() => handleDemand(true)}
                      >
                        Onayla
                      </Button>
                    </>
                  )}
                </>
              )}
            </Modal.Footer>
          </Modal>
        )}
      </AdminContainer>
    </>
  );
};

export default Demand;
