import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieCharts = ({ title, data }) => {
  const options1 = {
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <>
      <h4>{title}</h4>
      <div style={{ width: "100%", height: "400px" }}>
        {data && <Pie data={data} options={options1} />}
      </div>
    </>
  );
};

export default PieCharts;
