import React from "react";
import { removeAllCart, removeFromCart } from "../../../redux/redux";
import SwalServices from "../../../utils/swalServices";
import { userPost } from "../../../utils/axiosServices";

export const removeCart = (id, dispatch) => {
  try {
    dispatch(removeFromCart(id)).then(() => {
      SwalServices.success("İşlem yapıldı");
    });
  } catch (error) {
    
    SwalServices.warning("İşlem başarızı", "Sepet silinemedi.");
  }
};

export const createOrder = (cart, note, setbuttonOk, dispatch) => {
  
  setbuttonOk(true);
  const ProductIds = cart.map((item) => {
    return item.id;
  });
  userPost("api/Order/CreateOrder", { ProductIds: ProductIds, note })
    .then((a) => {
      dispatch(removeAllCart()).then(() => {
        SwalServices.success(
          "İşlem başarılı",
          "Siparişiniz oluşturuldu.Siparişlerim sayfasından kontrol edebilirsiniz."
        );
      });

      setbuttonOk(false);
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "İşlem başarısız" + error.message;
      SwalServices.warning("İşlem başarısız", err);

      setbuttonOk(false);
    });
};
