import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { linkTranslate } from "../../../utils/utilitiesservices";
import { Logo } from "../../../ui/logo";

const FavorieItem = ({ item, favoriesRemove, addCart, cart }) => {
  const isProductInCart = cart
    ? cart.some((cartitem) => parseInt(cartitem.id) === parseInt(item.id))
    : false;

  return (
    <Row key={item.id} className="carditem position-relative m-1">
      <Link
        to={"#"}
        className="trash-button w-auto"
        onClick={() => favoriesRemove(item.id)}
      >
        <FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon>
      </Link>
      <Col xs={12} sm={12} md={4}>
        <div className="cart-board  w-100 h-100">
          <div
            className={" carditemimage-bg "}
            style={
              item.image
                ? {
                    backgroundImage: `url(/images/products/mini/${item.image.fileName})`,
                  }
                : {}
            }
          >
            <Link
              to={"/productdetail" + linkTranslate(item.name) + "/" + item.id}
            >
              {item.image ? (
                <img
                  className="cardImg"
                  src={"/images/products/mini/" + item.image.fileName}
                  alt={item.name}
                />
              ) : (
                <Logo></Logo>
              )}
            </Link>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={12} md={8} className="py-3 card-prop">
        <div>
          <h4>{item.name}</h4>
        </div>
        <div>
          {" "}
          <strong>Ürün Kodu</strong>: {item.code}
        </div>
        <div>
          {" "}
          <strong>Bağlı Olduğu Depo</strong>: {item.storageName}
        </div>
        <div>
          {" "}
          <strong>Marka</strong>: {item.brandName}
        </div>{" "}
        <div className="text-center">
          {isProductInCart ? (
            <Button className=" m-1 px-5 py-2" variant="dark" disabled={true}>
              Sepete Mevcut
            </Button>
          ) : (
            <Button
              className=" m-1 px-5 py-2"
              variant="dark"
              onClick={() => addCart(item)}
            >
              Sepete Ekle
            </Button>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default FavorieItem;
