import React, { useState } from "react";
import { useSelector } from "react-redux";
import Role from "../../../utils/Role";
import { Badge, Nav, NavDropdown, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const LeftMenu = ({ counted }) => {
  const loggedIn = useSelector((state) => state.user);
  if (loggedIn.role === Role.Admin) {
    return (
      <>
        <Nav.Link to="/orders" as={Link} className="position-relative">
          Siparişler
          {counted && (
            <>
              {counted.orderCount > 0 ? (
                <Badge
                  bg="danger"
                  style={{ top: "10px", right: "10px", fontSize: "10px" }}
                >
                  {counted.orderCount}
                </Badge>
              ) : (
                ""
              )}
            </>
          )}
        </Nav.Link>

        <Nav.Link to="/products" as={Link}>
          Ürünler
        </Nav.Link>

        <Nav.Link to="/pending" as={Link} className="position-relative">
          Onay Bekleyen İşlemler{" "}
          {counted && (
            <>
              {counted.demandCount > 0 ? (
                <Badge
                  bg="danger"
                  style={{ top: "10px", right: "10px", fontSize: "10px" }}
                >
                  {counted.demandCount}
                </Badge>
              ) : (
                ""
              )}
            </>
          )}
        </Nav.Link>

        <NavDropdown
          onClick={(e) => e.preventDefault()}
          title={"Tanımlar"}
          className="sub-cat mx-2"
          id={"basic-nav-dropdown-"}
        >
          <NavDropdown.Item
            as={Link}
            to={"/Brands"}
            className=" sub-categories"
          >
            Markalar
          </NavDropdown.Item>
          <NavDropdown.Item
            as={Link}
            to={"/Categoryes"}
            className=" sub-categories"
          >
            Kategoriler
          </NavDropdown.Item>

          <NavDropdown.Item
            as={Link}
            to={"/Qualityes"}
            className=" sub-categories"
          >
            Nitelikler
          </NavDropdown.Item>

          <NavDropdown.Item
            as={Link}
            to={"/Extradition"}
            className=" sub-categories"
          >
            İade Tipleri
          </NavDropdown.Item>
          <NavDropdown.Item
            className=" sub-categories"
            to="/warehouses"
            as={Link}
          >
            Depolar
          </NavDropdown.Item>
          <NavDropdown.Item
            className=" sub-categories"
            to="/projects"
            as={Link}
          >
            Projeler
          </NavDropdown.Item>
        </NavDropdown>

        <Nav.Link to="/users" as={Link}>
          Kullanıcılar
        </Nav.Link>
      </>
    );
  } else if (loggedIn.role === Role.WareHouse) {
    return (
      <>
        <Nav.Link to="/orders" as={Link} className="position-relative">
          Siparişler
          {counted && (
            <>
              {counted.orderCount > 0 ? (
                <Badge
                  bg="danger"
                  style={{ top: "10px", right: "10px", fontSize: "10px" }}
                >
                  {counted.orderCount}
                </Badge>
              ) : (
                ""
              )}
            </>
          )}
        </Nav.Link>

        <Nav.Link to="/products" as={Link}>
          Ürünler
        </Nav.Link>

        <Nav.Link to="/pending" as={Link} className="position-relative">
          Onay Bekleyen İşlemler
          {counted && (
            <>
              {counted.demandCount > 0 ? (
                <Badge
                  bg="danger"
                  style={{ top: "10px", right: "10px", fontSize: "10px" }}
                >
                  {counted.demandCount}
                </Badge>
              ) : (
                ""
              )}
            </>
          )}
        </Nav.Link>
      </>
    );
  }
};

export default LeftMenu;
