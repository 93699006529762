import { errorCodeControl, userGetList, userPost } from "../../../utils/axiosServices";
import SwalServices from "../../../utils/swalServices";

export const getAll = (filter, setfilter, setrowCount, setData) => {
  userGetList("api/admin/ProjectStorage/GetAllWarehouse", filter)
    .then((a) => {
      setData(a.data.list);
      setrowCount(a.data.rowsCount);
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      setData([]);
      errorCodeControl(error);
    });
};

export const getById = (id, setData) => {
  userGetList("api/admin/ProjectStorage/GetById", { id })
    .then((a) => {
      setData(a.data);
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      errorCodeControl(error);
    });
};
export const create = (data, setIsloading, fillDataAndClose) => {
  setIsloading(true);
  userPost("api/admin/ProjectStorage/CreateWarehouse", data)
    .then((a) => {
      setIsloading(false);
      fillDataAndClose(a.data);
      SwalServices.success("İşlem Başarılı");
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      setIsloading(false);
      errorCodeControl(error);
    });
};

export const update = (data, setIsloading, fillDataAndClose) => {
  setIsloading(true);
  userPost("api/admin/ProjectStorage/Update", data)
    .then((a) => {
      setIsloading(false);
      fillDataAndClose();
      SwalServices.success("İşlem Başarılı");
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      setIsloading(false);
      errorCodeControl(error);
    });
};

export const removeById = (id, setrowCount, setData, redirect) => {
  userPost("api/admin/ProjectStorage/Delete", { id: id })
    .then((a) => {
      setData(a.data.list);
      setrowCount(a.data.rowsCount);
      SwalServices.success("İşlem Başarılı");
      redirect();
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      setData([]);
      errorCodeControl(error);
    });
};
