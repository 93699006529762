import {
  errorCodeControl,
  userGetList,
  userPost,
} from "../../../utils/axiosServices";
import SwalServices from "../../../utils/swalServices";

export const getOrderAll = (filter, setrowCount, setData) => {
  userGetList("api/Order/GetAllMyOrder", filter)
    .then((a) => {
      setData(a.data.list);
      setrowCount(a.data.rowsCount);
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      setData([]);
      errorCodeControl(error);
    });
};

export const GetExtraditions = (setData) => {
  userGetList("api/Order/GetExtraditions")
    .then((a) => {
      setData(a.data);
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      setData([]);
      errorCodeControl(error);
    });
};
export const postOrderExtradition = (
  id,
  note,
  extradition,
  success,
  isLoading,
  FillData
) => {
  debugger;
  isLoading(true);
  userPost("api/Order/PostOrderExtradition", { id, note, extradition })
    .then((a) => {
      success();
      FillData();
      isLoading(false);
      SwalServices.success("İşlem başarılı.", "İade talebi oluşturuldu.");
    })
    .catch((error) => {
      isLoading(false);
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      errorCodeControl(error);
    });
};

export const postOrderAllExtradition = (
  id,
  note,
  extradition,
  success,
  isLoading,
  FillData
) => {
  debugger;
  isLoading(true);
  userPost("api/Order/PostOrderAllExtradition", { id, note, extradition })
    .then((a) => {
      success();
      FillData();
      isLoading(false);
      SwalServices.success("İşlem başarılı.", "İade talebi oluşturuldu.");
    })
    .catch((error) => {
      isLoading(false);
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      errorCodeControl(error);
    });
};
