import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { post } from "../../utils/axiosServices";
import SwalServices from "../../utils/swalServices";
import RegisterForm from "./registerForm";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [btnLoading, setbtnLoading] = useState(false);

  const onSubmit = (values) => {
    
    setbtnLoading(true);
    const data = {
      name: values.name,
      surname: values.surname,
      email: values.email,
      phone: values.phone,
      Project: values.project?.value,
      password: values.password,
      passwordR: values.passwordR,
    };

    post("/api/Auth/Register", data)
      .then((a) => {
        const redirect = () => {
          
          navigate("/");
        };
        SwalServices.confirmSignle(
          "İşlem Başarılı",
          "Üyelik talebinizin alındı.Üyelik işlemine devamı edebilmek için e-posta onayınız bekleniyor.Lütfen e-postanızın gelen kutusunu kontrol ediniz.",
          redirect 
        );
        setbtnLoading(false);
      })
      .catch(function (error) {
        
        let err = error.response
          ? error.response.data
          : "Hata oluştu." + error.message;
        SwalServices.warning("İşlem başarısız.", err);
        setbtnLoading(false);
        return;
      });
  };

  return (
    <>
      <Helmet>
        <title>OGM Pictures - Üyelik Oluştur </title>
      </Helmet>
      <div className="text-center mt-2">
        <div className="p-3">
          <h4 className="font-size-18 mt-2 text-center">Üyelik Oluştur</h4>
          <small className="login-sml-info">
            Devam etmek için lütfen aşağıdaki bilgileri doldurunuz.
          </small>
          <RegisterForm onSubmit={onSubmit} btnLoading={btnLoading} />
        </div>
      </div>
    </>
  );
};

export default Register;
