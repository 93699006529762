import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Logo } from "../../../ui/logo";
import ProductGalery from "./productGalery";
import { BreadcrumbProduct } from "../../../utils/breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus, faCircle } from "@fortawesome/free-solid-svg-icons";
import { addCartProduct, getProductDetail } from "./productDetailServices";
import { Link, useLocation, useParams } from "react-router-dom";
import SwalServices from "../../../utils/swalServices";
import FavoriesButton from "../../../utils/favoriesButton";
import ProductCard from "../products/ProductCard";
import { getFormatDatetime } from "../../../utils/utilitiesservices";

const ProductDetail = () => {


  const location = useLocation();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  let { id } = useParams();

  const isProductInCart = cart.some((item) => item.id === parseInt(id));

  const [data, setData] = useState(null);
  const [modelShow, setModelShow] = useState(false);

  useEffect(() => {
    getProductDetail(id, setData);
    window.scrollTo(0, 0);
  }, [location]);

  const addCart = () => {
    addCartProduct(data, dispatch);
  };
  return (
    <Container className="mb-5">
      {data && (
        <>
          <Helmet title={"OGM Pictures - "+data.name}></Helmet>
          <Row className="mb-2">
            <BreadcrumbProduct>
              {" "}
              <li class="breadcrumb-item" aria-current="page">
                {data.categoryName}
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {data.name}
              </li>
            </BreadcrumbProduct>
          </Row>

          <div className="single-property-section  d-md-none ">
            <div className="container">
              <div className="single-title">
                <div className="left-single">
                  <div className="d-flex justify-content-between">
                    <span>
                      <span
                        className={
                          "label label-shadow ms-2" +
                          (data.affiliatedProject ? " Close" : "")
                        }
                      >
                        {" "}
                        {data.affiliatedProject
                          ? data.affiliatedProject
                          : data.storageName}{" "}
                      </span>
                    </span>{" "}
                  </div>
                  <h1 className="mb-0">{data.name} </h1>

                  <div>
                    <h4 class="label label-category ">{data.categoryName} </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Row>
            <Col md={6} className="property-section mb-3">
              <div className="desc-box property-box">
                <div
                  className="page-section ratio3_2 p-0 property-image "
                  id="details"
                >
                  <div className="single-gallery property-slider">
                    {data.images && data.images.length > 0 ? (
                      <Row>
                        <ProductGalery images={data.images}></ProductGalery>
                      </Row>
                    ) : (
                      <div className="gallery-for">
                        <div>
                          <div className="products-img text-center">
                            <Logo></Logo>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Col>

            <Col md={6} className="property-section">
              <div itemtype="https://schema.org/Product">
                {data && (
                  <>
                    <div className="single-property-section  d-none d-md-block   d-lg-block  ">
                      <div className="container">
                        <div className="single-title">
                          <div className="left-single">
                            <div className="d-flex justify-content-between">
                              <span>
                                <span
                                  className={
                                    "label label-shadow ms-2" +
                                    (data.affiliatedProject ? " Close" : "")
                                  }
                                >
                                  {" "}
                                  {data.affiliatedProject
                                    ? data.affiliatedProject
                                    : data.storageName}{" "}
                                </span>
                              </span>{" "}
                            </div>
                            <h1 className="mb-0">{data.name} </h1>

                            <div>
                              <h4 class="label label-category ">
                                {data.categoryName}{" "}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="container">
                      <div className="row ratio_55">
                        <div className="description-section">
                          <div className="description-details">
                            <div className="desc-box">
                              <div className="row property-section">
                                <div
                                  className="about page-section page-properties p-0 mt-3"
                                  id="aciklama"
                                >
                                  <h3>Ürün Bilgileri</h3>
                                  <div className="row w-100">
                                    <div className="col-xl-12 col-md-12 col-sm-12  col-ilan">
                                      <div className="row   ilan-d-table">
                                        <div className="col-md-12 d-flex m-2">
                                          <div className="w150">
                                            {" "}
                                            <strong> Ürün Kodu :</strong>{" "}
                                          </div>
                                          <div> {data.code}</div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xl-12 col-md-12 col-sm-12  col-ilan">
                                      <div className="row   ilan-d-table">
                                        <div className="col-md-12 d-flex m-2">
                                          <div className="w150">
                                            {" "}
                                            <strong> Marka :</strong>{" "}
                                          </div>
                                          <div> {data.brandName}</div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-xl-12 col-md-12 col-sm-12  col-ilan">
                                      <div className="row   ilan-d-table">
                                        <div className="col-md-12 d-flex m-2">
                                          <div className="w150">
                                            <strong>Bağlı Olduğu Depo :</strong>{" "}
                                          </div>
                                          <div> {data.storageName}</div>
                                        </div>
                                      </div>
                                    </div>

                                    {data.quality &&
                                      data.quality.map((a) => (
                                        <div className="col-xl-12 col-md-12 col-sm-12  col-ilan">
                                          <div className="row   ilan-d-table">
                                            <div className="col-md-12 d-flex m-2">
                                              <div className="w150">
                                                {" "}
                                                <strong>
                                                  {" "}
                                                  {a.qualityContent} :
                                                </strong>{" "}
                                              </div>
                                              <div> {a.content}</div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}

                                    <div className="col-xl-12 col-md-12 col-sm-12  col-ilan">
                                      <div className="row   ilan-d-table">
                                        <div className="col-md-12 d-flex m-2">
                                          <div className="w150">
                                            <strong>Önceki Projeler :</strong>{" "}
                                          </div>
                                          <div>
                                            {" "}
                                            <Link
                                              className="btn btn-sm btn-dark p-1 ms-2"
                                              to={"#"}
                                              onClick={() =>
                                                data.transfer &&
                                                data.transfer.length > 0
                                                  ? setModelShow(true)
                                                  : SwalServices.warning(
                                                      "Önceki proje hareketi bulunmuyor."
                                                    )
                                              }
                                            >
                                              Önceki Projeler
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="mt-5  d-flex justify-content-end align-items-center">
                                  {" "}
                                  {data.affiliatedProject ? (
                                    <div className="alert  alert-warning">
                                      Aktif Projede Bulunuyor{" "}
                                    </div>
                                  ) : (
                                    <button
                                      disabled={isProductInCart}
                                      className="btn btn-lg btn-success"
                                      onClick={() => addCart()}
                                    >
                                      {isProductInCart ? (
                                        <>Sepete Eklendi ... </>
                                      ) : (
                                        <>
                                          {" "}
                                          Sepete Ekle{" "}
                                          <FontAwesomeIcon
                                            icon={faCartPlus}
                                          ></FontAwesomeIcon>
                                        </>
                                      )}
                                    </button>
                                  )}
                                  <div className="cart-favories ms-3">
                                    <FavoriesButton
                                      productId={data.id}
                                      status={data.isFavorie}
                                    ></FavoriesButton>
                                  </div>
                                </div>

                                {data.description &&
                                  data.description.trim().length > 0 && (
                                    <Row>
                                      <Col>
                                        <div className="page-section" id="desc">
                                          <h4>Ürün Açıklaması</h4>
                                          <div className="row">
                                            <div className="col-sm-12">
                                              {data.description}
                                            </div>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Col>
          </Row>

          {data.productList && data.productList.length > 0 && (
            <Col md={12} className="property-section mt-5">
              <h3>Diğer Ürünler</h3>
              <hr />
              <Row className="ogmpictures">
                {data.productList.map((product) => (
                  <Col key={product.id} sm={12} md={6} lg={4} className="mb-4">
                    <ProductCard item={product}></ProductCard>
                  </Col>
                ))}
              </Row>
            </Col>
          )}

          <Modal
            size="md"
            show={modelShow}
            onHide={() => {
              setModelShow(false);
            }}
            aria-labelledby="example-modal-sizes-title-sm"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                Önceki Projeleri
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {data.transfer &&
                data.transfer.map((a) => (
                  <div className="SweetAlertSpan border p-3 m-1 d-flex align-items-center">
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="me-3 previousprojectsdot"
                    ></FontAwesomeIcon>
                    {a}
                  </div>
                ))}
            </Modal.Body>
            <Modal.Footer>
              <>
                <Button variant="secondary" onClick={() => setModelShow()}>
                  Kapat
                </Button>
              </>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </Container>
  );
};

export default ProductDetail;
