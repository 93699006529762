import React, { useState, useCallback, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import FilterComponent from "./FilterComponent";
import ProductCard from "./ProductCard";
import LoadSpin from "../../../ui/loadSpin";
import Pagination from "../../../utils/pagination";
import { getFilter, getProducts } from "./productsServices";
import { BreadcrumbCategory } from "../../../utils/breadcrumb";
import { Helmet } from "react-helmet";
import SearchButtton from "../../../ui/searchButton";

const Products = () => {
  let { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [rowCount, setrowCount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [category, setCategory] = useState([]);
  const [filterData, setFilterData] = useState();

  const [filter, setFilter] = useState({
    page: searchParams.get("page") || 1,
    size: searchParams.get("size") || 15,
    searchText: searchParams.get("searchText") || "",
    view: "all",
    brands: [],
    mainCategory: searchParams.get("mainCategory") || null,
    projects: [],
    warehouse: [],
  });

  const [isFilterVisible, setIsFilterVisible] = useState(false);

  useEffect(() => {
    const subCategory = searchParams.get("subcategory");
    let newFilter = {
      page: searchParams.get("page") || 1,
      size: searchParams.get("size") || 15,
      view: searchParams.get("view") || "all",
      brands: searchParams.get("brands")
        ? searchParams
            .get("brands")
            .split(",")
            .map((value) => parseInt(value, 10))
            .filter(Number.isFinite)
        : [],
      mainCategory: searchParams.get("mainCategory")
        ? parseInt(searchParams.get("mainCategory"))
        : id
        ? parseInt(id)
        : null,
      projects: searchParams.get("projects")
        ? searchParams
            .get("projects")
            .split(",")
            .map((value) => parseInt(value, 10))
            .filter(Number.isFinite)
        : [],
      warehouse: searchParams.get("warehouse")
        ? searchParams
            .get("warehouse")
            .split(",")
            .map((value) => parseInt(value, 10))
            .filter(Number.isFinite)
        : [],
      searchText: searchParams.get("searchText") || "",
      categories: null,
    };
    const newCategories = searchParams.get("categories");
    if (newCategories) {
      newFilter.categories = JSON.parse(newCategories);
    } 
    getFilter(setFilterData, newFilter, setFilter, setCategory, subCategory);
  }, []);

  useEffect(() => {
    let newFilter = {
      page: searchParams.get("page") || 1,
      size: searchParams.get("size") || 15,
      view: searchParams.get("view") || "all",
      brands: searchParams.get("brands")
        ? searchParams
            .get("brands")
            .split(",")
            .map((value) => parseInt(value, 10))
            .filter(Number.isFinite)
        : [],
      mainCategory: searchParams.get("mainCategory")
        ? parseInt(searchParams.get("mainCategory"))
        : id
        ? parseInt(id)
        : null,
      projects: searchParams.get("projects")
        ? searchParams
            .get("projects")
            .split(",")
            .map((value) => parseInt(value, 10))
            .filter(Number.isFinite)
        : [],
      warehouse: searchParams.get("warehouse")
        ? searchParams
            .get("warehouse")
            .split(",")
            .map((value) => parseInt(value, 10))
            .filter(Number.isFinite)
        : [],
      searchText: searchParams.get("searchText") || "",
      categories: null,
    };
    const newCategories = searchParams.get("categories");
    if (newCategories) {
      newFilter.categories = JSON.parse(newCategories);
    } else {
      newFilter.categories = filter.categories;
    } 
    setFilter((prevFilter) => ({
      ...prevFilter,
      ...newFilter,
    }));
  }, [location]);

  useEffect(() => {
    if (category.length > 0) {
      setIsLoading(true);
      debugger;
      getProducts(setData, filter, setrowCount, setIsLoading);
    }
  }, [filter, filter.page]);

  useEffect(() => {
    if (category.length > 0) {
      const newCategories = [...category];
      const filterOpt = filter;

      if (filterOpt.mainCategory) {
        if (newCategories[1]) {
          newCategories[1] = newCategories[1].filter(
            (a) => a.parentId === filterOpt.mainCategory
          );
        }
      }

      const newList = [];
      for (let index = 0; index < newCategories.length; index++) {
        let item = newCategories[index];

        if (index > 1) {
          const previousItem = newList[index - 1];
          const validParentIds = new Set(
            previousItem.map((categoryitem) => categoryitem.id)
          );
          item = item.filter((categoryitem) =>
            validParentIds.has(categoryitem.parentId)
          );

          if (filterOpt.categories["category-" + (index - 1)].length > 0) {
            const list = filterOpt.categories["category-" + (index - 1)];
            item = item.filter((categoryitem) =>
              list.includes(categoryitem.parentId)
            );
          }

          if (item == null) {
            filterOpt.categories["category-" + index] = [];
          }

          if (filterOpt.categories["category-" + index].length > 0) {
            const currentCategoryIds = new Set(
              item.map((categoryitem) => categoryitem.id)
            );
            filter.categories["category-" + index] = filterOpt.categories[
              "category-" + index
            ].filter((id) => currentCategoryIds.has(id));
          }
        }

        newList.push(item);
      }
      filterOpt.page = parseInt(searchParams.get("page")) || 1;
      setFilter(filterOpt);
      setFilterData({ ...filterData, categories: newList });
    }
  }, [filter.mainCategory, filter.categories]);

  const handleCheckboxChange = useCallback((newFilter, is) => {
    const updatedSearchParams = new URLSearchParams(searchParams);

    updatedSearchParams.set("page", 1);

    if (filter.view !== newFilter.view) {
      updatedSearchParams.set("view", newFilter.view);
    } else if (!newFilter.view) {
      updatedSearchParams.delete("view");
    }

    if (filter.brands !== newFilter.brands) {
      updatedSearchParams.set("brands", newFilter.brands);
    } else if (!newFilter.brands) {
      updatedSearchParams.delete("brands");
    }

    if (filter.mainCategory !== newFilter.mainCategory) {
      updatedSearchParams.set("mainCategory", newFilter.mainCategory);
    } else if (!newFilter.mainCategory) {
      updatedSearchParams.delete("mainCategory");
    }

    if (filter.projects !== newFilter.projects) {
      updatedSearchParams.set("projects", newFilter.projects);
    } else if (!newFilter.projects) {
      updatedSearchParams.delete("projects");
    }

    if (filter.warehouse !== newFilter.warehouse) {
      updatedSearchParams.set("warehouse", newFilter.warehouse);
    } else if (!newFilter.warehouse) {
      updatedSearchParams.delete("warehouse");
    }

    if (
      newFilter.categories &&
      newFilter.categories["category-1"] &&
      newFilter.categories["category-1"].length > 0
    ) {
      updatedSearchParams.set(
        "categories",
        JSON.stringify(newFilter.categories)
      );
    } else {
      updatedSearchParams.delete("categories");
    }

    const newUri = `${location.pathname}?${updatedSearchParams.toString()}`;
    navigate(newUri);
  });

  const handleSearch = (value) => {
    searchParams.set("searchText", value);
    searchParams.set("page", 1);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };
  return (
    <>
      <Helmet title="OGM Pictures - Ürünler"></Helmet>
      {filterData ? (
        <Container className="mb-3">
          <Row>
            <BreadcrumbCategory />
          </Row>
          <Row>
            <Col md={12} lg={3} className="text-capitalize">
              {/* Mobilde filtreyi açmak/kapatmak için buton */}
              <Button
                className="d-md-none mb-3"
                onClick={() => setIsFilterVisible(!isFilterVisible)}
              >
                Filtreyi {isFilterVisible ? "Kapat" : "Aç"}
              </Button>

              <div
                className={`${
                  isFilterVisible ? "d-block" : "d-none"
                } d-md-block`}
              >
                {" "}
                <SearchButtton
                  handleSearch={handleSearch}
                  placeholder="Ürün Adı"
                  value={searchParams.get("searchText") || ""}
                />
                <FilterComponent
                  isadmin={false}
                  data={filterData}
                  filter={filter}
                  handleChangeFilter={handleCheckboxChange}
                  navigate={navigate}
                />
              </div>
            </Col>

            <Col lg={9} md={12} className="property-section">
              <div>
                {filter.mainCategory && category && category.length > 0 ? (
                  <h4>
                    {category[0].find((cat) => cat.id === filter.mainCategory)
                      ?.name || "Tüm Ürünler"}
                  </h4>
                ) : (
                  <h4>Tüm Ürünler</h4>
                )}
              </div>
              {!isLoading ? (
                <>
                  {data && data.length > 0 ? (
                    <Row className="ogmpictures">
                      {data.map((product) => (
                        <Col
                          key={product.id}
                          sm={12}
                          md={6}
                          lg={4}
                          className="mb-4"
                        >
                          <ProductCard item={product} />
                        </Col>
                      ))}
                      <div className="d-flex justify-content-center">
                        {rowCount > 0 && (
                          <Pagination
                            currentPage={
                              parseInt(searchParams.get("page")) || 1
                            }
                            size={filter.size}
                            rowCount={rowCount}
                          />
                        )}
                      </div>
                    </Row>
                  ) : (
                    <Row className="ogmpictures">
                      <Col className="text-center">
                        <h2>Uygun ürün bulunamadı</h2>
                      </Col>
                    </Row>
                  )}
                </>
              ) : (
                <LoadSpin />
              )}
            </Col>
          </Row>
        </Container>
      ) : (
        <Container>
          <Row>
            <Col className="text-center">
              <LoadSpin />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Products;

// "start": "react-scripts start",
// "build": "react-scripts build",
// "test": "react-scripts test",
// "eject": "react-scripts eject"
