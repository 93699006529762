import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";

import SwalServices from "../../../utils/swalServices";
import {
  getFavories,
  favoriesRemoveService,
  favoriesAllRemove,
} from "./favoriesservices";
import { BreadcrumbFavories } from "../../../utils/breadcrumb";
import {
  addCartProductArray,
  addCartProduct,
} from "../productDetail/productDetailServices";
import { useDispatch, useSelector } from "react-redux";
import FavorieItem from "./favorieItem";
import { Helmet } from "react-helmet";

const Favories = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const [data, setData] = useState();
  useEffect(() => {
    getFavories(setData);
  }, []);

  const confirmCallback = () => {
    addCartProductArray(data, dispatch);
  };

  const cartConfirm = () => {
    SwalServices.confirm(
      "Sepet Onayı",
      "Tüm içerik sepete eklensin mi?",
      confirmCallback
    );
  };

  const addCart = async (product) => {
    const removefavories = async () => {
      addCartProduct(product, dispatch);
    };

    SwalServices.confirm(
      "Sepete Ekle",
      "Sepeti onayla ve siparişi oluştur",
      removefavories
    );
  };

  const favoriesRemove = async (id) => {
    const removefavories = async () => {
      favoriesRemoveService(id, setData);
    };

    SwalServices.confirm(
      "Favorilerden Kaldır",
      "Favorilerden kaldırmak istediğinizden emin misiniz?",
      removefavories
    );
  };

  const cartRemoveConfirm = () => {
    const allRemove = () => {
      favoriesAllRemove(setData);
    };

    SwalServices.confirm("Tümünü sil", "Tüm favoriler silinsin mi?", allRemove);
  };

  return (
    <Container className="m-250"> 
    <Helmet>
        <title>OGM Pictures - Favorilerim </title>
      </Helmet>   
      <Row className="d-flex justify-content-between">
        <Col xs={6} sm={6} md={6}>
          <BreadcrumbFavories></BreadcrumbFavories>
        </Col>
        <Col xs={6} sm={6} md={6} className="text-end">
          <Link className="mx-2 text-black text-underline " to={"/cart"}>
            Sepetim
          </Link>
          <Link className="mx-2 text-black text-underline " to={"/orders"}>
            Siparişlerim
          </Link>
        </Col>
      </Row>
      {data && data.length > 0 ? (
        <div className="card-container ">
          {data.map((item) => (
            <FavorieItem
              item={item}
              favoriesRemove={favoriesRemove}
              cart={cart}
              addCart={addCart}
            ></FavorieItem>
          ))}
          <Row className="mb-50 mt-5">
            <Col md={12} className="text-end">
              <Button
                className="m-1 px-5 py-2 "
                variant="danger"
                onClick={() => cartRemoveConfirm()}
              >
                Hepsini Sil
              </Button>

              <Button
                className=" m-1 px-5 py-2"
                variant="dark"
                onClick={() => cartConfirm()}
              >
                Hepsini Sepete Ekle
              </Button>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="card-container-emty text-center d-flex justify-content-center  align-items-center flex-column">
          <div className="card-icon">
            <FontAwesomeIcon icon={faShoppingCart} />
          </div>
          <h3>Favorileriniz Henüz Boş</h3>
          <div>
            Favorilerinizde ürün bulunamadı."Ürünler" butonuna tıklayarak
            ürünleri favorilere ekleyebilirsiniz.
          </div>
          <Link to={"/products"} className="btn btn-dark mt-4  px-5 py-2">
            Ürünler
          </Link>
        </div>
      )}
    </Container>
  );
};
export default Favories;
