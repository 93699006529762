import React, { useEffect, useState } from "react";
import { Badge, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import AdminContainer from "../../../layout/adminLayout/Component/adminContainer";
import { BreadcrumbDashBoard } from "../../../utils/breadcrumbAdmin";
import { demandTypeName, GetDashboard } from "./dashboardServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBox,
  faCircleCheck,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { getFormatDatetime } from "../../../utils/utilitiesservices";
import PieCharts from "./pieCart";

const Dashboard = () => {
  const [cardCount, setCardCount] = useState();
  const [demand, setDemand] = useState();
  const [pieProduct, setPieProduct] = useState();
  const [pieProject, setPieProject] = useState();

  useEffect(() => {
    GetDashboard(fillData);
  }, []);

  const fillData = (data) => {
    setCardCount(data.count);
    setDemand(data.demands);
    setPieProduct(pieProduct);

    if (data.pieProduct) {
      setPieProduct({
        labels: [
          "Toplam Hurda Ürün",
          "Toplam Kullanılan Ürün",
          "Toplam Boştaki Ürünler",
        ],
        datasets: [
          {
            label: "Adet",
            data: [
              data.pieProduct.productScarpCount,
              data.pieProduct.productActiveCount,
              data.pieProduct.producWaitCount,
            ],
            backgroundColor: ["#36A2EB", "#09c827", "#FFCE56"],
            hoverBackgroundColor: ["#36A2EB", "#09c827", "#FFCE56"],
          },
        ],
      });
    }

    if (data.pieProjects) {
      debugger;
      const labels = data.pieProjects.map(
        (item) => item.storageName + "(" + item.count + ")"
      );
      const counts = data.pieProjects.map((item) => item.count);
      const colors = data.pieProjects.map((_, index) => {
        const colors = [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ];
        return colors[index % colors.length];
      });

      setPieProject({
        labels,
        datasets: [
          {
            label: "Proje Ürün Kullanımı",
            data: counts,
            backgroundColor: colors,
            hoverBackgroundColor: colors,
          },
        ],
      });
    }
  };
  const CountBox = ({ to, title, icon, count }) => {
    return (
      <Col class="p-4">
        <div className="shadow p-3 mb-5 bg-white rounded justify-content-md-start align-items-center d-flex ">
          <Link to={to} className="text-white">
            <div className="p-2 m-3 border rounded  dashboardIcon">
              {" "}
              <FontAwesomeIcon
                icon={icon}
                style={{ fontSize: "30px" }}
              ></FontAwesomeIcon>{" "}
            </div>{" "}
          </Link>
          <div class="d-flex flex-column align-items-start justify-content-center">
            <span>{title}</span>
            <h4>{count}</h4>
          </div>
        </div>
      </Col>
    );
  };
  return (
    <>
      <AdminContainer>
        {" "}
        <BreadcrumbDashBoard></BreadcrumbDashBoard>{" "}
        <Helmet title="Ogm Pictures"></Helmet>
        <div className="mb-5">
          {cardCount && (
            <Row className="justify-content-md-center d-flex ">
              <CountBox
                to={"/products"}
                title={"Toplam Ürün"}
                icon={faBox}
                count={cardCount.productCount}
              ></CountBox>{" "}
              <CountBox
                to={"/orders"}
                title={"Toplam Bekleyen Sipariş"}
                icon={faShoppingCart}
                count={cardCount.orderCount}
              ></CountBox>{" "}
              <CountBox
                to={"/pending"}
                title={"Toplam Bekleyen Onay"}
                icon={faCircleCheck}
                count={cardCount.demandCount}
              ></CountBox>
            </Row>
          )}
          <Row>
            <Col sm={12} md={8}>
              <div className="p-2 my-1 border rounded">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 p-3">
                      {" "}
                      <PieCharts
                        title={"Ürün Dağılımı"}
                        data={pieProduct}
                      ></PieCharts>{" "}
                    </div>{" "}
                    <div className="col-md-6 p-3">
                      {" "}
                      <PieCharts
                        title={"Depo Dağılımı"}
                        data={pieProject}
                      ></PieCharts>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} md={4}>
              <div className="py-3  my-1 border rounded">
                <div className="d-flex justify-content-between px-2">
                  <h5>Onay Bekleyen İşler</h5>{" "}
                  <Link
                    to="/pending"
                    className="text-black"
                    style={{ fontSize: "13px" }}
                  >
                    Tümünü Gör {">"}
                  </Link>{" "}
                </div>
                <div
                  className="text-muted mb-4 px-2"
                  style={{ fontSize: "13px" }}
                >
                  Üzerinizde onay bekleyen işler listelenmiştir.
                </div>

                {demand && demand.length > 0 ? (
                  <>
                    {" "}
                    {demand.map((item) => (
                      <div className="d-flex justify-content-between p-2 border-top ">
                        <Badge bg="warning">Beklemede</Badge>

                        <div className="fw-bold">{demandTypeName(item)}</div>

                        <div className="text-center">
                          {getFormatDatetime(item.createDate)}
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="text-center">Bekleyen onay bulunmuyor.</div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </AdminContainer>
    </>
  );
};

export default Dashboard;
