import {
  errorCodeControl,
  userGetList,
  userPost,
} from "../../../utils/axiosServices";
import SwalServices from "../../../utils/swalServices";

export const getAll = (filter, setfilter, setrowCount, setData) => {
  userGetList("api/admin/demand/GetAll", filter)
    .then((a) => {
      setData(a.data.list);
      setrowCount(a.data.rowsCount);
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      setData([]);
      errorCodeControl(error);
    });
};

export const getOrderDetail = (id, success) => {
  userGetList("api/admin/demand/getOrderDetail", { id })
    .then((a) => {
      success(a.data);
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      errorCodeControl(error);
    });
};

export const getProductDetail = (id, success) => {
  userGetList("api/admin/demand/GetProductDetail", { id })
    .then((a) => {
      success(a.data);
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      errorCodeControl(error);
    });
};
export const getUserDetail = (id, success) => {
  userGetList("api/admin/demand/GetUserDetail", { id })
    .then((a) => {
      success(a.data);
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      errorCodeControl(error);
    });
};

export const getProjectDetail = (id, success) => {
  userGetList("api/admin/demand/GetProjectDetail", { id })
    .then((a) => {
      success(a.data);
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      errorCodeControl(error);
    });
};
export const PostOrderDetail = (
  id,
  note,
  state,
  setIsLoading,
  HandleModalClose,
  fillData
) => {
  debugger;
  setIsLoading(true);
  userPost("api/admin/demand/PostOrderDetail", { id, note, status: state })
    .then((a) => {
      setIsLoading(false);
      fillData();
      HandleModalClose();
      SwalServices.success("İşlem yapıldı.", "Talep durumu güncellendi.");
    })
    .catch((error) => {
      setIsLoading(false);
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      errorCodeControl(error);
    });
};

export const removeById = (id, setrowCount, setData, redirect) => {
  userPost("api/admin/demand/Delete", { Id: id })
    .then((a) => {
      setData(a.data.list);
      setrowCount(a.data.rowsCount);
      SwalServices.success("İşlem Başarılı");
      redirect();
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      errorCodeControl(error);
    });
};
