import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { createOrder, removeCart } from "./cardservices";
import { BreadcrumbCard } from "../../../utils/breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import SwalServices from "../../../utils/swalServices";
import CardItem from "./cardItem";
import LoadSpin from "../../../ui/loadSpin";
import { Helmet } from "react-helmet";

const Card = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.cart);

  const [buttonOk, setbuttonOk] = useState(false);
  const [note, setnote] = useState("");

  const confirmCallback = () => {
    createOrder(data, note, setbuttonOk, dispatch);
  };

  const cartConfirm = () => {
    SwalServices.confirm(
      "Sepet Onaylı",
      "Sepeti onayla ve siparişi oluştur",
      confirmCallback
    );
  };

  const cartRemove = async (id) => {
    const removeCartA = async () => {
      removeCart(id, dispatch);
    };

    SwalServices.confirm(
      "Sepetten kaldır",
      "Ürün sepetten kaldırılsın mı?",
      removeCartA
    );
  };

  return (
    <Container className="m-250">
      <Helmet title="OGM Pictures - Sepetim"></Helmet>
      <Row className="d-flex justify-content-between">
        <Col xs={6} sm={6} md={6}>
          <BreadcrumbCard></BreadcrumbCard>
        </Col>
        <Col xs={6} sm={6} md={6} className="text-end">
          <Link className="mx-2 text-black text-underline " to={"/Favories"}>
            Favorilerim
          </Link>
          <Link className="mx-2 text-black text-underline " to={"/orders"}>
            Siparişlerim
          </Link>
        </Col>
      </Row>

      {data && data.length > 0 ? (
        <div className="card-container ">
          {data.map((item) => (
            <CardItem item={item} cartRemove={cartRemove}></CardItem>
          ))}
          <Row className="mb-50 mt-5">
            <Col md={10} className="mb-3">
              <input
                className="form-control"
                type="text"
                placeholder="Notlar..."
                onChange={(e) => setnote(e.target.value)}
              ></input>{" "}
            </Col>

            <Col md={2} className="text-end mb-3">
              {!buttonOk ? (
                <Button
                  className="  px-5 py-2"
                  variant="dark"
                  onClick={() => cartConfirm()}
                >
                  Sepeti Onayla
                </Button>
              ) : (
                <span>
                  <LoadSpin></LoadSpin>
                </span>
              )}
            </Col>
          </Row>
        </div>
      ) : (
        <div className="card-container-emty text-center d-flex justify-content-center  align-items-center flex-column">
          <div className="card-icon">
            <FontAwesomeIcon icon={faShoppingCart} />
          </div>
          <h3>Sepetiniz Henüz Boş</h3>
          <div>
            Sepetinizde ürün bulunamadı."Ürünler" butonuna tıklayarak ürünleri
            sepetinize ekleyebilirsiniz.
          </div>
          <Link to={"/products"} className="btn btn-dark mt-4  px-5 py-2">
            Ürünler
          </Link>
        </div>
      )}
    </Container>
  );
};
export default Card;
