import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadSpin from "../../../../ui/loadSpin";
import { getById, update } from "../projectServices";

const Update = ({ show, handleClose, filldata }) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    
    const success = (data) => {
      
      formik.setFieldValue("id", data.id);
      formik.setFieldValue("name", data.name);
      formik.setFieldValue("location", data.location);
      formik.setFieldValue("status", data.status);
    };
    getById(show, success);
  }, [show]);

  const onSubmit = async (values, { setSubmitting }) => {
     // Form gönderildiğinde buraya ulaşılıyor mu?
    const submitFillData = (a) => {
      filldata();
      setSubmitting(false);
      handleClose(); // Form gönderildikten sonra modal'ı kapat
    };
    const data = {
      id: values.id,
      storageName: values.name,
      location: values.location,
      status: values.status === "true" || values.status === true ? true : false,
    };
    
    update(data, setIsLoading, submitFillData);
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Proje giriniz."),
    location: Yup.string().required("Konum giriniz."),
  });

  const formik = useFormik({
    initialValues: {
      id: null,
      name: "",
      location: "",
      status: true,
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <Modal
      size="xl"
      show={show}
      onHide={handleClose}
      aria-labelledby="example-modal-sizes-title-sm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-sm">
          Proje Düzenle
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          className="form-horizontal row"
          method="post"
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <div className="col-md-12 mb-3 text-start">
            <label>
              Durum <span className="text-danger">*</span>{" "}
            </label>
            <select
              id="status"
              name="status"
              className={`form-control ${
                formik.touched.status && formik.errors.status
                  ? "is-invalid"
                  : ""
              }`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.status}
            >
              <option value={true}>Aktif</option>
              <option value={false}>Pasif</option>
            </select>
            {formik.touched.status && formik.errors.status ? (
              <div className="invalid-feedback">{formik.errors.status}</div>
            ) : null}
          </div>
          <div className="col-md-12 mb-3 text-start">
            <label>
              Proje Adı <span className="text-danger">*</span>{" "}
            </label>
            <input
              id="name"
              name="name"
              type="text"
              className={`form-control ${
                formik.touched.name && formik.errors.name ? "is-invalid" : ""
              }`}
              placeholder="Proje adı giriniz."
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="invalid-feedback">{formik.errors.name}</div>
            ) : null}
          </div>{" "}
          <div className="col-md-12 mb-3 text-start">
            <label>
              Konum <span className="text-danger">*</span>{" "}
            </label>
            <input
              id="location"
              name="location"
              type="text"
              className={`form-control ${
                formik.touched.location && formik.errors.location
                  ? "is-invalid"
                  : ""
              }`}
              placeholder="Konum giriniz."
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.location}
            />
            {formik.touched.location && formik.errors.location ? (
              <div className="invalid-feedback">{formik.errors.location}</div>
            ) : null}
          </div>
          <Modal.Footer>
            {isLoading ? (
              <div>
                <LoadSpin></LoadSpin>
              </div>
            ) : (
              <>
                <Button variant="secondary" type="button" onClick={handleClose}>
                  Kapat
                </Button>
                <Button variant="success" type="submit">
                  Güncelle
                </Button>
              </>
            )}
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Update;
