import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadSpin from "../../ui/loadSpin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const ForgotPasswordConfirmForm = ({ onSubmit, btnLoading }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordR, setShowPasswordR] = useState(false);

  const toggleShowPassword = (setShow) => {
    setShow((prevState) => !prevState);
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(6, "En az 6 karakter olmalı")
      .required("Şifrenizi giriniz."),
    passwordR: Yup.string()
      .oneOf([Yup.ref("password"), null], "Şifreler eşleşmiyor")
      .required("Şifrenizi tekrar giriniz."),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      passwordR: "",
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <>
      <form
        className="form-horizontal row"
        method="post"
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <div className="col-md-12 mb-3 text-start">
          <label className="form-label" htmlFor="password">
            Yeni Şifre <span className="text-danger">*</span>
          </label>
          <div className="input-group">
            <input
              type={showPassword ? "text" : "password"}
              className={`form-control ${
                formik.touched.password && formik.errors.password
                  ? "is-invalid"
                  : ""
              }`}
              name="password"
              placeholder="Şifrenizi giriniz."
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />{" "}
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={() => toggleShowPassword(setShowPassword)}
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </button>
          </div>
          {formik.touched.password && formik.errors.password ? (
            <div className="invalid-feedback">{formik.errors.password}</div>
          ) : null}
        </div>

        <div className="col-md-12 mb-3 text-start">
          <label className="form-label" htmlFor="passwordR">
            Yeni Şifre Tekrar <span className="text-danger">*</span>
          </label>

          <div className="input-group">
            <input
              type={showPasswordR ? "text" : "password"}
              className={`form-control ${
                formik.touched.passwordR && formik.errors.passwordR
                  ? "is-invalid"
                  : ""
              }`}
              name="passwordR"
              placeholder="Şifrenizi tekrar giriniz."
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.passwordR}
            />{" "}
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={() => toggleShowPassword(setShowPasswordR)}
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </button>
          </div>
          {formik.touched.passwordR && formik.errors.passwordR ? (
            <div className="invalid-feedback">{formik.errors.passwordR}</div>
          ) : null}
        </div>

        {!btnLoading ? (
          <div className="mb-3 row">
            <div className="col-sm-12">
              <button
                className="btn btn-dark w-100 mt-2 waves-effect waves-light lgn-btn"
                type="submit"
              >
                Şifremi Güncelle
              </button>
            </div>
          </div>
        ) : (
          <>
            {" "}
            <div className="alert alert-warning loading-div mt-3">
              <LoadSpin></LoadSpin>
              {""}
              <hr />
              <span className="mt-3">İşlem yapılıyor...</span>
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default ForgotPasswordConfirmForm;
