import React, { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import LoadSpin from "../../../../ui/loadSpin";
import { userByIdAndCategory, update } from "../categoryesServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const Update = ({ show, handleClose, filldata }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [Qualityes, setQualityes] = useState([]);
  const [selectedQualities, setSelectedQualities] = useState([]);
  const [file, setFile] = useState();

  const [image, setImage] = useState({ state: false, value: null });

  const [selectedCategoryTree, setSelectedCategoryTree] = useState({
    categorymain: null,
    category1: null,
    category2: null,
    category3: null,
  });

  useEffect(() => {
    const fetchProjects = async (data) => {
      try {
        const success = (data) => {
          setCategories(data.categories);
          setQualityes(data.qualityes);
          setSelectedQualities(data.item.selectedQualities);

          // image
          if (data.item.image) {
            setImage({ state: true, value: data.item.image });
          }
          formik.setFieldValue("id", data.item.id);
          formik.setFieldValue("name", data.item.categoryName);

          if (data.item.parentId) {
            const findParentIds = (categoryId, categories) => {
              let parentIds = [];
              let currentCategory = categories.find(
                (category) => category.value === categoryId
              );

              while (currentCategory) {
                parentIds.unshift(currentCategory.value);
                currentCategory = categories.find(
                  (category) => category.value === currentCategory.parentId
                );
              }
              debugger;
              return parentIds;
            };

            const parentCategories = findParentIds(
              data.item.parentId,
              data.categories
            );

            const updatedCategoryTree = { ...selectedCategoryTree };
            ["categorymain", "category1", "category2", "category3"].forEach(
              (key, index) => {
                updatedCategoryTree[key] = parentCategories[index] || null;
              }
            );

            setSelectedCategoryTree(updatedCategoryTree);

            // const selectedProject = data.categories.find(
            //   (p) => p.id === data.item.parentId
            // );

            // if (selectedProject) {
            //   formik.setFieldValue("parentCategory", {
            //     value: selectedProject.id,
            //     label: selectedProject.name,
            //   });
            // }
          }

          //
        };

        await userByIdAndCategory(show, success);
      } catch (error) {
        console.error("Kategori listesi yüklenirken bir hata oluştu:", error);
      }
    };
    fetchProjects();
  }, []);

  const handleCheckboxChange = (id) => {
    setSelectedQualities((prevSelectedQualities) => {
      if (prevSelectedQualities.includes(id)) {
        return prevSelectedQualities.filter((qualityId) => qualityId !== id);
      } else {
        return [...prevSelectedQualities, id];
      }
    });
  };

  const onSubmit = async (values, { setSubmitting }) => {
    const submitFillData = () => {
      setSubmitting(false);
      handleClose();
      filldata();
    };

    setIsLoading(true);

    const form = new FormData();
    form.append("id", show);

    form.append("CategoryName", values.name);
    // form.append("ParentId", values.parentCategory?.value);
    // form.append("QualityList", selectedQualities);    form.append("file", file);
    selectedQualities.forEach((quality, index) => {
      form.append(`QualityList[${index}]`, quality);
    });
    form.append("ImageState", image.state);

    if (selectedCategoryTree.categorymain === null) {
      if (file) {
        form.append("file", file);
      }
    } else {
      if (selectedCategoryTree.category3 != null) {
        form.append("ParentId", selectedCategoryTree.category3);
      } else if (selectedCategoryTree.category2 != null) {
        form.append("ParentId", selectedCategoryTree.category2);
      } else if (selectedCategoryTree.category1 != null) {
        form.append("ParentId", selectedCategoryTree.category1);
      } else if (selectedCategoryTree.categorymain != null) {
        form.append("ParentId", selectedCategoryTree.categorymain);
      }
    }

    await update(form, setIsLoading, submitFillData);
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(2, "En az 2 karakter olmalı")
      .required("Kategori adı giriniz."),
  });

  const formik = useFormik({
    initialValues: {
      id: null,
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const handleMainCategoryChange = (item, values) => {
    debugger;
    const updatedCategoryTree = {
      ...selectedCategoryTree,
      [item]: values ? values.value : values,
    };

    // Reset the subcategories based on the changed item
    if (item === "categorymain") {
      updatedCategoryTree.category1 = null;
      updatedCategoryTree.category2 = null;
      updatedCategoryTree.category3 = null;
      formik.setValues({
        ...formik.values,
        categoryId: null,
      });
    } else if (item === "category1") {
      updatedCategoryTree.category2 = null;
      updatedCategoryTree.category3 = null;
      formik.setValues({
        ...formik.values,
        categoryId: null,
      });
    } else if (item === "category2") {
      updatedCategoryTree.category3 = null;
      formik.setValues({
        ...formik.values,
        categoryId: null,
      });
    }
    setSelectedCategoryTree(updatedCategoryTree);
  };
  return (
    <Modal
      size="xl"
      show={show}
      onHide={handleClose}
      aria-labelledby="example-modal-sizes-title-sm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-sm">
          Kategori Düzenle
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Modal.Body>
          <Form
            className="form-horizontal row"
            method="post"
            autoComplete="off"
            onSubmit={formik.handleSubmit}
          >
            {image.value && image.state && (
              <div className="d-flex justify-content-center">
                {" "}
                <div
                  className="text-center mb-3 position-relative "
                  style={{ maxHeight: "180px", maxWidth: "180px" }}
                >
                  <img
                    src={"/images/category/" + image.value}
                    style={{ maxHeight: "170px", maxWidth: "170px" }}
                  />

                  <button
                    className="btn btn-light text-danger position-absolute top-0  end-0"
                    type="button"
                    onClick={() => setImage({ ...image, state: false })}
                  >
                    <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
                  </button>
                </div>
              </div>
            )}

            {selectedCategoryTree.categorymain === null && (
              <div className="col-md-12 mb-3 text-center">
                <input
                  accept="image/*"
                  type="file"
                  onChange={(event) => {
                    setFile(
                      event.target.files.length > 0
                        ? event.target.files[0]
                        : null
                    );
                  }}
                />
              </div>
            )}

            {/* <div className="col-md-12 mb-3 text-start">
              <label className="form-label" htmlFor="project">
                Üst Kategori
              </label>
              <Select
                value={formik.values.parentCategory}
                options={
                  categories &&
                  categories.map((item) => ({
                    value: item.id,
                    label: item.fullPath,
                    parentId: item.parentId,
                  }))
                }
                placeholder="Üst Kategori"
                isClearable={true}
                onChange={(selectedOption) => {
                  formik.setFieldValue("parentCategory", selectedOption);
                }}
                className={`react-select-container`}
              />
            </div> */}

            <div className="row ilan-d-table">
              <div className="col-md-12  m-1">
                <div className="w-100">
                  <strong>Ana Kategori :</strong>{" "}
                  <span className="text-danger">*</span>
                </div>
                <div className="w-100 row">
                  <div className="col-md-12 mb-3 text-start">
                    <Select
                      isClearable
                      placeholder=" Ana Kategori..."
                      name="categoryId"
                      options={categories
                        .filter((a) => a.parentId === null)
                        .map((category) => ({
                          value: category.value,
                          label: category.label,
                        }))}
                      value={
                        selectedCategoryTree.categorymain &&
                        categories &&
                        categories.find(
                          (category) =>
                            category.value === selectedCategoryTree.categorymain
                        )
                      }
                      onChange={(e) =>
                        handleMainCategoryChange("categorymain", e)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            {selectedCategoryTree.categorymain && (
              <div className="row ilan-d-table">
                <div className="col-md-12  m-1">
                  <div className="w-100">
                    <strong> Üst Kategori :</strong>{" "}
                    <span className="text-danger">*</span>
                  </div>
                  <div className="w-100 row">
                    <div className="col-md-12 mb-3 text-start">
                      <Select
                        isClearable
                        placeholder=" Üst Kategori..."
                        name="categoryId"
                        options={categories
                          .filter(
                            (a) =>
                              a.parentId !== null &&
                              a.parentId === selectedCategoryTree.categorymain
                          )
                          .map((category) => ({
                            value: category.value,
                            label: category.label,
                          }))}
                        value={
                          selectedCategoryTree.category1 &&
                          categories &&
                          categories.find(
                            (category) =>
                              category.value === selectedCategoryTree.category1
                          )
                        }
                        onChange={(e) =>
                          handleMainCategoryChange("category1", e)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {selectedCategoryTree.category1 && (
              <div className="row ilan-d-table">
                <div className="col-md-12  m-1">
                  <div className="w-100">
                    <strong> Üst Kategori :</strong>{" "}
                    <span className="text-danger">*</span>
                  </div>
                  <div className="w-100 row">
                    <div className="col-md-12 mb-3 text-start">
                      <Select
                        isClearable
                        placeholder="Üst Kategori..."
                        name="categoryId"
                        options={categories
                          .filter(
                            (a) =>
                              a.parentId !== null &&
                              a.parentId === selectedCategoryTree.category1
                          )
                          .map((category) => ({
                            value: category.value,
                            label: category.label,
                          }))}
                        value={
                          selectedCategoryTree.category2 &&
                          categories &&
                          categories.find(
                            (category) =>
                              category.value === selectedCategoryTree.category2
                          )
                        }
                        onChange={(e) =>
                          handleMainCategoryChange("category2", e)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {selectedCategoryTree.category2 && (
              <div className="row ilan-d-table">
                <div className="col-md-12  m-1">
                  <div className="w-100">
                    <strong>Üst Kategori :</strong>{" "}
                    <span className="text-danger">*</span>
                  </div>
                  <div className="w-100 row">
                    <div className="col-md-12 mb-3 text-start">
                      <Select
                        isClearable
                        placeholder="Üst Kategori..."
                        name="categoryId"
                        options={categories
                          .filter(
                            (a) =>
                              a.parentId !== null &&
                              a.parentId === selectedCategoryTree.category2
                          )
                          .map((category) => ({
                            value: category.value,
                            label: category.label,
                          }))}
                        value={
                          selectedCategoryTree.category3 &&
                          categories &&
                          categories.find(
                            (category) =>
                              category.value === selectedCategoryTree.category3
                          )
                        }
                        onChange={(e) =>
                          handleMainCategoryChange("category3", e)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="col-md-12 mb-3 text-start">
              <label className="form-label" htmlFor="name">
                Kategori Adı <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className={`form-control ${
                  formik.touched.name && formik.errors.name ? "is-invalid" : ""
                }`}
                name="name"
                placeholder="Kategori adı giriniz."
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="invalid-feedback">{formik.errors.name}</div>
              ) : null}
            </div>

            {Qualityes && (
              <Row>
                {Qualityes.map((item) => (
                  <Col md={3} sm={3}>
                    <div className="col-md-12 mb-3 text-start">
                      <label className="form-label"></label>
                      <Form.Check
                        type="checkbox"
                        id={item.id}
                        label={item.qualityContent}
                        checked={selectedQualities.includes(item.id)}
                        onChange={() => handleCheckboxChange(item.id)}
                      />
                    </div>{" "}
                  </Col>
                ))}
              </Row>
            )}
            <Modal.Footer>
              {isLoading ? (
                <div>
                  <LoadSpin />
                </div>
              ) : (
                <>
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={handleClose}
                  >
                    Kapat
                  </Button>
                  <Button variant="success" type="submit">
                    Güncelle
                  </Button>
                </>
              )}
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal.Body>
    </Modal>
  );
};

export default Update;
