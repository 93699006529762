import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadSpin from "../../ui/loadSpin";

const ForgotPasswordForm = ({ onSubmit, btnLoading }) => {
  const validationSchema = Yup.object({
    email: Yup.string()
      .required("E-posta giriniz.")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        "Geçerli bir e-posta adresi giriniz."
      ),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <>
      <form
        className="form-horizontal row"
        method="post"
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <div className="col-md-12 mb-3 text-start">
          <label>
            E-posta adresiniz <span className="text-danger">*</span>{" "}
          </label>
          <input
            type="text"
            className={`form-control ${
              formik.touched.email && formik.errors.email ? "is-invalid" : ""
            }`}
            name="email"
            placeholder="E-posta adresinizi giriniz."
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="invalid-feedback">{formik.errors.email}</div>
          ) : null}
        </div>

        {!btnLoading ? (
          <div className="mb-3 row">
            <div className="col-sm-12">
              <button
                className="btn btn-dark w-100 mt-2 waves-effect waves-light lgn-btn"
                type="submit"
              >
                Gönder
              </button>
            </div>
          </div>
        ) : (
          <>
            {" "}
            <div className="alert alert-warning loading-div mt-3">
              <LoadSpin></LoadSpin>
              {""}
              <hr />
              <span className="mt-3">İşlem yapılıyor...</span>
            </div>
          </>
        )}

        <div className="mb-3  text-center footer-register-link">
          <div class="form-check">
            <span> Üye misiniz?</span> <Link to={"/login"}>Giriş Yapın</Link>
          </div>
        </div>
      </form>
    </>
  );
};

export default ForgotPasswordForm;
