import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../header/header";
import { getHeader } from "./adminServices";
import "../app.css";
import { Col, Container, Row } from "react-bootstrap";
import LeftMenu from "./Component/leftMenu";

const AdminLayout = () => {
  // const [data, setData] = useState();
  // useEffect(() => {
  //   getHeader(setData);
  // }, []);

  return (
    <>
      <div className="loader-wrapper" style={{ display: "none" }}>
        <div className="row loader-img">
          <div className="col-12">
            <img
              src="/assets/images/loader/loader-2.gif"
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>
      <Header></Header>

      <Outlet />
      <div className="tap-top">
        <div>
          <i className="fas fa-arrow-up"></i>
        </div>
      </div>
    </>
  );
};

export default AdminLayout;
