import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import FormHandleChange from "../../utils/formService";
import LoadSpin from "../../ui/loadSpin";
import { loginValue, onSubmit } from "./loginServices";
import LoginForm from "./loginForm";
import { userLogin } from "../../redux/redux";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formValue, setformValue] = useState(loginValue);
  const [btnLoading, setbtnLoading] = useState(false);
  const handleChange = (event) => {
    FormHandleChange(event, formValue, setformValue);
  };

  const handleLogin = (token) => {
    dispatch(userLogin(token)).then(() => {
      navigate("/");
    });
  };

  return (
    <>
      <Helmet>
        <title>OGM Pictures - Kullanıcı Girişi </title>
      </Helmet>
      <div className="text-center mt-2">
        {!btnLoading ? (
          <div className="p-3">
            <h4 className="font-size-18   mt-2 text-center">
              Üye Girişi
            </h4>
            <small className="login-sml-info">
              Devam etmek için lütfen oturum açınız.
            </small>

            <LoginForm
              formValue={formValue}
              onSubmit={onSubmit}
              setbutton={setbtnLoading}
              handleLogin={handleLogin}
              handleChange={handleChange}
              navigate={navigate}
            ></LoginForm>
          </div>
        ) : (
          <>
            {" "}
            <div className="alert alert-warning loading-div mt-3">
              <LoadSpin></LoadSpin>
              {""}
              <hr />
              <span className="mt-3">Bilgileriniz kontrol ediliyor...</span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Login;
