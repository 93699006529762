import { errorCodeControl, userGetList } from "../../../utils/axiosServices";
import SwalServices from "../../../utils/swalServices";

export const getAll = (filter, setfilter, setrowCount, setData) => {
  userGetList("api/profil/GetAllUsers", filter)
    .then((a) => {
      setData(a.data.list);
      setrowCount(a.data.rowsCount);
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      setData([]);
      errorCodeControl(error);
    });
};
