import React, { useState } from "react";
import Select from "react-select";
import { Button, Form, Modal } from "react-bootstrap";
import SwalServices from "../../../utils/swalServices";
import LoadSpin from "../../../ui/loadSpin";
import { useFormik } from "formik";
import * as Yup from "yup";

const ExtraditionModal = ({
  modelExtradition,
  handleClose,
  onSubmit,
  extraditions,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const Submit = async (values, { setSubmitting }) => {
    setIsLoading(true);
    onSubmit(values);
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(2, "En az 2 karakter olmalı")
      .required("İade açıklaması giriniz."),
    extradition: Yup.object().required("İade nedeni seçiniz."),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      extradition: null,
    },
    validationSchema: validationSchema,
    onSubmit: Submit,
    validateOnBlur: true,
    validateOnChange: true,
  });

  return (
    <Modal
      size="xl"
      show={modelExtradition ? true : false}
      onHide={handleClose}
      aria-labelledby="example-modal-sizes-title-sm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-sm">
          {modelExtradition.name} iade etmek istediğinize emin misiniz?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          className="form-horizontal row"
          method="post"
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <div className="col-md-12 mb-3 text-start" style={{ zIndex: 5 }}>
            <label className="form-label" htmlFor="extradition">
              İade nedeni <span className="text-danger">*</span>
            </label>
            <Select 
              options={
                extraditions &&
                extraditions.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))
              }
              placeholder="İade nedeni seçiniz..."
              isClearable={true}
              onChange={(selectedOption) => {
                formik.setFieldValue("extradition", selectedOption);
              }}
              className={`react-select-container ${
                formik.touched.extradition && formik.errors.extradition
                  ? "is-invalid"
                  : ""
              }`}
            />
            {formik.touched.extradition && formik.errors.extradition ? (
              <div className="invalid-feedback d-block">
                {formik.errors.extradition}
              </div>
            ) : null}
          </div>

          <div className="col-md-12 mb-3 text-start">
            <label className="form-label" htmlFor="name">
              İade açıklaması <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control ${
                formik.touched.name && formik.errors.name ? "is-invalid" : ""
              }`}
              name="name"
              placeholder=" İade açıklaması giriniz."
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="invalid-feedback">{formik.errors.name}</div>
            ) : null}
          </div>

          <Modal.Footer>
            {isLoading ? (
              <div>
                <LoadSpin />
              </div>
            ) : (
              <>
                <Button variant="secondary" type="button" onClick={handleClose}>
                  Kapat
                </Button>
                <Button variant="success" type="submit">
                  İade Et
                </Button>
              </>
            )}
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ExtraditionModal;
