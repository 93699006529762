import { errorCodeControl, userGetList, userPost } from "../../../utils/axiosServices";
import SwalServices from "../../../utils/swalServices";
import * as Yup from "yup";
export const basicInformation = (
  setProject,
  setWareHouses,
  setProjects,
  setBrands
) => {
  try {
    userGetList("api/admin/Product/BasicInformation").then((a) => {
      setProject(a.data.categories);
      setWareHouses(a.data.wareHouses);
      setProjects(a.data.projects);
      setBrands(a.data.brands);
    });
  } catch (error) {
    let err = error.response
      ? error.response.data
      : "Hata oluştu." + error.message;
    SwalServices.warning("Hata oluştu", err);
    setProject([]);
    errorCodeControl(error);
  }
};

export const getById = (
  id,
  setProject,
  setWareHouses,
  setProjects,
  setBrands,
  success
) => {
  try {
    userGetList("api/admin/Product/GetById", { id }).then((a) => {
      setProject(a.data.categories);
      setWareHouses(a.data.wareHouses);
      setProjects(a.data.projects);
      setBrands(a.data.brands);
      success(a.data.product, a.data.categories);
    });
  } catch (error) {
    let err = error.response
      ? error.response.data
      : "Hata oluştu." + error.message;
    SwalServices.warning("Hata oluştu", err);
    setProject([]);
    errorCodeControl(error);
  }
};

export const createProduct = (data, setIsloading, navigate) => {
  setIsloading(true);
  const redirect = () => {
    navigate("/products");
  };

  userPost("api/admin/Product/create", data)
    .then((a) => {
      setIsloading(false);

      SwalServices.confirmSignle(
        "İşlem Başarılı",
        "Ürün başarılı bir şekilde eklendi.",
        redirect,
        redirect
      );
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      setIsloading(false);
      errorCodeControl(error);
    });
};

export const updateProduct = (data, setIsloading, navigate) => {
  setIsloading(true);
  const redirect = () => {
    // navigate("/products");
  };

  userPost("api/admin/Product/update", data)
    .then((a) => {
      setIsloading(false);

      SwalServices.confirmSignle(
        "İşlem Başarılı",
        "Ürün başarılı bir şekilde güncellendi.",
        redirect,
        redirect
      );
    })
    .catch((error) => {
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      SwalServices.warning("İşlem başarısız.", err);
      setIsloading(false);
      errorCodeControl(error);
    });
};
