import React, { useState } from "react";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartRegular } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { userPost } from "./axiosServices";

const FavoriesButton = ({ status, productId }) => { 
  const [data, setData] = useState(status);

  const handleFaviries = (e) => {
    e.preventDefault();

    const request = { rId: productId };

    userPost("api/Order/AddFavories", request)
      .then((a) => {
        
        setData(a.data);
      })
      .catch((error) => {});
  };

  return (
    <Link
      to={"#"}
      className="border p-1 rounded text-danger favoriesButton  btn-lg"
      onClick={(e) => handleFaviries(e)}
    >
      {data ? (
        <FontAwesomeIcon icon={faHeartSolid} />
      ) : (
        <FontAwesomeIcon icon={faHeartRegular} />
      )}
    </Link>
  );
};

export default FavoriesButton;
