import React from "react";
import { errorCodeControl, userGetList } from "../../../utils/axiosServices";
import SwalServices from "../../../utils/swalServices";

export const getDashboardCategory = async (setData) => {
  userGetList("api/Home/GetDashboardInfo")
    .then((a) => {
      setData(a.data);
    })
    .catch((error) => {
      debugger;
      let err = error.response
        ? error.response.data
        : "Hata oluştu." + error.message;
      setData([]);
      errorCodeControl(error);
    });
};
// const response = await userGetList("api/Home/GetDashboardInfo").then(a=>setData(a.data.result));
